import { IconArrowBackUp } from "@tabler/icons-react"

import { workNeededStepFormSchema } from "@ensol/types/forms/projects/consuel"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { CONSUEL_PROPERTIES } from "@ensol/entool/pages/OpsProject/processes/Consuel/config"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"

import { ConsuelForm } from "./components/ConsuelForm"

export const WorkNeeded = ({ project, processId }: StepComponentProps) => (
  <ConsuelForm
    schema={workNeededStepFormSchema}
    initialValues={{
      ...getInitialValues(workNeededStepFormSchema, project),
      ...pickDefinedProperties(CONSUEL_PROPERTIES, project),
    }}
    project={project}
    processId={processId}
    actions={[
      {
        action: "visitNeeded",
        label: "Repasser en avis de visite",
        color: "orange",
        Icon: IconArrowBackUp,
      },
      {
        action: "validate",
        label: "Travaux prévus",
      },
    ]}
  />
)
