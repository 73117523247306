import * as z from "zod"
import { isValidPhoneNumber } from "libphonenumber-js"
import { formatPhoneNum } from "@ensol/shared/utils/format"
import { Gender, ResidentialStatus } from "@ensol/shared/entities/prospects"

export const phoneSchema = z
  .string()
  .min(1, "Champ obligatoire")
  .refine(
    (val) =>
      isValidPhoneNumber(val, {
        defaultCountry: "FR",
      }),
    "Numéro de téléphone invalide",
  )
  .transform((val) => formatPhoneNum(val, "E.164"))

export const createProspectSchema = z.object({
  gender: z.nativeEnum(Gender).nullable().optional(),
  firstName: z.string().min(1, "Champ obligatoire"),
  lastName: z.string().min(1, "Champ obligatoire"),
  email: z.string().email("Email invalide"),
  phone: phoneSchema,
  residentialStatus: z.nativeEnum(ResidentialStatus).optional(),
  hubspotContactId: z
    .string()
    .nullable()
    .optional()
    .transform((value) => (value === "" || value === undefined ? null : value)),
  isSubscribedToNewsletter: z.boolean().optional(),
})

export type ProspectInput = z.infer<typeof createProspectSchema>

export const updateProspectSchema = createProspectSchema.extend({
  gender: z.nativeEnum(Gender, {
    invalid_type_error: "Champ obligatoire",
  }),
})

export type UpdateProspectInput = z.infer<typeof updateProspectSchema>

export const contactFormSchema = z.discriminatedUnion("isOnSite", [
  createProspectSchema.extend({
    isOnSite: z.literal(true),
    hasAcceptedTerms: z.boolean().refine((val) => val),
    phone: z.string(),
  }),
  createProspectSchema.extend({
    isOnSite: z.literal(false),
    hasAcceptedTerms: z.boolean().refine((val) => val),
  }),
])
createProspectSchema.extend({
  hasAcceptedTerms: z.boolean().refine((val) => val),
  phone: z
    .string()
    .min(1, "Champ obligatoire")
    .transform((val) => formatPhoneNum(val, "E.164")),
})

export type ContactFormInput = z.infer<typeof contactFormSchema>

export const listProspectsFilters = z.object({
  search: z.string().optional(),
  orderBy: z
    .object({
      field: z.enum(["createdAt"]),
      direction: z.enum(["asc", "desc"]),
    })
    .optional(),
})

export type ListProspectsFilters = z.infer<typeof listProspectsFilters>
