import { Grid, Group, Paper, Stack, Title } from "@mantine/core"
import { IconHome } from "@tabler/icons-react"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"

import { getCoords } from "@ensol/shared/entities/houses/coords"

import { queryClient } from "@ensol/entool/backend/queryClient"
import { Map } from "@ensol/entool/components/Map"
import { EditHouseButton } from "@ensol/entool/components/form/House/EditHouseButton"
import { Installations } from "@ensol/entool/pages/Prospect/Installations"

type HouseProps = {
  house: ProspectResponses.ProspectDetails["houses"][number]
}

export const House = ({ house }: HouseProps) => (
  <Paper withBorder shadow="none" w="100%" p="20">
    <Grid gutter="48">
      <Grid.Col span={{ base: 12, lg: 9 }}>
        <Stack gap="20" h="100%">
          <Group align="center" gap="5">
            <IconHome />
            <Title order={3}>
              {house.streetAddress}, {house.city}
            </Title>
          </Group>
          <Installations
            houseId={house.id}
            address={house.address}
            installations={house.installations}
          />
        </Stack>
      </Grid.Col>
      <Grid.Col
        span={{ base: 0, lg: 3 }}
        display={{ base: "none", lg: "block" }}
      >
        <Stack>
          <EditHouseButton
            house={house}
            onSuccess={async () => {
              await queryClient.invalidateQueries({
                queryKey: ["prospects", house.prospectId],
              })
            }}
            buttonProps={{ variant: "light" }}
          />
          <Map
            mapId={`house-${house.id}-map`}
            coords={getCoords(house)}
            style={{ height: 140 }}
          />
        </Stack>
      </Grid.Col>
    </Grid>
  </Paper>
)
