import { Group } from "@mantine/core"
import {
  IconAt,
  IconCoins,
  IconHomeCog,
  IconPhone,
  IconSolarPanel,
} from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { getSubsidy } from "@ensol/shared/entities/installations/subsidies"
import {
  getEnphaseUrl,
  FOLLOW_UP_APPLICATIONS,
} from "@ensol/shared/material/photovoltaic/applications"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"

import enphaseLogo from "@ensol/entool/assets/enphaseLogo.png"
import solarFusionLogo from "@ensol/entool/assets/solarFusionLogo.png"
import { HeaderInfos } from "@ensol/entool/components/HeaderInfos"
import { Link } from "@ensol/entool/components/Link"
import { InstallationCharacteristics } from "@ensol/entool/components/entities/Installation/InstallationCharacteristics"
import { ProjectHeader } from "@ensol/entool/components/entities/Project/Header"
import { IssuesBadges } from "@ensol/entool/components/entities/Project/IssuesBadges"
import { ProjectOwners } from "@ensol/entool/components/entities/Project/ProjectOwners"
import { FOLLOW_UP_PIPELINE_HEADER_HEIGHT_IN_PX } from "@ensol/entool/styles/constants"

type Props = {
  project: ProjectResponses.Project
}

export const Header = ({ project }: Props) => {
  const { id, installation, followUpIssues, followUpExternalPlantIdentifier } =
    project
  const { house, photovoltaicInstallation } = installation
  const { prospect } = house
  const inverter =
    photovoltaicInstallation !== null
      ? getInverter(photovoltaicInstallation.inverterType)
      : null

  return (
    <ProjectHeader
      projectId={project.id}
      prospect={prospect}
      backPath="/follow-ups"
      height={FOLLOW_UP_PIPELINE_HEADER_HEIGHT_IN_PX}
      LeftComponent={
        <>
          <Group>
            <HeaderInfos
              infos={[
                { Icon: IconAt, text: prospect.email },
                { Icon: IconPhone, text: prospect.phone },
                {
                  Icon: IconCoins,
                  text:
                    photovoltaicInstallation &&
                    photovoltaicInstallation.subsidyType !== null
                      ? getSubsidy(photovoltaicInstallation.subsidyType)
                          .shortName
                      : "-",
                },
              ]}
            />
            <IssuesBadges issues={followUpIssues} />
          </Group>
          <Group gap="8" mt="16">
            <ProjectOwners project={project} background="blue.0" />
          </Group>
        </>
      }
      RightComponent={
        <>
          <InstallationCharacteristics installation={installation} />
          <Group gap="12" mt="16">
            {inverter?.brand === "Enphase" && (
              <Link
                label="Enlighten"
                href={getEnphaseUrl(followUpExternalPlantIdentifier)}
                color="orange"
                Icon={<img src={enphaseLogo} height={16} />}
                isExternal
              />
            )}
            {inverter?.brand === "Huawei" && (
              <Link
                label="FusionSolar"
                href={FOLLOW_UP_APPLICATIONS.Huawei.url}
                color="indigo"
                Icon={<img src={solarFusionLogo} height={16} />}
                isExternal
              />
            )}
            <Link
              label="Installation"
              href={`/installations/${installation.id}`}
              Icon={<IconSolarPanel />}
            />
            <Link
              label="Projet"
              href={`/projects/${id}`}
              Icon={<IconHomeCog />}
            />
          </Group>
        </>
      }
    />
  )
}
