import { Pagination, Stack, Table } from "@mantine/core"
import dayjs from "dayjs"
import _ from "lodash"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"
import { ListProspectsFilters } from "@ensol/types/forms/prospects"

import { getFullName } from "@ensol/shared/entities/prospects"
import { formatPhoneNum } from "@ensol/shared/utils/format"

import { HubspotLink } from "@ensol/entool/components/entities/Prospect/HubspotLink"
import { SortedTh } from "@ensol/entool/components/table/SortedTh"

type Props = {
  prospects: ProspectResponses.ProspectsList
  orderBy: NonNullable<ListProspectsFilters["orderBy"]>
  onOrderChange: (
    orderBy: NonNullable<ListProspectsFilters["orderBy"]>["field"],
  ) => void
}

const PAGE_SIZE = 20

export const ProspectsList = ({ prospects, orderBy, onOrderChange }: Props) => {
  const navigate = useNavigate()
  const [activePage, setPage] = useState(1)

  return (
    <Stack pb="24">
      <Table highlightOnHover>
        <Table.Thead>
          <Table.Tr>
            <SortedTh
              display={{ base: "none", md: "table-cell" }}
              reversed={
                orderBy.field === "createdAt" && orderBy.direction === "desc"
              }
              sorted={orderBy.field === "createdAt"}
              onSort={() => {
                onOrderChange("createdAt")
              }}
            >
              Date de création
            </SortedTh>
            <Table.Th>Nom</Table.Th>
            <Table.Th>Email</Table.Th>
            <Table.Th display={{ base: "none", md: "table-cell" }}>
              Téléphone
            </Table.Th>
            <Table.Th display={{ base: "none", md: "table-cell" }}>
              # Installations
            </Table.Th>
            <Table.Th>Contact</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {_.slice(
            prospects,
            (activePage - 1) * PAGE_SIZE,
            activePage * PAGE_SIZE,
          ).map((prospect) => (
            <Table.Tr
              key={prospect.id}
              onClick={() => navigate(`/prospects/${prospect.id}`)}
              style={{ cursor: "pointer" }}
            >
              <Table.Td display={{ base: "none", md: "table-cell" }}>
                {dayjs(prospect.createdAt).format("DD MMMM")}
              </Table.Td>
              <Table.Td>{getFullName(prospect)}</Table.Td>
              <Table.Td>{prospect.email}</Table.Td>
              <Table.Td display={{ base: "none", md: "table-cell" }}>
                {formatPhoneNum(prospect.phone)}
              </Table.Td>
              <Table.Td display={{ base: "none", md: "table-cell" }}>
                {prospect._count.installations}
              </Table.Td>
              <Table.Td>
                <HubspotLink
                  hubspotContactId={prospect.hubspotContactId}
                  w="fit-content"
                  p="0"
                  variant="transparent"
                  emptyContent="-"
                />
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
      <Pagination
        value={activePage}
        onChange={setPage}
        total={Math.ceil(prospects.length / PAGE_SIZE)}
      />
    </Stack>
  )
}
