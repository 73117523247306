import { Modal, ScrollArea, Title, Text, Stack, Loader } from "@mantine/core"
import { useDisclosure, useLocalStorage } from "@mantine/hooks"

import { ConsentAskStatus } from "@ensol/shared/entities/houses/switchgrid"

import { Field } from "@ensol/entool/components/form/Field"
import { DISPLAY_SWITCHGRID_SECTION_SETTING_KEY } from "@ensol/entool/pages/Installation/SimulationParameters/Settings"
import { useGetHouseQuery } from "@ensol/entool/queries/houses"
import { useGetConsentAskQuery } from "@ensol/entool/queries/switchgrid"

import { ConsentForm } from "./ConsentForm"
import { CreateConsentAsk } from "./CreateConsentAsk"
import { CreateOrder } from "./CreateOrder"

type Props = {
  houseId: string
}

export const SwitchgridParameters = ({ houseId }: Props) => {
  const { data: house } = useGetHouseQuery(houseId)
  const [
    isConsentModalOpen,
    { open: openConsentModal, close: closeConsentModal },
  ] = useDisclosure(false)
  const [isSwitchgridSectionDisplayed] = useLocalStorage({
    key: DISPLAY_SWITCHGRID_SECTION_SETTING_KEY,
    defaultValue: false,
  })

  const { data: consentAsk, isPending: isConsentAskLoading } =
    useGetConsentAskQuery(houseId)

  if (!house || !isSwitchgridSectionDisplayed) {
    return null
  }

  return (
    <>
      <Field
        name="Récupération des données Enedis"
        subtitle="Utiliser les données de consommation permet d'améliorer la simulation"
      >
        {isConsentAskLoading ? (
          <Stack justify="center" px="10">
            <Loader size="sm" color="gray" />
          </Stack>
        ) : (
          <>
            <CreateConsentAsk
              consentAsk={consentAsk}
              openConsentModal={openConsentModal}
            />
            {consentAsk &&
              consentAsk.status === ConsentAskStatus.ACCEPTED &&
              !consentAsk.isExpired && <CreateOrder houseId={houseId} />}
          </>
        )}
      </Field>
      <Modal
        size={1000}
        radius="lg"
        padding="xl"
        opened={isConsentModalOpen}
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={closeConsentModal}
        title={
          <Stack gap="4">
            <Title order={2}>Demande de consentement</Title>
            <Text size="sm" c="gray.6">
              Vérifiez / complétez les informations et envoyez la demande de
              consentement pour obtenir un lien de signature à partager au
              client
            </Text>
          </Stack>
        }
      >
        <ConsentForm house={house} closeConsentModal={closeConsentModal} />
      </Modal>
    </>
  )
}
