import _ from "lodash"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { UnpackArray } from "@ensol/types/utils"

import { ProcessStep } from "@ensol/shared/entities/projects/processes"
import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"

import { ProjectCard } from "@ensol/entool/components/entities/Project/Card"
import {
  ColumnWrapper,
  Column,
} from "@ensol/entool/components/entities/Project/Column"
import { ProjectMilestonesConfig } from "@ensol/entool/utils/projects/types"

import { InfoComponentProps, OrderByOptions } from "./config"

type Props = {
  projects: ProjectResponses.ProjectsList
  propertyName: keyof UnpackArray<ProjectResponses.ProjectsList>
  milestonesConfig: ProjectMilestonesConfig<ProcessStep | ProjectStatus>
  doneMilestone?: ProcessStep | ProjectStatus
  abortedMilestones?: (ProcessStep | ProjectStatus)[]
  warnDelayInDays: number
  currentTab: string
  viewColor: string
  isSearching: boolean
  orderBy: OrderByOptions
  Component?: React.ComponentType<InfoComponentProps>
}

export const View = ({
  projects,
  propertyName,
  milestonesConfig,
  doneMilestone,
  abortedMilestones,
  warnDelayInDays,
  currentTab,
  viewColor,
  isSearching,
  orderBy,
  Component,
}: Props) => {
  const projectsByColumn = _.groupBy(
    projects,
    (project) => project[propertyName],
  )

  return (
    <ColumnWrapper>
      {Object.values(milestonesConfig).map(
        ({ id: milestoneId, label, color }) => {
          const projects = projectsByColumn[milestoneId] ?? []

          if (isSearching && projects.length === 0) {
            return null
          }

          return (
            <Column
              key={milestoneId}
              backgroundColor={color}
              title={label}
              titleColor={viewColor}
              count={projects.length}
            >
              {_.orderBy(projects, ...orderBy).map((project) => (
                <ProjectCard
                  key={project.id}
                  project={project}
                  lastEventDate={project.lastEventDateByProperty[propertyName]}
                  warnDelayInDays={warnDelayInDays}
                  shouldDisplayDaysSinceLastEvent={
                    milestoneId !== doneMilestone &&
                    !abortedMilestones?.includes(milestoneId)
                  }
                  to={`/projects/${project.id}?tab=${currentTab}`}
                >
                  {Component && (
                    <Component project={project} milestoneId={milestoneId} />
                  )}
                </ProjectCard>
              ))}
            </Column>
          )
        },
      )}
    </ColumnWrapper>
  )
}
