import React from "react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { UnpackArray } from "@ensol/types/utils"

import {
  OpsProcessId,
  ProcessStep,
} from "@ensol/shared/entities/projects/processes"
import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"

import { ConsuelInfo } from "@ensol/entool/pages/OpsProjects/processes/Consuel"
import { EnedisInfo } from "@ensol/entool/pages/OpsProjects/processes/Enedis"
import { InstallationInfo } from "@ensol/entool/pages/OpsProjects/processes/Installation"
import { PaymentInfo } from "@ensol/entool/pages/OpsProjects/processes/Payment"
import {
  PROJECTS_PROCESS_VIEWS,
  ProjectProcessView,
} from "@ensol/entool/utils/projects/views"

import { HardwareInfo } from "./processes/Hardware"
import { PreliminaryRequestInfo } from "./processes/PreliminaryRequest"
import { TechnicalVisitInfo } from "./processes/TechnicalVisit"

const PROCESS_COMPONENTS = {
  technicalVisitStep: TechnicalVisitInfo,
  preliminaryRequestStep: PreliminaryRequestInfo,
  greendealStep: undefined,
  installationStep: InstallationInfo,
  paymentStep: PaymentInfo,
  hardwareStep: HardwareInfo,
  enedisStep: EnedisInfo,
  consuelStep: ConsuelInfo,
  edfOaStep: undefined,
}

export type OrderByOptions = [
  Array<keyof UnpackArray<ProjectResponses.ProjectsList>>,
  ("asc" | "desc")[],
]

const ORDER_BY_OPTIONS: Record<OpsProcessId, OrderByOptions> = {
  technicalVisitStep: [
    ["technicalVisitStartDate", "technicalVisitSchedulingHoldEndDate"],
    ["asc", "asc"],
  ],
  preliminaryRequestStep: [
    [
      "preliminaryRequestExpectedValidationDate",
      "preliminaryRequestLastContactDate",
    ],
    ["asc", "desc"],
  ],
  greendealStep: [[], []],
  installationStep: [
    [
      "installationStartDate",
      "installationSchedulingHoldEndDate",
      "preliminaryRequestExpectedValidationDate",
    ],
    ["asc", "asc", "asc"],
  ],
  paymentStep: [
    ["installationStartDate", "paymentHoldEndDate"],
    ["asc", "asc"],
  ],
  hardwareStep: [[], []],
  enedisStep: [[], []],
  consuelStep: [[], []],
  edfOaStep: [[], []],
}

export type InfoComponentProps = {
  project: UnpackArray<ProjectResponses.ProjectsList>
  milestoneId: ProcessStep | ProjectStatus
}

export const PROJECT_PROCESS_LIST_VIEWS: (ProjectProcessView<OpsProcessId> & {
  Component?: React.ComponentType<InfoComponentProps>
  orderBy: OrderByOptions
})[] = PROJECTS_PROCESS_VIEWS.map((props) => ({
  ...props,
  Component: PROCESS_COMPONENTS[props.id],
  orderBy: ORDER_BY_OPTIONS[props.id],
}))
