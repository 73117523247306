import { Stack } from "@mantine/core"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"

import { FollowUpStep } from "@ensol/shared/entities/projects/processes"
import { getFullName } from "@ensol/shared/entities/prospects"

import { ProcessView } from "@ensol/entool/components/entities/Project/ProcessView"
import { ContentContainer } from "@ensol/entool/components/layout/ContentContainer"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { getFollowUpStepComponent } from "@ensol/entool/pages/FollowUpProject/steps"
import { useGetProjectQuery } from "@ensol/entool/queries/projects"
import { FOLLOW_UP_STEPS } from "@ensol/entool/utils/projects/processes"

import { Header } from "./Header"

export const FollowUpProjectPage = () => {
  const { projectId } = useParams()
  const projectQuery = useGetProjectQuery(projectId!)

  return (
    <QueryWrapper query={projectQuery}>
      {({ data: project }) => {
        const { prospect } = project.installation.house
        return (
          <Stack flex="1" h="100%">
            <Helmet>
              <title>{getFullName(prospect)} | Entool</title>
            </Helmet>
            <Header project={project} />
            <ContentContainer flex="1" mb="32">
              <ProcessView
                processId="followUpStep"
                project={project}
                stepperHeight={150}
                milestonesConfig={FOLLOW_UP_STEPS}
                abortedMilestones={[
                  FollowUpStep.CONSENT_REFUSED,
                  FollowUpStep.M1_IGNORED,
                ]}
                getStepComponent={getFollowUpStepComponent}
              />
            </ContentContainer>
          </Stack>
        )
      }}
    </QueryWrapper>
  )
}
