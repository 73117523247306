import { showNotification } from "@mantine/notifications"
import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import dayjs from "dayjs"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import {
  ListProjectsFilters,
  ProjectsEventsFilters,
} from "@ensol/types/forms/projects"
import { EmailInput } from "@ensol/types/forms/projects/emails"
import { FeedbackInput } from "@ensol/types/forms/projects/feedbacks"
import { Nullable } from "@ensol/types/utils"

import { ProjectEventType } from "@ensol/shared/entities/projects/events"
import { ProcessId } from "@ensol/shared/entities/projects/processes"
import { isZodError, isEnsolError } from "@ensol/shared/utils/errors"

import { httpClient } from "@ensol/entool/backend/axios"
import { queryClient } from "@ensol/entool/backend/queryClient"
import { formatZodValidationError } from "@ensol/entool/utils/form/validation"
import { EMAILS_FIELDS_LABELS } from "@ensol/entool/utils/projects/fieldsLabels"

type ListProjectsOptions = {
  filters: ListProjectsFilters
  enabled?: boolean
}

export const useListProjectsQuery = ({
  filters,
  enabled = true,
}: ListProjectsOptions) => {
  return useQuery<ProjectResponses.ProjectsList>({
    queryKey: ["projects", filters],
    queryFn: async () => {
      const response = await httpClient.post("/projects", filters)
      return response.data
    },
    enabled,
  })
}

export const useLatestProjectsQuery = (refetchInterval?: number) => {
  return useQuery<ProjectResponses.Project[]>({
    queryKey: ["projects", "latest"],
    queryFn: async () => {
      const response = await httpClient.get("/projects/latest")
      return response.data
    },
    refetchInterval,
    refetchIntervalInBackground: refetchInterval !== undefined,
  })
}

export const useGetProjectQuery = (projectId: string) =>
  useQuery<ProjectResponses.Project>({
    queryKey: ["projects", projectId],
    queryFn: async () => {
      const response = await httpClient.get(`/projects/${projectId}`)
      return response.data
    },
  })

type CompleteProcessStepInput = {
  projectId: string
  processId: ProcessId
  action: string
}

export const useCompleteProcessStepMutation = () => {
  return useMutation<null, AxiosError, CompleteProcessStepInput>({
    mutationFn: async ({ projectId, processId, action }) => {
      const response = await httpClient.put(
        `/projects/${projectId}/${processId}/complete`,
        { action },
      )
      return response.data
    },
  })
}

export const useCancelProcessStepMutation = (
  projectId: string,
  processId: ProcessId,
) => {
  return useMutation<null, AxiosError>({
    mutationFn: async () => {
      const response = await httpClient.put(
        `/projects/${projectId}/${processId}/cancel`,
      )
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["projects"],
      })
    },
    onError: async (error) => {
      if (isEnsolError(error)) {
        showNotification({
          title: "Impossible d'annuler l'étape",
          message: error.message,
          color: "red",
        })
      }
    },
  })
}

export const useStartProcessMutation = (
  projectId: string,
  processId: ProcessId,
) => {
  return useMutation<null, AxiosError>({
    mutationFn: async () => {
      const response = await httpClient.put(
        `/projects/${projectId}/${processId}/start`,
      )
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["projects"] })
    },
  })
}

export const useStartProjectMutation = (projectId: string) => {
  return useMutation<null, AxiosError>({
    mutationFn: async () => {
      const response = await httpClient.put(`/projects/${projectId}/start`)
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["projects"],
      })
    },
  })
}

export const useAbortProjectMutation = (projectId: string) => {
  return useMutation<null, AxiosError>({
    mutationFn: async () => {
      const response = await httpClient.put(`/projects/${projectId}/abort`)
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["projects"],
      })
    },
  })
}

export const useResumeProjectMutation = (projectId: string) => {
  return useMutation<null, AxiosError>({
    mutationFn: async () => {
      const response = await httpClient.put(`/projects/${projectId}/resume`)
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["projects"],
      })
    },
  })
}

export const useCreateEstimateMutation = (projectId: string) => {
  return useMutation<null, AxiosError>({
    mutationFn: async () => {
      const response = await httpClient.post(`/projects/${projectId}/estimate`)
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["projects"],
      })
    },
  })
}

export const useDeleteEstimateMutation = (projectId: string) => {
  return useMutation<null, AxiosError>({
    mutationFn: async () => {
      const response = await httpClient.delete(
        `/projects/${projectId}/estimate`,
      )
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["projects"],
      })
    },
  })
}

export const useGetEstimateQuery = (projectId: string) =>
  useQuery<ProjectResponses.Estimate | null>({
    queryKey: ["projects", projectId, "estimate"],
    queryFn: async () => {
      const response = await httpClient.get(`/projects/${projectId}/estimate`)
      return response.data
    },
  })

export const useUpdateProcessDataMutation = <T>(
  url: string,
  projectId: string,
) => {
  return useMutation<ProjectResponses.Project, unknown, T>({
    mutationFn: async (data) => {
      const response = await httpClient.put(
        `/projects/${projectId}/${url}`,
        data,
      )
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["projects"],
      })
    },
  })
}

export const useSendEmailMutation = (projectId: string, prospectId: string) => {
  return useMutation<ProjectResponses.Project, unknown, Nullable<EmailInput>>({
    mutationFn: async (data) => {
      const response = await httpClient.post(
        `/projects/${projectId}/email`,
        data,
      )
      return response.data
    },
    onSuccess: async () => {
      showNotification({
        message: "L'email a été envoyé au client !",
        color: "green",
      })
      await queryClient.invalidateQueries({
        queryKey: ["intercom", prospectId, "events"],
      })
    },
    onError: async (error) => {
      if (isZodError(error)) {
        showNotification({
          title: "Des informations sont manquantes ou incorrectes",
          message: formatZodValidationError(error.issues, EMAILS_FIELDS_LABELS),
          color: "red",
        })
      } else if (isEnsolError(error)) {
        showNotification({
          title: "Erreur lors de l'envoi de l'email",
          message: error.message,
          color: "red",
        })
      } else {
        showNotification({
          title: "Erreur lors de l'envoi de l'email",
          message: "Merci de réessayer",
          color: "red",
        })
      }
    },
  })
}

export const useCreateInstallationCertificateMutation = (projectId: string) => {
  return useMutation({
    mutationFn: async () => {
      const response = await httpClient.post(
        `/projects/${projectId}/installation-certificate`,
      )
      return response.data
    },
  })
}

export const useCreateBTACertificateMutation = (projectId: string) => {
  return useMutation({
    mutationFn: async () => {
      const response = await httpClient.post(
        `/projects/${projectId}/edf-oa-certificate`,
      )
      return response.data
    },
    onError: async (error) => {
      if (isEnsolError(error)) {
        showNotification({
          title: "Erreur lors de la génération de l'attestation BTA",
          message: error.message,
          color: "red",
        })
      } else {
        showNotification({
          title: "Erreur lors de la génération de l'attestation BTA",
          message: "Merci de réessayer",
          color: "red",
        })
      }
    },
  })
}

export const useGetProjectsEventsQuery = (filters: ProjectsEventsFilters) =>
  useQuery<ProjectResponses.ProjectsEvents>({
    queryKey: ["projects", "events", filters],
    queryFn: async () => {
      const response = await httpClient.get("/projects/events", {
        params: filters,
      })
      return response.data
    },
    placeholderData: keepPreviousData,
  })

type UpdateProjectEventDatesInput = {
  projectId: string
  type: ProjectEventType
  start: Date | null
  end: Date | null
}

export const updateProjectEventDates = async ({
  projectId,
  type,
  start,
  end,
}: UpdateProjectEventDatesInput) => {
  if (type === ProjectEventType.INSTALLATION) {
    await httpClient.put(`/projects/${projectId}/installation`, {
      installationStartDate: dayjs(start).add(12, "hour").toDate(),
      installationEndDate: dayjs(end).subtract(12, "hour").toDate(),
    })
  } else if (type === ProjectEventType.TECHNICAL_VISIT) {
    await httpClient.put(`/projects/${projectId}/technical-visit`, {
      technicalVisitStartDate: start,
    })
  } else {
    throw new Error("Unsupported event type")
  }

  await queryClient.invalidateQueries({
    queryKey: ["projects", "events"],
  })
}

export const useGetProjectFeedbackQuery = (projectId: string) =>
  useQuery<ProjectResponses.ProjectFeedback>({
    queryKey: ["projects", projectId, "feedback"],
    queryFn: async () => {
      const response = await httpClient.get(`/projects/${projectId}/feedback`)
      return response.data
    },
  })

export const useUpdateProjectFeedbackMutation = (projectId: string) => {
  return useMutation({
    mutationFn: async (data: FeedbackInput) => {
      const response = await httpClient.put(
        `/projects/${projectId}/feedback`,
        data,
      )
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["projects", projectId, "feedback"],
      })
    },
  })
}
