import { beforeM1FormSchema } from "@ensol/types/forms/projects/followUp"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { BeforeConsentForm } from "@ensol/entool/pages/FollowUpProject/steps/components/BeforeConsentForm"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

export const WaitingForCertificate = ({
  project,
  ...props
}: StepComponentProps) => (
  <BeforeConsentForm
    schema={beforeM1FormSchema}
    initialValues={getInitialValues(beforeM1FormSchema, project)}
    project={project}
    actions={[{ action: "validate", label: "En attente du consentement" }]}
    {...props}
  />
)
