export enum ContractHolderType {
  INDIVIDUAL = "INDIVIDUAL",
  COMPANY = "COMPANY",
}

export enum ConsentAskStatus {
  PREPARING_ASK = "PREPARING_ASK",
  ADDRESS_CHECK_FAILED = "ADDRESS_CHECK_FAILED",
  PENDING_USER_ACTION = "PENDING_USER_ACTION",
  ACCEPTED = "ACCEPTED",
}

export enum OrderStatus {
  PENDING = "PENDING",
  RETRY_LATER = "RETRY_LATER",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED",
}
