import { CopyButton, Button, MantineStyleProps } from "@mantine/core"
import { IconClipboardCheck, IconClipboardText } from "@tabler/icons-react"

type Props = {
  text: string
} & MantineStyleProps

export const CopiableText = ({ text, ...props }: Props) => (
  <CopyButton value={text}>
    {({ copied, copy }) => (
      <Button
        color="black"
        p="0"
        variant="transparent"
        size="sm"
        fw={400}
        h="fit-content"
        rightSection={copied ? <IconClipboardCheck /> : <IconClipboardText />}
        onClick={copy}
        styles={{
          inner: { justifyContent: "space-between" },
          label: {
            whiteSpace: "break-spaces",
            textAlign: "left",
            lineHeight: "20px",
          },
        }}
        {...props}
      >
        {text}
      </Button>
    )}
  </CopyButton>
)
