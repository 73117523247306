import { IconBarrierBlock } from "@tabler/icons-react"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { UpdateInstallationStep } from "@ensol/entool/components/form/Installation/UpdateInstallationStep"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useGetInstallationQuery } from "@ensol/entool/queries/installations"

export const UpdateInstallation = ({
  project,
  ...props
}: StepComponentProps) => {
  const simulationQuery = useGetInstallationQuery(project.installationId)

  return (
    <QueryWrapper query={simulationQuery}>
      {({ data: installation }) => (
        <UpdateInstallationStep
          installation={installation}
          project={project}
          {...props}
          actions={[
            {
              action: "hold",
              label: "Travaux importants",
              color: "red",
              Icon: IconBarrierBlock,
            },
            {
              action: "validate",
              label: "En attente des AE pour mise à jour du devis",
            },
          ]}
        />
      )}
    </QueryWrapper>
  )
}
