type OptimizerInfo = {
  name: string
  power: number
  price: number
  warranty: number
  techDocUrl: string
}

// TODO FIXME: Cannot import enum types in frontend https://github.com/prisma/prisma/issues/19261
export const OptimizerType = {
  HUAWEI_SUN_2000_450: "HUAWEI_SUN_2000_450",
} as const

export type OptimizerType = keyof typeof OptimizerType

export const OPTIMIZERS: Record<OptimizerType, OptimizerInfo> = {
  HUAWEI_SUN_2000_450: {
    name: "Huawei Sun2000 450W",
    power: 0.45,
    price: 60,
    warranty: 25,
    techDocUrl:
      "https://solar.huawei.com/en/download?p=%2F-%2Fmedia%2FSolar%2Fattachment%2Fpdf%2Ffr%2Fdatasheet%2FSUN2000-450W-P.pdf",
  },
}

export const getOptimizer = (
  optimizerType?: OptimizerType | null,
): OptimizerInfo | undefined =>
  optimizerType ? OPTIMIZERS[optimizerType] : undefined
