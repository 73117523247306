import React from "react"

export type OptionType = string | number | boolean

export type Option<T extends OptionType> = {
  label: string
  value: T
  subtitle?: string | React.ReactNode
  disabled?: boolean
  incompatible?: boolean
}

export const findSelectedOption = <T extends OptionType>(
  selectedValue: T | null,
  options: Option<T>[],
) => options?.find((option) => selectedValue === option.value)
