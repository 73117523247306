import { transformSchemaForApi } from "@ensol/types/forms/utils"
import * as z from "zod"

export const beforeM1FormSchema = z.object({
  followUpExternalPlantIdentifier: z.string().nullable().optional(),
  followUpNotes: z.string().optional(),
  followUpIssues: z.array(z.string()),
  installationEndDate: z.date({ invalid_type_error: "Champ obligatoire" }),
  followUpProductionStartDate: z.date({
    invalid_type_error: "Champ obligatoire",
  }),
})

export const M1ToSendFormSchema = beforeM1FormSchema.extend({
  followUpM1ReportsPaths: z.array(z.string()).min(1, "Champ obligatoire"),
})

export type FollowUpStepInput =
  | z.infer<typeof beforeM1FormSchema>
  | z.infer<typeof M1ToSendFormSchema>

export const followUpSchema = transformSchemaForApi(M1ToSendFormSchema)
