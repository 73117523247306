import * as z from "zod"

import { PanelType } from "@ensol/shared/material/photovoltaic/panels"
import { InverterType } from "@ensol/shared/material/photovoltaic/inverters"
import { OptimizerType } from "@ensol/shared/material/photovoltaic/optimizers"
import { PhotovoltaicSubsidyType } from "@ensol/shared/entities/installations/subsidies/photovoltaic"

import { extraWorksSchema } from "./extraWorks"

export const roofSectionWithPanelsSchema = z.object({
  panelsCount: z.number().nonnegative(),
  roofSection: z.object({
    id: z.string(),
    orientation: z.number(),
    inclination: z.number(),
  }),
})

export const photovoltaicInstallationSchema = z
  .object({
    panelsCount: z.number().nonnegative(),
    panelType: z.nativeEnum(PanelType),
    inverterType: z.nativeEnum(InverterType),
    optimizerType: z.nativeEnum(OptimizerType).nullable(),
    optimizerCount: z.number().nullable(),
    subsidyType: z.nativeEnum(PhotovoltaicSubsidyType).nullable(),
    roofSectionsWithPanels: z.array(roofSectionWithPanelsSchema).min(1),
  })
  .merge(extraWorksSchema)
