import { useMantineTheme } from "@mantine/core"
import { SVGProps } from "react"

type Props = {
  variant?: "primary" | "white"
} & SVGProps<SVGSVGElement>

export const Logo = ({ variant = "primary", ...props }: Props) => {
  const { colors } = useMantineTheme()
  return (
    <svg
      viewBox="0 0 117 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      height={100}
      {...props}
    >
      <path
        d="M.003 18.766c0-5.55 2.774-10.308 9.119-10.308 6.344 0 9.119 4.758 9.119 10.308v1.189l-.397.397H4.758l-.397.397c0 .792.397 4.758 5.155 4.758 1.586 0 3.172-.397 3.964-2.377l.397-.398h3.172l.398.398c-.398 3.172-3.173 5.947-7.93 5.947C3.171 29.077 0 24.716 0 18.769l.003-.003Zm13.877-1.983c0-1.189-.397-4.758-4.758-4.758s-4.758 3.569-4.758 4.758l.397.397h8.722l.397-.397ZM38.462 28.68H35.29l-.397-.398v-11.5c0-1.982-.397-4.757-3.964-4.757-3.566 0-5.155 1.983-5.155 6.741v9.516l-.397.398h-3.172l-.397-.398V9.25l.397-.397h2.775l.397.397.397 1.586h.397s1.586-2.378 5.155-2.378c5.55 0 7.533 3.57 7.533 8.327v11.5l-.397.397v-.002ZM49.962 29.074c-5.156 0-7.93-2.378-7.93-6.741l.396-.397H45.6l.398.397c0 1.983 1.189 3.172 3.964 3.172 2.774 0 3.963-.792 3.963-2.378 0-1.983-1.189-2.378-5.155-3.172-3.964-.792-6.344-2.378-6.344-5.947 0-2.775 1.983-5.55 7.533-5.55 4.361 0 7.533 1.983 7.533 5.947l-.397.397h-3.172l-.397-.397c0-1.189-.792-2.378-3.57-2.378-1.983 0-3.569.397-3.569 1.983 0 1.984 1.586 2.378 5.55 3.173 3.964.794 5.947 2.377 5.947 5.947 0 3.172-2.378 5.947-7.93 5.947l.008-.003ZM69.786 8.458c5.947 0 9.516 3.964 9.516 10.308 0 6.344-3.57 10.308-9.516 10.308-5.947 0-9.517-3.964-9.517-10.308 0-6.344 3.57-10.308 9.517-10.308Zm0 17.05c3.569 0 5.55-2.378 5.55-6.742 0-4.363-1.983-6.741-5.55-6.741-3.567 0-5.55 2.377-5.55 6.741s1.983 6.741 5.55 6.741ZM86.438 28.68h-3.172l-.397-.397V1.32l.397-.397h3.172l.397.397v26.963l-.397.397Z"
        fill={variant === "white" ? "#FFFFFF" : "#06125C"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m94.346.923-.334.487 1.261 3.547.682.488H109.2l.334-.488-1.261-3.547-.682-.487H94.346ZM98.37 13.54l-.334.488 1.261 3.547.682.487h13.246l.334-.487-1.261-3.547-.682-.488H98.37Zm2.788-5.82.334-.488h13.246l.681.487 1.262 3.547-.334.488h-13.246l-.682-.488-1.261-3.547Z"
        fill={variant === "white" ? "#FFFFFF" : "url(#Logo_svg__a)"}
      />
      <defs>
        <linearGradient
          id="Logo_svg__a"
          x1={108.471}
          y1={21.008}
          x2={96.098}
          y2={-1.312}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.blue[5]} />
          <stop offset={0.5} stopColor={colors.purple[5]} />
          <stop offset={1} stopColor={colors.pink[5]} />
        </linearGradient>
      </defs>
    </svg>
  )
}
