import { TechnicalVisitStep } from "@ensol/shared/entities/projects/processes"

import { StepComponentGetter } from "@ensol/entool/components/entities/Project/ProcessView"

import { Pending } from "./1-Pending"
import { WaitingForSalesChanges } from "./10-WaitingForSalesChanges"
import { WaitingForSignature } from "./11-WaitingForSignature"
import { ValidatedWithWorks } from "./12-ValidatedWithWorks"
import { OnHold } from "./13-OnHold"
import { Rejected } from "./14-Rejected"
import { Validated } from "./15-Validated"
import { SchedulingOnHold } from "./2-SchedulingOnHold"
import { CannotSchedule } from "./3-CannotSchedule"
import { Requested } from "./4-Requested"
import { InvitationRejected } from "./5-InvitationRejected"
import { Scheduled } from "./6-Scheduled"
import { Completed } from "./7-Completed"
import { BeingProcessed } from "./8-BeingProcessed"
import { UpdateInstallation } from "./9-UpdateInstallation"

export const getTechnicalVisitStepComponent: StepComponentGetter = (
  currentStep,
) => {
  switch (currentStep) {
    case TechnicalVisitStep.PENDING:
      return { StepComponent: Pending, isCancellable: false }
    case TechnicalVisitStep.SCHEDULING_ON_HOLD:
      return { StepComponent: SchedulingOnHold, isCancellable: false }
    case TechnicalVisitStep.CANNOT_SCHEDULE:
      return { StepComponent: CannotSchedule, isCancellable: false }
    case TechnicalVisitStep.REQUESTED:
      return { StepComponent: Requested, isCancellable: false }
    case TechnicalVisitStep.INVITATION_REJECTED:
      return { StepComponent: InvitationRejected, isCancellable: false }
    case TechnicalVisitStep.SCHEDULED:
      return { StepComponent: Scheduled, isCancellable: false }
    case TechnicalVisitStep.COMPLETED:
      return { StepComponent: Completed }
    case TechnicalVisitStep.BEING_PROCESSED:
      return { StepComponent: BeingProcessed }
    case TechnicalVisitStep.UPDATE_INSTALLATION:
      return { StepComponent: UpdateInstallation }
    case TechnicalVisitStep.WAITING_FOR_SALES_CHANGES:
      return { StepComponent: WaitingForSalesChanges }
    case TechnicalVisitStep.WAITING_FOR_SIGNATURE:
      return { StepComponent: WaitingForSignature }
    case TechnicalVisitStep.VALIDATED_WITH_WORKS:
      return { StepComponent: ValidatedWithWorks }
    case TechnicalVisitStep.ON_HOLD:
      return { StepComponent: OnHold }
    case TechnicalVisitStep.REJECTED:
      return { StepComponent: Rejected, isCancellable: false }
    case TechnicalVisitStep.VALIDATED:
      return { StepComponent: Validated }
    default:
      return { StepComponent: null }
  }
}
