import { Stack, TextInput } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconSearch } from "@tabler/icons-react"
import dayjs from "dayjs"
import _ from "lodash"
import { useState } from "react"
import { Helmet } from "react-helmet-async"

import { ProjectCard } from "@ensol/entool/components/entities/Project/Card"
import {
  ColumnWrapper,
  Column,
} from "@ensol/entool/components/entities/Project/Column"
import { IssuesBadges } from "@ensol/entool/components/entities/Project/IssuesBadges"
import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"
import { PageLayout } from "@ensol/entool/components/layout/PageLayout"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useListProjectsQuery } from "@ensol/entool/queries/projects"
import { CONTENT_PADDING_IN_PX } from "@ensol/entool/styles/constants"
import { devices } from "@ensol/entool/styles/theme"
import { getMonthlyReportUrl } from "@ensol/entool/utils/projects/reports"
import { FOLLOW_UP_PROCESS_VIEW } from "@ensol/entool/utils/projects/views"

export const FollowUpProjectsPage = () => {
  const isMobile = useMediaQuery(devices.xs)

  const [search, setSearch] = useState("")
  const projectsQuery = useListProjectsQuery({
    filters: { search, milestoneId: "followUpStep" },
  })

  const {
    doneMilestone,
    abortedMilestones,
    color: viewColor,
    warnDelayInDays,
    milestones,
  } = FOLLOW_UP_PROCESS_VIEW

  return (
    <PageLayout title="Suivi" contentContainerProps={{ px: 0 }}>
      <Helmet>
        <title>Suivi | Entool</title>
      </Helmet>
      <Stack miw="0" flex="1">
        <Stack mb="24" px={CONTENT_PADDING_IN_PX}>
          <TextInput
            w="100%"
            leftSectionPointerEvents="none"
            leftSection={<IconSearch />}
            placeholder="Rechercher un projet par nom, email, téléphone, ou adresse"
            onChange={_.debounce((e) => setSearch(e.target.value), 250)}
          />
        </Stack>
        <QueryWrapper query={projectsQuery}>
          {({ data }) => {
            const projectsByStep = _.groupBy(
              data,
              ({ followUpStep }) => followUpStep,
            )

            return (
              <ColumnWrapper>
                {Object.values(milestones).map(
                  ({ id: milestoneId, label, color }) => {
                    const projects = projectsByStep[milestoneId] ?? []

                    if (search !== "" && projects.length === 0) {
                      return null
                    }

                    return (
                      <Column
                        key={milestoneId}
                        backgroundColor={color}
                        title={label}
                        titleColor={viewColor}
                        count={projects.length}
                      >
                        {_.orderBy(
                          projects,
                          [
                            ({ followUpIssues }) => followUpIssues.length,
                            ({ followUpProductionStartDate }) =>
                              followUpProductionStartDate,
                          ],
                          ["desc", "asc"],
                        ).map((project) => {
                          const {
                            id,
                            installationSlug,
                            lastEventDateByProperty,
                            followUpProductionStartDate,
                            followUpIssues,
                          } = project
                          const daysSinceProduction = dayjs().diff(
                            followUpProductionStartDate,
                            "day",
                          )

                          return (
                            <ProjectCard
                              key={id}
                              project={project}
                              lastEventDate={
                                lastEventDateByProperty.followUpStep
                              }
                              warnDelayInDays={warnDelayInDays}
                              shouldDisplayDaysSinceLastEvent={
                                milestoneId !== doneMilestone &&
                                !abortedMilestones?.includes(milestoneId)
                              }
                              to={
                                !isMobile
                                  ? `/follow-ups/${project.id}`
                                  : getMonthlyReportUrl({
                                      installationSlug,
                                      productionStartDate:
                                        followUpProductionStartDate,
                                      isSimulated: false,
                                    })
                              }
                              style={
                                followUpIssues.length > 0
                                  ? {
                                      backgroundColor:
                                        "var(--mantine-color-red-0)",
                                      border:
                                        "2px solid var(--mantine-color-red-3)",
                                    }
                                  : {}
                              }
                            >
                              <ProcessInfos
                                infos={[
                                  {
                                    name: "Produit depuis",
                                    value: followUpProductionStartDate
                                      ? `${daysSinceProduction} jours`
                                      : null,
                                  },
                                  {
                                    name: "Statut",
                                    dataType: "component",
                                    value: (
                                      <IssuesBadges issues={followUpIssues} />
                                    ),
                                  },
                                ]}
                              />
                            </ProjectCard>
                          )
                        })}
                      </Column>
                    )
                  },
                )}
              </ColumnWrapper>
            )
          }}
        </QueryWrapper>
      </Stack>
    </PageLayout>
  )
}
