import { ProjectStatus as PrismaProjectStatus } from "@ensol/types/prisma-client/index.js"

import { ProcessId } from "@ensol/shared/entities/projects/processes"
import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"
import { getFullName } from "@ensol/shared/entities/prospects"
import {
  SHORT_DISPLAY_DATE_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"

import { InfoComponentProps } from "../config"

export const Overview = ({ project }: InfoComponentProps) => {
  const {
    salesOwner,
    operationsOwner,
    adminOwner,
    technicalVisitInstaller,
    installer,
    signatureDate,
    technicalVisitStartDate,
    preliminaryRequestExpectedValidationDate,
    preliminaryRequestLastContactDate,
    installationEndDate,
    orderDeliveryDate,
  } = project

  return (
    <ProcessInfos
      infos={[
        {
          name: "Respo. AE",
          value: salesOwner ? getFullName(salesOwner) : null,
        },
        {
          name: "Respo. Ops",
          value: operationsOwner ? getFullName(operationsOwner) : null,
        },
        {
          name: "Respo. Admin",
          value: adminOwner ? getFullName(adminOwner) : null,
        },
        {
          name: "Installateur",
          value: installer?.name ?? technicalVisitInstaller?.name,
        },
        {
          name: "Date signature",
          value: formatDate(signatureDate, SHORT_DISPLAY_DATE_FORMAT),
          isHidden: project.status !== ProjectStatus.REVIEWED,
        },
        {
          name: "Date VT",
          value: formatDate(technicalVisitStartDate, SHORT_DISPLAY_DATE_FORMAT),
          isHidden:
            project.status !== ProjectStatus.STARTED &&
            project.status !== ProjectStatus.ADMINISTRATIVELY_VALIDATED,
        },
        {
          name: "Date prévue DP",
          value: formatDate(
            preliminaryRequestExpectedValidationDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          isHidden:
            project.status !== ProjectStatus.STARTED &&
            project.status !== ProjectStatus.TECHNICALLY_VALIDATED,
        },
        {
          name: "Contact mairie",
          value: formatDate(
            preliminaryRequestLastContactDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          isHidden: project.status !== ProjectStatus.TECHNICALLY_VALIDATED,
        },
        {
          name: "Installation",
          value: formatDate(installationEndDate, SHORT_DISPLAY_DATE_FORMAT),
          isHidden:
            project.status !== ProjectStatus.VALIDATED &&
            project.status !== ProjectStatus.DEPOSIT_INVOICE_PAID,
        },
        {
          name: "Livraison matériel",
          value: formatDate(orderDeliveryDate, SHORT_DISPLAY_DATE_FORMAT),
          isHidden: project.status !== ProjectStatus.DEPOSIT_INVOICE_PAID,
        },
      ]}
      milestones={{
        processes: getProcessesToDisplayByProjectStatus(project.status),
        project,
      }}
    />
  )
}

const getProcessesToDisplayByProjectStatus = (
  status: PrismaProjectStatus,
): ProcessId[] => {
  switch (status) {
    case ProjectStatus.STARTED:
      return ["preliminaryRequestStep", "technicalVisitStep"]
    case ProjectStatus.TECHNICALLY_VALIDATED:
      return ["preliminaryRequestStep"]
    case ProjectStatus.ADMINISTRATIVELY_VALIDATED:
      return ["technicalVisitStep"]
    case ProjectStatus.VALIDATED:
      return ["installationStep", "hardwareStep", "paymentStep"]
    case ProjectStatus.DEPOSIT_INVOICE_PAID:
      return ["hardwareStep"]
    case ProjectStatus.INSTALLATION_DONE:
      return ["paymentStep", "installationStep", "enedisStep", "consuelStep"]
    case ProjectStatus.FINAL_INVOICE_PAID:
      return ["installationStep", "enedisStep", "consuelStep"]
    case ProjectStatus.DONE:
      return ["edfOaStep", "greendealStep"]
    default:
      return []
  }
}
