import { ExtraWorkType } from "@ensol/shared/material/extraWorks"
import * as z from "zod"

export const extraWorksSchema = z.object({
  extraWorks: z.array(
    z.object({
      type: z.nativeEnum(ExtraWorkType, {
        invalid_type_error: "Champ obligatoire",
      }),
      isBilled: z.boolean(),
    }),
  ),
})
