import { SimpleGrid, Stack, Textarea } from "@mantine/core"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import {
  PostDepositStepInput,
  postDepositStepsFormSchema,
} from "@ensol/types/forms/projects/payment"

import { Field } from "@ensol/entool/components/form/Field"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { PennylaneEstimate } from "@ensol/entool/pages/OpsProject/processes/Payment/components/PennylaneEstimate"
import { useProcessForm } from "@ensol/entool/utils/projects/useProcessForm"

type Props = {
  project: ProjectResponses.Project
  estimate: ProjectResponses.Estimate
}

export const PostDepositPaymentForm = ({ project, estimate }: Props) => {
  const form = useProcessForm<PostDepositStepInput>({
    schema: postDepositStepsFormSchema,
    initialValues: { paymentNotes: project.paymentNotes },
    project,
    url: "payment",
  })

  return (
    <Stack>
      <SimpleGrid cols={2} spacing="32">
        <Field name="Notes" noBorder>
          <Textarea
            autosize
            w={FIELD_WIDTH}
            minRows={3}
            {...form.getInputProps("paymentNotes")}
          />
        </Field>
      </SimpleGrid>
      <PennylaneEstimate estimate={estimate} project={project} />
    </Stack>
  )
}
