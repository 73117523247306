import { IconX, IconPencil, IconBarrierBlock } from "@tabler/icons-react"

import { beingProcessedStepFormSchema } from "@ensol/types/forms/projects/technicalVisit"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

import { TechnicalVisitForm } from "./components/TechnicalVisitForm"

export const BeingProcessed = ({ project, ...props }: StepComponentProps) => (
  <TechnicalVisitForm
    schema={beingProcessedStepFormSchema}
    initialValues={getInitialValues(beingProcessedStepFormSchema, project)}
    project={project}
    actions={[
      {
        action: "reject",
        label: "Rejeter",
        color: "red",
        Icon: IconX,
      },
      {
        action: "hold",
        label: "Travaux importants",
        color: "red",
        Icon: IconBarrierBlock,
      },
      {
        action: "validateWithWorks",
        label: "Valider avec des travaux",
        color: "orange",
        Icon: IconBarrierBlock,
      },
      {
        action: "validateWithChanges",
        label: "Valider avec des changements",
        color: "orange",
        Icon: IconPencil,
      },
      {
        action: "validate",
        label: "Valider",
      },
    ]}
    {...props}
  />
)
