import { HousesResponses } from "@ensol/types/endpoints/houses"

import { Field } from "@ensol/entool/components/form/Field"
import { MultiFileField } from "@ensol/entool/components/form/File/MultiFileField"
import { getHousePhotoFileName } from "@ensol/entool/utils/house/photos"

type Props = {
  houseStreetAddress: HousesResponses.House["streetAddress"]
  value?: string[]
  error?: string
  onChange: (filePath: string[]) => void
  label: string
  fileNameExtension: string
  isRequired: boolean
}

export const HousePhotosField = ({
  houseStreetAddress,
  value,
  error,
  onChange,
  label,
  fileNameExtension,
  isRequired,
}: Props) => (
  <Field name={label} noBorder withAsterisk={isRequired}>
    <MultiFileField
      previewProps={{
        fileName: getHousePhotoFileName(houseStreetAddress, fileNameExtension),
      }}
      inputProps={{
        accept: ["image/png", "image/jpeg", "image/heic"],
        capture: "environment",
      }}
      value={value ?? []}
      error={error}
      onChange={onChange}
    />
  </Field>
)
