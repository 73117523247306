import { Group, Stack, Text, TitleOrder } from "@mantine/core"
import { IconBolt, IconHome, IconSkewY } from "@tabler/icons-react"
import { useState } from "react"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { ProspectResponses } from "@ensol/types/endpoints/prospects"

import { getCoords } from "@ensol/shared/entities/houses/coords"
import { CURRENT_CONNECTIONS } from "@ensol/shared/material/currentType"
import { ROOF_TYPES } from "@ensol/shared/material/roofType"

import { Map } from "@ensol/entool/components/Map"
import { Section } from "@ensol/entool/components/Section"

import { EditHouseButton } from "./EditHouseButton"

type Props = {
  house: ProspectResponses.HouseWithRoofSections
  onSuccess?: (house: ProspectResponses.HouseWithRoofSections) => void
  titleOrder?: TitleOrder
  panelsLayout?: InstallationsResponses.Installation["panelsLayout"]
}

export const HouseSection = ({
  house,
  titleOrder,
  panelsLayout,
  onSuccess,
}: Props) => {
  const [savedHouse, setSavedHouse] =
    useState<ProspectResponses.HouseWithRoofSections>(house)

  return (
    <Section
      title="Maison"
      icon={IconHome}
      titleOrder={titleOrder}
      isCollapsible
    >
      {savedHouse && (
        <Group w="100%" align="flex-start" wrap="nowrap">
          <Stack w="100%" gap={8} miw={300}>
            <Text fw="bold">
              {savedHouse.streetAddress}
              <br />
              {savedHouse.postcode} {savedHouse.city}
            </Text>
            <Group gap={8} c="gray.6">
              <IconBolt strokeWidth={1.5} />
              <Text>{CURRENT_CONNECTIONS[savedHouse.currentType].name}</Text>
            </Group>
            <Group gap={8} c="gray.6">
              <IconSkewY strokeWidth={1.5} />
              <Text>{ROOF_TYPES[savedHouse.roofType].name}</Text>
            </Group>
            <EditHouseButton
              house={savedHouse}
              onSuccess={(house) => {
                setSavedHouse(house)
                onSuccess?.(house)
              }}
              buttonProps={{ w: 180, variant: "outline" }}
            />
          </Stack>
          <Stack w="100%" maw={500}>
            <Map
              mapId="simulator-map"
              coords={getCoords(savedHouse)}
              panelsLayout={panelsLayout}
              style={{ height: 250 }}
            />
          </Stack>
        </Group>
      )}
    </Section>
  )
}
