import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { SimulationResults, SimulationWarnings } from "@ensol/types/simulation"

import {
  computeAllCosts,
  computeEffectiveMargin,
} from "@ensol/shared/entities/installations/costs"
import {
  CURRENT_CONNECTIONS,
  CurrentType,
} from "@ensol/shared/material/currentType"

import {
  checkInverterCompatibility,
  computePhotovoltaicInstallationCapacity,
} from "./energy"
import {
  computeFinancialData,
  computeMonthsBeforeBreakEvenDate,
} from "./savings"

type SimulationInput = {
  installationDate: Date
  monthlyBill: number
  yearlyConsumption: number
  estimatedYearlyProduction: number
  autoConsumptionPercent: number
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation
  batteryInstallation: InstallationsResponses.BatteryInstallation | null
  evChargerInstallation: InstallationsResponses.EvChargerInstallation | null
  house: Pick<
    HousesResponses.House,
    | "constructionYear"
    | "roofType"
    | "currentType"
    | "hasFlatRoof"
    | "hasGroundInstallation"
  >
  totalDiscount: number
}

type WarningsInput = {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation
  house: Pick<HousesResponses.House, "currentType">
  installationCost: number
  installationCostWithDiscount: number
}

const computeWarnings = ({
  photovoltaicInstallation,
  house: { currentType },
  installationCost,
  installationCostWithDiscount,
}: WarningsInput): SimulationWarnings => {
  const currentConnection = CURRENT_CONNECTIONS[currentType]
  const { panelType, inverterType, panelsCount } = photovoltaicInstallation

  const installedCapacity = computePhotovoltaicInstallationCapacity({
    panelType,
    panelsCount,
  })

  const effectiveMargin = computeEffectiveMargin({
    installationCost,
    installationCostWithDiscount,
  })

  const { DCACPercent } = checkInverterCompatibility(
    inverterType,
    panelType,
    panelsCount,
    currentType,
  )

  return {
    installedCapacity:
      installedCapacity > 6 && currentType === CurrentType.SINGLE_PHASE
        ? `Installation ${currentConnection.name.toLowerCase()} de ${installedCapacity}kWc contre 6kWc max installables. Le surplus (production-consommation) sera écrêté à la revente. Les économies réelles seront donc diminuées. Attention au ratio DC/AC de l'onduleur (${DCACPercent}%).`
        : undefined,
    totalDiscount:
      effectiveMargin < 0.15
        ? `La marge effective (${Math.round(
            effectiveMargin * 100,
          )}%) est inférieure à 15%.`
        : undefined,
  }
}

export const runSimulation = ({
  installationDate,
  monthlyBill,
  yearlyConsumption,
  estimatedYearlyProduction,
  autoConsumptionPercent,
  photovoltaicInstallation,
  batteryInstallation,
  evChargerInstallation,
  house,
  totalDiscount,
}: SimulationInput): SimulationResults => {
  const {
    installationCost,
    installationCostWithDiscount,
    grossInstallationCost,
    netInstallationCost,
    subsidies,
  } = computeAllCosts({
    photovoltaicInstallation,
    batteryInstallation,
    evChargerInstallation,
    house,
    totalDiscount,
  })

  const monthsBeforeBreakEvenDate = computeMonthsBeforeBreakEvenDate({
    installationDate,
    monthlyBill,
    yearlyConsumption,
    estimatedYearlyProduction,
    autoConsumptionPercent,
    netInstallationCost,
    photovoltaicInstallation,
  })

  const financialData = computeFinancialData({
    installationDate,
    monthlyBill,
    yearlyConsumption,
    estimatedYearlyProduction,
    autoConsumptionPercent,
    netInstallationCost,
    photovoltaicInstallation,
  })

  const warnings = computeWarnings({
    photovoltaicInstallation,
    house,
    installationCost,
    installationCostWithDiscount,
  })

  return {
    subsidies,
    installedCapacity: computePhotovoltaicInstallationCapacity(
      photovoltaicInstallation,
    ),
    autoConsumptionPercent,
    yearlyConsumption,
    estimatedYearlyProduction,
    totalDiscount,
    installationCost,
    grossInstallationCost,
    installationCostWithDiscount,
    netInstallationCost,
    monthsBeforeBreakEvenDate,
    warnings,
    ...financialData,
  }
}
