import { IconBarrierBlock, IconX } from "@tabler/icons-react"

import { beingProcessedStepFormSchema } from "@ensol/types/forms/projects/technicalVisit"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

import { TechnicalVisitForm } from "./components/TechnicalVisitForm"

export const WaitingForSignature = ({
  project,
  ...props
}: StepComponentProps) => (
  <TechnicalVisitForm
    schema={beingProcessedStepFormSchema}
    initialValues={getInitialValues(beingProcessedStepFormSchema, project)}
    project={project}
    actions={[
      {
        action: "reject",
        label: "Rejeter la visite technique",
        color: "red",
        Icon: IconX,
      },
      {
        action: "validateWithWorks",
        label: "Valider avec des travaux",
        color: "orange",
        Icon: IconBarrierBlock,
      },
      {
        action: "validate",
        label: "Valider la visite technique",
      },
    ]}
    {...props}
  />
)
