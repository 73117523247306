import { notifications, showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useNavigate } from "react-router-dom"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"
import { HouseInput } from "@ensol/types/forms/houses"
import {
  ListProspectsFilters,
  ProspectInput,
  UpdateProspectInput,
} from "@ensol/types/forms/prospects"
import { Prospect } from "@ensol/types/prisma-client/index.js"

import { getFullName } from "@ensol/shared/entities/prospects"
import { isEnsolError } from "@ensol/shared/utils/errors"

import { httpClient } from "@ensol/entool/backend/axios"
import { queryClient } from "@ensol/entool/backend/queryClient"

type ListProspectOptions<T> = {
  select?: (data: ProspectResponses.ProspectsList) => T
  filters?: ListProspectsFilters
}

export const useListProspectsQuery = <T = ProspectResponses.ProspectsList>({
  select,
  filters,
}: ListProspectOptions<T>) => {
  return useQuery<ProspectResponses.ProspectsList, unknown, T>({
    queryKey: ["prospects", filters],
    queryFn: async () => {
      const response = await httpClient.get("/prospects", { params: filters })
      return response.data
    },
    select,
  })
}

export const useGetProspectQuery = (prospectId: string) => {
  return useQuery<ProspectResponses.ProspectDetails>({
    queryKey: ["prospects", prospectId],
    queryFn: async () => {
      const response = await httpClient.get(`/prospects/${prospectId}`)
      return response.data
    },
  })
}

export const useCreateProspectMutation = () => {
  const navigate = useNavigate()

  return useMutation<Prospect, string, ProspectInput>({
    mutationFn: async (prospect) => {
      const response = await httpClient.post<Prospect>("/prospects", prospect)
      return response.data
    },
    onSuccess: (prospect) => {
      showNotification({
        message: `Prospect ${getFullName(prospect)} créé !`,
      })
      navigate(`/prospects/${prospect.id}`)
    },
  })
}

export const useUpdateProspectMutation = (
  prospectId: string,
  onSuccess?: () => void,
) => {
  return useMutation<
    ProspectResponses.Prospect,
    AxiosError,
    UpdateProspectInput
  >({
    mutationFn: async (prospect) => {
      const response = await httpClient.put(
        `/prospects/${prospectId}`,
        prospect,
      )
      return response.data
    },
    onSuccess: async () => {
      await onSuccess?.()
      await queryClient.invalidateQueries({
        queryKey: ["prospects", prospectId],
      })
    },
    onError: (error) => {
      if (isEnsolError(error)) {
        showNotification({
          title: "Erreur lors de la mise à jour du client",
          message: error.message,
          color: "red",
        })
      } else {
        showNotification({
          title: "Erreur lors de la mise à jour du client",
          message: "Merci de réessayer",
          color: "red",
        })
      }
    },
  })
}

export const useCreateProspectHouseMutation = (
  prospectId: string,
  onSuccess: (data: ProspectResponses.HouseWithRoofSections) => void,
) => {
  return useMutation<
    ProspectResponses.HouseWithRoofSections,
    AxiosError,
    HouseInput
  >({
    mutationFn: async (house) => {
      const response = await httpClient.post(
        `/prospects/${prospectId}/houses`,
        house,
      )
      return response.data
    },

    onSuccess: async (data) => {
      onSuccess(data)
      await queryClient.invalidateQueries({
        queryKey: ["prospects", prospectId],
      })
      notifications.show({ message: "Maison créée !" })
    },
  })
}
