import { Button, Group } from "@mantine/core"
import { IconX, IconRotate360 } from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"

import {
  useAbortProjectMutation,
  useResumeProjectMutation,
} from "@ensol/entool/queries/projects"

type Props = {
  project: ProjectResponses.Project
}

export const ProjectActions = ({ project }: Props) => {
  const { mutateAsync: abortProject } = useAbortProjectMutation(project.id)
  const { mutateAsync: resumeProject } = useResumeProjectMutation(project.id)

  if (project.status === ProjectStatus.ABORTED) {
    return (
      <Button
        leftSection={<IconRotate360 size={14} />}
        variant="outline"
        onClick={() => resumeProject()}
      >
        Relancer le projet
      </Button>
    )
  }

  return (
    <Group>
      <Button
        leftSection={<IconX size={14} />}
        variant="outline"
        color="red"
        onClick={() => abortProject()}
      >
        Abandonner le projet
      </Button>
    </Group>
  )
}
