import { Button, List, Stack, Text } from "@mantine/core"
import { modals } from "@mantine/modals"
import { showNotification } from "@mantine/notifications"
import { IconChevronsRight } from "@tabler/icons-react"
import { useCallback } from "react"

import { Pick } from "@ensol/types/prisma-client/runtime/library"

import { isZodError, isEnsolError } from "@ensol/shared/utils/errors"

import { queryClient } from "@ensol/entool/backend/queryClient"
import {
  StepActionProps,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { useEmailsToSend } from "@ensol/entool/components/entities/Project/StepActions/useEmailsToSend"
import { useCompleteProcessStepMutation } from "@ensol/entool/queries/projects"
import { formatZodValidationError } from "@ensol/entool/utils/form/validation"
import { confirmAction } from "@ensol/entool/utils/helpers/confirmModal"
import { PROJECT_FIELDS_LABELS } from "@ensol/entool/utils/projects/fieldsLabels"

type Props = Pick<
  StepActionsProps,
  "project" | "processId" | "emails" | "validateStep"
> & {
  actionProps: StepActionProps
}

export const StepAction = ({
  project,
  processId,
  emails,
  validateStep,
  actionProps: {
    action,
    isStepCompletion = true,
    label,
    color,
    Icon,
    confirmationMessage,
    validateAction,
    onClick,
  },
}: Props) => {
  const { mutateAsync: completeStep, isPending: isCompletingStep } =
    useCompleteProcessStepMutation()

  const { emailsToSend, areEmailsLoading } = useEmailsToSend(
    project.installation.prospectId,
    emails,
  )

  const performAction = useCallback(async () => {
    try {
      validateStep?.()
      validateAction?.(project)
      await onClick?.(project)
      if (isStepCompletion) {
        await completeStep({ projectId: project.id, processId, action })
        await queryClient.invalidateQueries({
          queryKey: ["projects"],
        })
      }
    } catch (error) {
      if (isZodError(error)) {
        showNotification({
          title: "Des informations sont manquantes ou incorrectes",
          message: formatZodValidationError(
            error.issues,
            PROJECT_FIELDS_LABELS,
          ),
          autoClose: 5000,
          color: "red",
        })
      } else if (isEnsolError(error)) {
        showNotification({
          title: "Une condition est manquante pour passer l'étape",
          message: error.message,
          autoClose: 5000,
          color: "red",
        })
      }
    }
  }, [
    project,
    processId,
    action,
    isStepCompletion,
    onClick,
    validateStep,
    validateAction,
    completeStep,
  ])

  return (
    <Button
      key={`${action}-${label}`}
      leftSection={Icon ? <Icon size={14} /> : <IconChevronsRight size={14} />}
      variant={action === "validate" ? "filled" : "outline"}
      color={color}
      disabled={isCompletingStep || areEmailsLoading}
      onClick={async () => {
        if (emailsToSend.length > 0) {
          return modals.openConfirmModal({
            title: "Emails à envoyer",
            children: (
              <Stack gap="4">
                <Text size="sm">
                  Ces emails auraient du être envoyés à cette étape
                </Text>
                <List size="sm" withPadding>
                  {emailsToSend.map(({ label }) => (
                    <List.Item key={label}>{label}</List.Item>
                  ))}
                </List>
                <Text size="sm" mt="16">
                  Voulez-vous passer à l&apos;étape suivante quand même ?
                </Text>
              </Stack>
            ),
            labels: { confirm: "Confirmer", cancel: "Annuler" },
            centered: true,
            onConfirm: performAction,
          })
        }

        if (confirmationMessage) {
          return confirmAction({
            message: confirmationMessage,
            onConfirm: performAction,
          })
        }

        await performAction()
      }}
    >
      {label ?? "Valider l'étape"}
    </Button>
  )
}
