import { SignIn } from "@clerk/clerk-react"
import * as Sentry from "@sentry/react"
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom"

import { AppShell } from "@ensol/entool/components/layout/AppShell"
import { FollowUpProjectPage } from "@ensol/entool/pages/FollowUpProject"
import { FollowUpProjectsPage } from "@ensol/entool/pages/FollowUpProjects"
import { Home } from "@ensol/entool/pages/Home"
import { PanelsLayoutPage } from "@ensol/entool/pages/PanelsLayout"
import { ProjectFeedbackPage } from "@ensol/entool/pages/ProjectFeedback"
import { ProjectsEventsPage } from "@ensol/entool/pages/ProjectsEvents"
import { ProspectsPage } from "@ensol/entool/pages/Prospects"
import { SalesTasksPage } from "@ensol/entool/pages/SalesTasks"
import { SalesVisitPage } from "@ensol/entool/pages/SalesVisit"

import { AuthGuard } from "./AuthGuard"
import { AuthProvider } from "./AuthProvider"
import { InstallationPage, NewInstallationPage } from "./Installation"
import { NewProspectPage } from "./NewProspect"
import { OpsProjectPage } from "./OpsProject"
import { OpsProjectsPage } from "./OpsProjects"
import { ProspectPage } from "./Prospect"

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthProvider />}>
      <Route element={<AppShell />}>
        <Route path="/sign-in/*" element={<SignIn path="/sign-in" />} />
        <Route element={<AuthGuard />}>
          <Route index element={<Home />} />
          <Route path="/prospects">
            <Route index element={<ProspectsPage />} />
            <Route path="new" element={<NewProspectPage />} />
            <Route path=":prospectId">
              <Route index element={<ProspectPage />} />
            </Route>
          </Route>
          <Route path="/installations">
            <Route path="new/:houseId" element={<NewInstallationPage />} />
            <Route path=":installationId">
              <Route index element={<InstallationPage />} />
              <Route path="panels-layout" element={<PanelsLayoutPage />} />
            </Route>
          </Route>
          <Route path="/projects">
            <Route index element={<OpsProjectsPage />} />
            <Route path=":projectId" element={<OpsProjectPage />} />
          </Route>
          <Route path="/events">
            <Route index element={<ProjectsEventsPage />} />
          </Route>
          <Route path="/follow-ups">
            <Route index element={<FollowUpProjectsPage />} />
            <Route path=":projectId" element={<FollowUpProjectPage />} />
          </Route>
          <Route path="/sales">
            <Route index element={<SalesTasksPage />} />
            <Route path=":projectId" element={<SalesVisitPage />} />
          </Route>
          <Route path="/feedbacks">
            <Route path=":projectId" element={<ProjectFeedbackPage />} />
          </Route>
        </Route>
      </Route>
    </Route>,
  ),
)
