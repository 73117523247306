import { Loader, Stack, Title } from "@mantine/core"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import {
  ProspectInput,
  createProspectSchema,
} from "@ensol/types/forms/prospects"
import { Nullable } from "@ensol/types/utils"

import { Gender, ResidentialStatus } from "@ensol/shared/entities/prospects"
import { isZodError } from "@ensol/shared/utils/errors"
import { validateBody } from "@ensol/shared/utils/validation"

import { ContentContainer } from "@ensol/entool/components/layout/ContentContainer"
import { FallbackForm } from "@ensol/entool/pages/NewProspect/FallbackForm"
import { useCreateProspectMutation } from "@ensol/entool/queries/prospects"

export const NewProspectPage = () => {
  const [searchParams] = useSearchParams()
  const [newProspectInput, setNewProspectInput] =
    useState<Nullable<ProspectInput> | null>(null)

  const { mutateAsync: createProspect, isPending } = useCreateProspectMutation()
  const [error, setError] = useState<string>()

  useEffect(() => {
    const phone = searchParams.get("phone") ?? ""
    const sanitizedPhone = phone.replace(/\s/g, "")

    const newProspectInput = {
      firstName: searchParams.get("firstName"),
      lastName: searchParams.get("lastName"),
      email: searchParams.get("email"),
      phone: sanitizedPhone.length > 10 ? `+${sanitizedPhone}` : sanitizedPhone,
      residentialStatus:
        (searchParams.get("residentialStatus") as
          | ResidentialStatus
          | undefined) ?? ResidentialStatus.OWNER,
      hubspotContactId: searchParams.get("hubspotContactId") ?? null,
      gender: (searchParams.get("gender")?.toUpperCase() as Gender) ?? null,
    }
    setNewProspectInput(newProspectInput)

    validateBody(newProspectInput, createProspectSchema)
      .then(async (prospect) => {
        await createProspect(prospect)
      })
      .catch((err) => {
        if (isZodError(err)) setError(err.message)
        else setError(err)
      })
  }, [createProspect, searchParams])

  if (isPending)
    return (
      <ContentContainer flex="1">
        <Stack align="center" gap={48}>
          <Title>Création du prospect...</Title>
          <Loader />
        </Stack>
      </ContentContainer>
    )
  if (error && newProspectInput !== null) {
    return <FallbackForm {...newProspectInput} />
  }
  return null
}
