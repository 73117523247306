import { IconHomeCheck } from "@tabler/icons-react"

import { FollowUpStepInput } from "@ensol/types/forms/projects/followUp"

import { DISPLAY_DATE_FORMAT } from "@ensol/shared/utils/format"

import { Section } from "@ensol/entool/components/Section"
import { FormField } from "@ensol/entool/components/entities/Project/FormField"
import { StepActionsProps } from "@ensol/entool/components/entities/Project/StepActions"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { UpdateInstallationStep } from "@ensol/entool/components/form/Installation/UpdateInstallationStep"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { FollowUpForm } from "@ensol/entool/pages/FollowUpProject/steps/components/FollowUpForm"
import { useGetInstallationQuery } from "@ensol/entool/queries/installations"
import { isFormValid } from "@ensol/entool/utils/form/validation"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const BeforeConsentForm = <Input extends FollowUpStepInput>({
  project,
  processId,
  actions,
  schema,
  initialValues,
}: ProcessFormProps<Input> & StepActionsProps) => {
  const simulationQuery = useGetInstallationQuery(project.installationId)
  const form = useProcessForm<Input>({
    schema,
    initialValues,
    project,
    url: "follow-up",
  })

  return (
    <QueryWrapper query={simulationQuery}>
      {({ data: installation }) => (
        <UpdateInstallationStep
          installation={installation}
          project={project}
          processId={processId}
          actions={actions.map((action) => ({
            ...action,
            validateAction: () => schema.parse(form.values),
            isDisabled: !isFormValid(form),
          }))}
        >
          <Section title="Suivi" icon={IconHomeCheck} titleOrder={4}>
            <FollowUpForm
              project={project}
              form={form}
              initialValues={initialValues}
            >
              <FormField
                initialValues={initialValues}
                propertyName="installationEndDate"
                isRequired
              >
                <DateInput
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_FORMAT}
                  clearable
                  minDate={project.installationStartDate ?? undefined}
                  {...form.getInputProps("installationEndDate")}
                />
              </FormField>
            </FollowUpForm>
          </Section>
        </UpdateInstallationStep>
      )}
    </QueryWrapper>
  )
}
