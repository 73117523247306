import { Title, Group } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"

import { GENDERS, getFullName } from "@ensol/shared/entities/prospects"

import { Header, HeaderProps } from "@ensol/entool/components/Header"
import { EditProspectButton } from "@ensol/entool/components/form/Prospect/EditProspectButton"
import { devices } from "@ensol/entool/styles/theme"

export type ProspectHeaderProps = HeaderProps & {
  prospect: ProspectResponses.Prospect
  onProspectEditSuccess?: () => void
}

export const ProspectHeader = ({
  prospect,
  onProspectEditSuccess,
  LeftComponent,
  ...props
}: ProspectHeaderProps) => {
  const isSmallScreen = useMediaQuery(devices.md)

  return (
    <Header
      {...props}
      LeftComponent={
        <>
          <Group gap="8">
            <Title
              order={isSmallScreen ? 4 : 1}
            >{`${prospect.gender ? GENDERS[prospect.gender].short : ""} ${getFullName(prospect)}`}</Title>
            <EditProspectButton
              prospect={prospect}
              onSuccess={onProspectEditSuccess}
            />
          </Group>
          {LeftComponent}
        </>
      }
    />
  )
}
