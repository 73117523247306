type EvChargerBrand = "Wallbox"

type EvChargerInfo = {
  name: string
  longName: string
  power: number
  brand: EvChargerBrand
  price: number
  warranty: number
  techDocUrl: string
}

export const EvChargerType = {
  WALLBOX_PULSAR_PLUS_SOCKET_MONO: "WALLBOX_PULSAR_PLUS_SOCKET_MONO",
  WALLBOX_PULSAR_PLUS_SOCKET_TRI: "WALLBOX_PULSAR_PLUS_SOCKET_TRI",
} as const

export type EvChargerType = keyof typeof EvChargerType

export const EV_CHARGERS: Record<EvChargerType, EvChargerInfo> = {
  WALLBOX_PULSAR_PLUS_SOCKET_MONO: {
    name: "Wallbox Monophasée",
    longName: "Wallbox Pulsar Plus Socket 7,4kW 1P - IP55",
    power: 7.4,
    brand: "Wallbox",
    price: 493.92,
    warranty: 3,
    techDocUrl:
      "https://headless-api.wallbox.com/wp-content/uploads/2024/01/FR_20231213_DS_PulsarPlusSocket.pdf?_gl=1*1d5ol5y*_gcl_au*MzE4MzUyMzQ5LjE3MTgwMTQ3NjU.",
  },
  WALLBOX_PULSAR_PLUS_SOCKET_TRI: {
    name: "Wallbox Triphasée",
    longName: "Wallbox Pulsar Plus Socket 22kW 3P - IP55",
    power: 22,
    brand: "Wallbox",
    price: 493.92,
    warranty: 3,
    techDocUrl:
      "https://headless-api.wallbox.com/wp-content/uploads/2024/01/FR_20231213_DS_PulsarPlusSocket.pdf?_gl=1*1d5ol5y*_gcl_au*MzE4MzUyMzQ5LjE3MTgwMTQ3NjU.",
  },
}

export const getEvCharger = (type: EvChargerType): EvChargerInfo =>
  EV_CHARGERS[type]

type SmartMeterInfo = {
  name: string
  price: number
}

const SMART_METER_MONO: SmartMeterInfo = {
  name: "Monophasé - pour délestage / charge solaire",
  price: 88.8,
}

const SMART_METER_TRI: SmartMeterInfo = {
  name: "Triphasé - pour délestage / charge solaire",
  price: 172.32,
}

export const getEvChargerSmartMeter = (
  evChargerType: EvChargerType,
): SmartMeterInfo =>
  evChargerType === EvChargerType.WALLBOX_PULSAR_PLUS_SOCKET_MONO
    ? SMART_METER_MONO
    : SMART_METER_TRI
