import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { EvChargerQuoteDetails } from "@ensol/types/quote"

import {
  computeAllEvChargerCosts,
  computeEvChargerAccessoriesPrice,
  computeEvChargerSmartMeterPrice,
  computeEvChargerUnitPrice,
  computeEvChargerWorkforceCost,
  EV_CHARGER_VAT_RATE,
} from "@ensol/shared/entities/installations/costs/evCharger"
import { computeExtraWorksQuoteDetails } from "@ensol/shared/entities/quotes/details/extraWorks"
import { getEvCharger } from "@ensol/shared/material/evCharger"

export const computeEvChargerQuoteDetails = (
  evChargerInstallation: InstallationsResponses.EvChargerInstallation,
): EvChargerQuoteDetails => {
  const { installationCost, netInstallationCost, subsidy } =
    computeAllEvChargerCosts({
      evChargerInstallation,
    })

  const evCharger = getEvCharger(evChargerInstallation.type)

  return {
    material: {
      evCharger: {
        name: "Borne de recharge",
        description: `${evCharger.longName}\nGarantie ${evCharger.warranty} ans`,
        quantity: 1,
        price: computeEvChargerUnitPrice(evChargerInstallation),
      },
      smartMeter: {
        name: "Passerelle de communication",
        description: "Délestage / charge solaire",
        quantity: 1,
        price: computeEvChargerSmartMeterPrice(evChargerInstallation),
      },
      accessories: {
        name: "Accessoires",
        description: "Câble de charge T2, support mural",
        quantity: 1,
        price: computeEvChargerAccessoriesPrice(),
      },
    },
    extraWorks: computeExtraWorksQuoteDetails(evChargerInstallation.extraWorks),
    workforce: {
      installation: {
        name: "Installation",
        description: "Forfait pose en intérieur et raccordement de la borne",
        quantity: 1,
        price: computeEvChargerWorkforceCost(),
      },
      additionalCost: {
        name: "Supplément",
        description: `Pose externe, tranchée, câblage ${evChargerInstallation.additionalCost === 0 ? "(supplément déterminé après la visite technique)" : ""}`,
        quantity: 1,
        price: evChargerInstallation.additionalCost,
      },
    },
    subsidy: subsidy
      ? {
          amount: subsidy.subsidyAmount,
          type: subsidy.subsidyType,
        }
      : null,
    vatRate: EV_CHARGER_VAT_RATE,
    totals: { installationCost, netInstallationCost },
  }
}
