import { Divider, Group, Stack, Text } from "@mantine/core"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { UnpackArray } from "@ensol/types/utils"

import { ProcessId } from "@ensol/shared/entities/projects/processes"

import { ProcessesMilestoneReport } from "./ProcessView/MilestoneReport"

type InfoValue =
  | { name: string; value: JSX.Element; dataType: "component" }
  | {
      name: string
      value?: string | null
      dataType?: "long-text"
    }
type Props = {
  infos: (InfoValue & {
    color?: string
    isHidden?: boolean
  })[]
  milestones?: {
    processes: ProcessId[]
    project: UnpackArray<ProjectResponses.ProjectsList>
  }
}

export const ProcessInfos = ({ infos, milestones }: Props) => (
  <>
    <Stack gap="0">
      {infos.some(({ value, isHidden }) => !!value && !isHidden) && (
        <Divider mt="8" mb="8" />
      )}
      {infos.map((config) => {
        const { value, color, dataType, isHidden } = config

        if (!value || isHidden) {
          return null
        }

        if (dataType === "long-text") {
          return (
            <Text key={config.name} c={color} size="sm">
              {config.name} : {value}
            </Text>
          )
        }

        return (
          <Group key={config.name} mih="24" gap="4" justify="space-between">
            <Text size="sm" mr="8">
              {config.name}
            </Text>
            {dataType === "component" ? (
              value
            ) : (
              <Text c={color} size="sm" fw={500}>
                {value}
              </Text>
            )}
          </Group>
        )
      })}
    </Stack>
    {milestones !== undefined && milestones.processes.length > 0 && (
      <Stack gap="0">
        <Divider mt="8" mb="8" />
        <ProcessesMilestoneReport
          processes={milestones.processes}
          project={milestones.project}
          isCompactDisplay
        />
      </Stack>
    )}
  </>
)
