import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import {
  computeAllBatteryCosts,
  computeBatteryWorkforceCost,
} from "@ensol/shared/entities/installations/costs/battery"
import { computeAllEvChargerCosts } from "@ensol/shared/entities/installations/costs/evCharger"
import {
  computeAllPhotovoltaicCosts,
  computePhotovoltaicWorkforceCost,
} from "@ensol/shared/entities/installations/costs/photovoltaic"
import { Subsidy } from "@ensol/shared/entities/installations/subsidies"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

// MARGINS (%)
const ENSOL_MARGIN = 0.25 // %

export const withMargin = (value: number): number =>
  roundDecimalNumber(value * (1 + ENSOL_MARGIN))

type EffectiveMarginInput = {
  installationCost: number
  installationCostWithDiscount: number
}

export const computeEffectiveMargin = ({
  installationCost,
  installationCostWithDiscount,
}: EffectiveMarginInput): number => {
  const installationCostWithoutMargin = installationCost / (1 + ENSOL_MARGIN)
  return installationCostWithDiscount / installationCostWithoutMargin - 1
}

type WorkforceCostInput = {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation | null
  batteryInstallation: InstallationsResponses.BatteryInstallation | null
  house: Pick<HousesResponses.House, "roofType" | "currentType">
}

export const computeWorkforceCost = ({
  photovoltaicInstallation,
  batteryInstallation,
  house,
}: WorkforceCostInput): number => {
  const photovoltaicWorkforceCost = photovoltaicInstallation
    ? computePhotovoltaicWorkforceCost({ photovoltaicInstallation, house })
    : 0
  const batteryWorkforceCost = batteryInstallation
    ? computeBatteryWorkforceCost(batteryInstallation)
    : 0

  return photovoltaicWorkforceCost + batteryWorkforceCost
}

type AllCostsInput = {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation | null
  batteryInstallation: InstallationsResponses.BatteryInstallation | null
  evChargerInstallation: InstallationsResponses.EvChargerInstallation | null
  house: Pick<
    HousesResponses.House,
    "constructionYear" | "currentType" | "roofType" | "hasFlatRoof"
  >
  totalDiscount: number
}

export type AllCosts = {
  installationCost: number
  grossInstallationCost: number
  netInstallationCost: number
}

export const computeAllCosts = ({
  photovoltaicInstallation,
  batteryInstallation,
  evChargerInstallation,
  house,
  totalDiscount,
}: AllCostsInput): AllCosts & {
  installationCostWithDiscount: number
  subsidies: Subsidy[]
} => {
  const subsidies = new Array<Subsidy>()
  let installationCost = 0
  let grossInstallationCost = 0
  let netInstallationCost = 0

  if (photovoltaicInstallation !== null) {
    const photovoltaicCosts = computeAllPhotovoltaicCosts({
      photovoltaicInstallation,
      house,
      totalDiscount,
    })

    installationCost += photovoltaicCosts.installationCost
    grossInstallationCost += photovoltaicCosts.grossInstallationCost
    netInstallationCost += photovoltaicCosts.netInstallationCost
    if (photovoltaicCosts.subsidy) {
      subsidies.push(photovoltaicCosts.subsidy)
    }
  }

  if (batteryInstallation !== null) {
    const batteryCosts = computeAllBatteryCosts({
      batteryInstallation,
    })

    installationCost += batteryCosts.installationCost
    grossInstallationCost += batteryCosts.grossInstallationCost
    netInstallationCost += batteryCosts.netInstallationCost
  }

  if (evChargerInstallation !== null) {
    const evChargerCosts = computeAllEvChargerCosts({
      evChargerInstallation,
    })

    installationCost += evChargerCosts.installationCost
    grossInstallationCost += evChargerCosts.grossInstallationCost
    netInstallationCost += evChargerCosts.netInstallationCost
    if (evChargerCosts.subsidy) {
      subsidies.push(evChargerCosts.subsidy)
    }
  }

  return {
    installationCost,
    grossInstallationCost,
    installationCostWithDiscount: installationCost - totalDiscount,
    netInstallationCost: netInstallationCost - totalDiscount,
    subsidies,
  }
}
