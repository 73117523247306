import {
  Alert,
  Badge,
  Button,
  Group,
  MantineStyleProps,
  Modal,
  Stack,
  Text,
} from "@mantine/core"
import { IconExternalLink, IconInfoCircle } from "@tabler/icons-react"
import { Link } from "react-router-dom"

import { ProjectEventType } from "@ensol/shared/entities/projects/events"

import { MapLink } from "@ensol/entool/components/MapLink"
import { TextInfo } from "@ensol/entool/components/entities/Project/TextInfo"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { PROJECT_EVENT_TYPES_OPTIONS } from "@ensol/entool/utils/projects/options"

type Props = {
  event?: {
    extendedProps: {
      projectId: string
      type: ProjectEventType
      clientName: string
      operationsOwnerName: string | null
      installerName: string
      address: string
      postcode: string
      installedPowerCapacity: number | null
      expectedStartDateSource?: string
    }
  }
  onClose: () => void
}

export const EventModal = ({ event, onClose }: Props) => {
  if (!event) {
    return null
  }

  const {
    extendedProps: {
      type,
      projectId,
      clientName,
      operationsOwnerName,
      installerName,
      address,
      postcode,
      installedPowerCapacity,
      expectedStartDateSource,
    },
  } = event

  return (
    <Modal
      opened={projectId !== null}
      onClose={onClose}
      centered
      size="500"
      title={
        <Text fw="bold" fz={18}>
          {clientName}
        </Text>
      }
    >
      <Stack gap="8" align="flex-start">
        <Group gap="8" justify="space-between" w="100%">
          <Badge>
            {findSelectedOption(type, PROJECT_EVENT_TYPES_OPTIONS)!.label}
          </Badge>
          <Button
            component={Link}
            target="_blank"
            variant="transparent"
            to={`/projects/${projectId}?tab=${type === ProjectEventType.INSTALLATION ? "installationStep" : "technicalVisitStep"}`}
            p="0"
            leftSection={<IconExternalLink size={14} />}
          >
            Ouvrir le projet
          </Button>
        </Group>
        {expectedStartDateSource && (
          <Alert
            w="100%"
            p="8"
            variant="outline"
            title="Source utilisée pour calculer la date proposée"
            icon={<IconInfoCircle />}
          >
            {expectedStartDateSource}
          </Alert>
        )}
        <EventInfo name="Installateur" value={installerName} mt="16" />
        <EventInfo name="Responsable Ops" value={operationsOwnerName} />
        <EventInfo name="Adresse" value={<MapLink address={address} />} />
        <EventInfo name="Code postal" value={postcode} />
        {installedPowerCapacity && (
          <EventInfo
            name="Capacité de l'installation"
            value={`${installedPowerCapacity}kWc`}
          />
        )}
      </Stack>
    </Modal>
  )
}

type EventInfoProps = {
  name: string
  value: string | React.ReactNode | null
} & MantineStyleProps

const EventInfo = ({ name, value, ...props }: EventInfoProps) => (
  <Group justify="space-between" w="100%" {...props}>
    <Text fw={500} c="gray.7">
      {name}
    </Text>
    {typeof value === "string" ? <TextInfo value={value} /> : value ?? "-"}
  </Group>
)
