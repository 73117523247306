import { Gender } from "@ensol/shared/entities/prospects"
import { phoneSchema } from "@ensol/types/forms/prospects"
import { ContractHolderType } from "@ensol/shared/entities/houses/switchgrid"
import { locationSchema, pdlSchema } from "@ensol/types/forms/houses"
import * as z from "zod"

export const dataConsentSchema = z.object({
  pdl: pdlSchema,
  location: locationSchema,
  heldBy: z.discriminatedUnion("type", [
    z.object({
      type: z.literal(ContractHolderType.INDIVIDUAL),
      gender: z.nativeEnum(Gender, {
        invalid_type_error: "Champ obligatoire",
      }),
      firstName: z.string().min(1, "Champ obligatoire"),
      lastName: z.string().min(1, "Champ obligatoire"),
    }),
    z.object({
      type: z.literal(ContractHolderType.COMPANY),
      name: z.string().min(1, "Champ obligatoire"),
      siret: z.string().min(1, "Champ obligatoire"),
    }),
  ]),
  signer: z.object({
    gender: z.nativeEnum(Gender, {
      invalid_type_error: "Champ obligatoire",
    }),
    firstName: z.string().min(1, "Champ obligatoire"),
    lastName: z.string().min(1, "Champ obligatoire"),
    email: z.string().email("Email invalide"),
    phone: phoneSchema,
  }),
})

export type DataConsentInput = z.infer<typeof dataConsentSchema>
