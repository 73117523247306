import _ from "lodash"

import { HardwareDeliveryMode } from "@ensol/shared/entities/projects/delivery"
import { ProjectEventType } from "@ensol/shared/entities/projects/events"
import {
  INTEGRATION_KITS,
  IntegrationKitType,
} from "@ensol/shared/entities/projects/integrationKits"

import { getOptionsFromList } from "@ensol/entool/utils/form/options"
import { Option } from "@ensol/entool/utils/form/radio"

export const INTEGRATION_KITS_OPTIONS: Option<IntegrationKitType>[] = _.map(
  INTEGRATION_KITS,
  (integrationKit, integrationKitType) => ({
    label: integrationKit.name,
    value: integrationKitType as IntegrationKitType,
  }),
)

export const TECHNICAL_VISIT_DIFFICULTY_OPTIONS = [
  { label: "Facile", value: "easy" },
  { label: "Moyen", value: "medium" },
  { label: "Difficile", value: "hard" },
]

export const HARDWARE_DELIVERY_MODE_OPTIONS = [
  { label: "Livraison", value: HardwareDeliveryMode.ADDRESS },
  { label: "Retrait", value: HardwareDeliveryMode.PICKUP },
]

export const HARDWARE_DELIVERY_ADDRESS_OPTIONS = [
  "Ensol - 490 Avenue des templiers 13400 Aubagne",
  "Solutions30 - 571 Avenue Pierre Mendès 83340 Le Luc",
  "Domopower - 373 chemin des Gypières, Park Valrose - 13390 Auriol",
  "Sun Watt - 44 Allée du Sault - 06810 Auribeau-sur-Siagne",
  "EnerJCiel - 60 Avenue Émile Cotte 13220 Châteauneuf-les-Martigues",
]

export const HARDWARE_DELIVERY_PICKUP_OPTIONS = [
  "NED - 244 Rue de la Manade 34160 Castries",
  "Alliantz Montpellier - 148 Rue Mehdi Ben Barka 34070 Montpellier",
  "Alliantz La Garde - 1523 Avenue de Draguignan 83130 La Garde",
  "Alliantz Aix - 5 Chemin de Barlatier Le Canet 13590 Meyreuil",
]

export const TECHNICAL_VISIT_VALIDATION_STATUSES_OPTIONS = [
  {
    group: "VAC",
    items: [
      "VAC : Changement de calepinage / Nb de panneaux",
      "VAC : Changement de calepinage / Multi-pans",
      "VAC : Besoin d'optimiseurs / Masque solaire",
      "VAC : Changement d'onduleur",
      "VAC : Accessibilité (nacelle, échafaudage)",
      "VAC : Changement de phase",
      "VAC : Autre",
    ],
  },
  {
    group: "VAT",
    items: [
      "VAT : Refaire la mise à la terre",
      "VAT : Passage des cables",
      "VAT : Tranchée",
      "VAT : Autre",
    ],
  },
  {
    group: "NV",
    items: [
      "NV : Couverture non compatible",
      "NV : Charpente non compatible",
      "NV : Présence d'amiante",
      "NV : Électrique non compatible",
      "NV : Toiture non accessible",
      "NV : Autre",
    ],
  },
]

export const FOLLOW_UP_ISSUES_OPTIONS = [
  "Pinces",
  "Matériel",
  "Internet",
  "Autre",
]

export const ENEDIS_INCOMPLETE_FILE_OPTIONS = [
  "Autres",
  "M007",
  "PDL",
  "Facture",
  "Consuel (Greendeal)",
  "Taxe foncière",
  "Certificat d'adressage",
  "Changement de puissance",
  "Infos clients",
]

export const PROJECT_EVENT_TYPES_OPTIONS = [
  { label: "Visite technique", value: ProjectEventType.TECHNICAL_VISIT },
  { label: "Installation", value: ProjectEventType.INSTALLATION },
]

export const PRELIMINARY_REQUEST_REQUESTED_CHANGES_REASONS_OPTIONS = [
  "Régularisation PC",
  "DP5",
  "DP4",
  "Demandes de l'ABF",
  "Existence légale",
  "Photos",
  "Autres",
]

export const PRELIMINARY_REQUEST_AWAITED_STAKEHOLDER_OPTIONS =
  getOptionsFromList(["Client", "Solteo"])

export const NPS_RATING_OPTIONS = getOptionsFromList([
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
])

export const REVIEW_PLATFORM_OPTIONS = getOptionsFromList([
  "Google",
  "PagesJaunes",
])

export const SCHEDULING_ISSUES_OPTIONS = [
  "Demande préalable",
  "Financement",
  "Travaux",
  "Calendrier",
  "Autre",
]

export const CONSUEL_WORKS_LOCATION_OPTIONS = [
  "AGCP",
  "TGBT",
  "Étiquetage",
  "Câblage",
]

export const CONSUEL_WORKS_REASON_OPTIONS = [
  "Protection contre les contacts directs (73)",
  "Mise en œuvre (85)",
  " Protection contre les surintensités (28)",
  "Signalisation (80)",
  "Cohérence DT (49)",
  "Protection contre les contacts indirects (17)",
]
