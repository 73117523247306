import { Group, Stack, Tabs } from "@mantine/core"
import { Helmet } from "react-helmet-async"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { getFullName } from "@ensol/shared/entities/prospects"

import { ContentContainer } from "@ensol/entool/components/layout/ContentContainer"
import {
  CONTENT_PADDING_IN_PX,
  NAVBAR_HEIGHT_IN_PX,
  OPERATION_PIPELINE_HEADER_HEIGHT_IN_PX,
} from "@ensol/entool/styles/constants"
import { useTabsNavigation } from "@ensol/entool/utils/helpers/useTabsNavigation"

import { Header } from "./Header"
import { Overview } from "./Overview"
import { PROJECT_PROCESS_DETAILS_VIEWS } from "./config"

type Props = {
  project: ProjectResponses.Project
}

export const Project = ({ project }: Props) => {
  const { currentTab, handleTabChange } = useTabsNavigation([
    { id: "overview" },
    ...PROJECT_PROCESS_DETAILS_VIEWS,
  ])

  const { house } = project.installation
  const { prospect } = house

  return (
    <Stack flex="1" gap="0" h="100%">
      <Helmet>
        <title>{getFullName(prospect)} | Entool</title>
      </Helmet>
      <Header project={project} currentTab={currentTab} />
      <ContentContainer flex="1" px={CONTENT_PADDING_IN_PX} mt="0">
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          style={{ display: "flex", flexDirection: "column", flex: 1 }}
          h="100%"
          orientation="vertical"
          variant="outline"
          keepMounted={false}
        >
          <Group flex="0" wrap="nowrap" align="flex-start" gap="40">
            <Tabs.List
              pt="40"
              h={`calc(100dvh - ${NAVBAR_HEIGHT_IN_PX + OPERATION_PIPELINE_HEADER_HEIGHT_IN_PX}px)`}
              pos="sticky"
              top={NAVBAR_HEIGHT_IN_PX + OPERATION_PIPELINE_HEADER_HEIGHT_IN_PX}
              flex="0"
            >
              <Tabs.Tab key="overview" value="overview">
                Résumé
              </Tabs.Tab>
              {PROJECT_PROCESS_DETAILS_VIEWS.map(({ id, name }) => (
                <Tabs.Tab
                  key={id}
                  value={id}
                  style={{ color: project[id] === null ? "grey" : "black" }}
                >
                  {name}
                </Tabs.Tab>
              ))}
            </Tabs.List>
            <Stack gap="0" pt="40" flex="1">
              <Tabs.Panel flex="1" mih="0" value="overview">
                <Overview project={project} />
              </Tabs.Panel>
              {PROJECT_PROCESS_DETAILS_VIEWS.map(
                ({ id, milestones, abortedMilestones, Component }) => (
                  <Tabs.Panel key={id} flex="1" mih="0" value={id}>
                    <Component
                      processId={id}
                      project={project}
                      milestonesConfig={milestones}
                      abortedMilestones={abortedMilestones}
                    />
                  </Tabs.Panel>
                ),
              )}
            </Stack>
          </Group>
        </Tabs>
      </ContentContainer>
    </Stack>
  )
}
