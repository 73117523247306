import { useParams } from "react-router-dom"

import { CreateInstallationInput } from "@ensol/types/forms/installations"
import {
  ProductionDataSource,
  ConsumptionDataSource,
} from "@ensol/types/simulation"

import { DEFAULT_PHOTOVOLTAIC_INSTALLATION } from "@ensol/shared/entities/installations/defaults"
import { computePhotovoltaicSubsidyType } from "@ensol/shared/entities/installations/subsidies/photovoltaic"

import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useGetHouseQuery } from "@ensol/entool/queries/houses"
import {
  useCreateInstallationMutation,
  useGetInstallationQuery,
  useUpdateInstallationMutation,
} from "@ensol/entool/queries/installations"

import { Installation } from "./Installation"

export const InstallationPage = () => {
  const { installationId } = useParams()
  const installationQuery = useGetInstallationQuery(installationId as string)

  const { mutateAsync: updateInstallation, isPending } =
    useUpdateInstallationMutation<CreateInstallationInput>(
      installationId as string,
    )

  return (
    <QueryWrapper query={installationQuery}>
      {({ data }) => (
        <Installation
          installationId={data.id}
          installationDate={data.updatedAt}
          name={data.name}
          slug={data.slug}
          productionDataSource={data.productionDataSource}
          consumptionDataSource={data.consumptionDataSource}
          monthlyBill={data.monthlyBill}
          yearlyConsumption={data.yearlyConsumption}
          autoConsumptionPercent={data.autoConsumptionPercent}
          horizonProfileFilePath={data.horizonProfileFilePath}
          photovoltaicInstallation={data.photovoltaicInstallation}
          batteryInstallation={data.batteryInstallation}
          evChargerInstallation={data.evChargerInstallation}
          discount={data.discount}
          referrerId={data.referrerId}
          panelsLayout={data.panelsLayout}
          prospect={data.prospect}
          house={data.house}
          project={data.project}
          onSave={updateInstallation}
          isSaving={isPending}
        />
      )}
    </QueryWrapper>
  )
}

export const NewInstallationPage = () => {
  const { houseId } = useParams()
  const houseQuery = useGetHouseQuery(houseId as string)

  const { mutateAsync: createInstallation, isPending } =
    useCreateInstallationMutation()

  return (
    <QueryWrapper query={houseQuery}>
      {({ data: house }) => {
        return (
          <Installation
            installationDate={new Date()}
            name={`Offre ${house.installations?.length + 1}`}
            productionDataSource={ProductionDataSource.PVGIS}
            consumptionDataSource={ConsumptionDataSource.BILL}
            photovoltaicInstallation={{
              ...DEFAULT_PHOTOVOLTAIC_INSTALLATION,
              subsidyType: computePhotovoltaicSubsidyType(
                house.hasGroundInstallation,
              ),
              panelsCount: 0,
              roofSectionsWithPanels: house.roofSections.map((section) => ({
                roofSection: section,
                panelsCount: 0,
              })),
            }}
            batteryInstallation={null}
            evChargerInstallation={null}
            horizonProfileFilePath={null}
            prospect={house.prospect}
            house={house}
            onSave={createInstallation}
            isSaving={isPending}
          />
        )
      }}
    </QueryWrapper>
  )
}
