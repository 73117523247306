import * as Sentry from "@sentry/react"

import { CurrentType } from "@ensol/shared/material/currentType"
import {
  getInverter,
  InverterType,
} from "@ensol/shared/material/photovoltaic/inverters"
import { getPanel, PanelType } from "@ensol/shared/material/photovoltaic/panels"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

import {
  MAX_PANELS_COUNT_FOR_PRE_INSTALLATION,
  RECOMMENDED_CONSUMED_ENERGY_MATCH_PERCENT,
} from "./panels"

const ELECTRICITY_PRICE = 0.25 // €/kWh
export const ELECTRICITY_PRICE_ANNUAL_RAISE = 1.05 // %

export const YEARLY_MATERIAL_EFFICIENCY_LOSS = 0.995 // %

const AVERAGE_KWH_PRODUCED_PER_KWC_INSTALLED = 1450 // kWh/KWc

type EnergyPriceInput = {
  year: number
  baseYear: number
  baseEnergyPrice: number
}

const computeYearEnergyPrice = ({
  baseEnergyPrice,
  baseYear,
  year,
}: EnergyPriceInput) => {
  if (year < baseYear) {
    Sentry.captureMessage("Year is lower than base year", {
      extra: {
        baseEnergyPrice,
        baseYear,
        year,
      },
    })
  }
  const yearOffset = year - baseYear
  return baseEnergyPrice * ELECTRICITY_PRICE_ANNUAL_RAISE ** yearOffset
}

export const estimateDefaultEnergyPrice = (year: number): number => {
  return computeYearEnergyPrice({
    year,
    baseYear: 2024,
    baseEnergyPrice: ELECTRICITY_PRICE,
  })
}

type EstimateEnergyPriceInput = {
  year: number
  monthlyBill: number
  yearlyConsumption: number
  installationDate: Date
}

export const estimateEnergyPrice = ({
  year,
  yearlyConsumption,
  monthlyBill,
  installationDate,
}: EstimateEnergyPriceInput) => {
  const baseEnergyPrice = (12 * monthlyBill) / yearlyConsumption
  return computeYearEnergyPrice({
    year,
    baseYear: installationDate.getFullYear(),
    baseEnergyPrice,
  })
}

type MonthlyEnergyUsageInput = {
  year: number
  installationDate: Date
  autoConsumptionPercent: number
  estimatedYearlyProduction: number
}

type MonthlyEnergyUsage = {
  autoConsumedEnergy: number
  resellableEnergy: number
}

export const computeMonthlyEnergyUsage = ({
  year,
  installationDate,
  estimatedYearlyProduction,
  autoConsumptionPercent,
}: MonthlyEnergyUsageInput): MonthlyEnergyUsage => {
  const currentYear = installationDate.getFullYear()
  const yearOffset = year - currentYear

  const currentMonthlyEstimatedEnergyProduction =
    (estimatedYearlyProduction *
      YEARLY_MATERIAL_EFFICIENCY_LOSS ** yearOffset) /
    12

  return {
    autoConsumedEnergy:
      currentMonthlyEstimatedEnergyProduction * (autoConsumptionPercent / 100),
    resellableEnergy:
      currentMonthlyEstimatedEnergyProduction *
      (1 - autoConsumptionPercent / 100),
  }
}

export const computeYearlyConsumption = (monthlyBill: number): number => {
  const electricityPrice = estimateDefaultEnergyPrice(new Date().getFullYear())
  return Math.round((monthlyBill * 12) / electricityPrice)
}

export const computeMonthlyBill = (yearlyConsumption: number): number => {
  const electricityPrice = estimateDefaultEnergyPrice(new Date().getFullYear())
  return Math.round((yearlyConsumption / 12) * electricityPrice)
}

type RecommendedPanelsCountInput = {
  panelType: PanelType
  yearlyConsumption: number
  shouldBeCapped: boolean
}

export const getRecommendedPanelsCount = ({
  panelType,
  yearlyConsumption,
  shouldBeCapped,
}: RecommendedPanelsCountInput) => {
  const panel = getPanel(panelType)
  const recommendedPanelsCount = Math.ceil(
    (yearlyConsumption * RECOMMENDED_CONSUMED_ENERGY_MATCH_PERCENT) /
      AVERAGE_KWH_PRODUCED_PER_KWC_INSTALLED /
      panel.power,
  )

  return Math.min(
    recommendedPanelsCount,
    shouldBeCapped ? MAX_PANELS_COUNT_FOR_PRE_INSTALLATION : Infinity,
  )
}

export const computePanelsCount = <
  T extends {
    panelsCount: number
  },
>(
  roofSectionsWithPanels: T[],
) =>
  roofSectionsWithPanels.reduce((acc, section) => acc + section.panelsCount, 0)

export const computePanelsCountForCapacity = ({
  installationCapacityInKw,
  panelType,
}: {
  installationCapacityInKw: number
  panelType: PanelType
}) => {
  const panelsCount = installationCapacityInKw / getPanel(panelType).power
  if (panelsCount === 0) return panelsCount
  return panelsCount < 1 ? 1 : Math.floor(panelsCount)
}

type PhotovoltaicInstallationCapacityInput = {
  panelType: PanelType
  panelsCount: number
}

export const computePhotovoltaicInstallationCapacity = ({
  panelType,
  panelsCount,
}: PhotovoltaicInstallationCapacityInput): number => {
  const panel = getPanel(panelType)
  return roundDecimalNumber(panel.power * panelsCount, 1)
}

// A more complex calculation can be done later, see commit b8d0a97d50205c8c08e3c9cefa03cfc3db8b61bd
export const computeInvertersCount = (
  inverterType: InverterType,
  panelsCount: number,
): number => {
  const inverter = getInverter(inverterType)
  return inverter.isCentralInverter ? 1 : panelsCount
}

type InverterCompatibility = {
  isCompatible: boolean
  DCACPercent: number
}

export const checkInverterCompatibility = (
  inverterType: InverterType,
  panelType: PanelType,
  panelsCount: number,
  currentType: CurrentType,
): InverterCompatibility => {
  const installedCapacity = computePhotovoltaicInstallationCapacity({
    panelType,
    panelsCount,
  })
  const inverter = getInverter(inverterType)
  const invertersCount = computeInvertersCount(inverterType, panelsCount)
  const inverterCapacity = invertersCount * inverter.power

  const DCACRatio = installedCapacity / inverterCapacity

  const isCompatibleWithCurrentType =
    inverter.allowedCurrentTypes.includes(currentType)

  // With a 6KTL inverter, you can actually accept a bigger DC/AC ratio for single phase installation, up to 8.5kWc
  // Explanation: https://ensol-group.slack.com/archives/C05RD1NE5D1/p1709225115401209
  const edgeCaseCompatibility =
    inverterType === InverterType.HUAWEI_SUN_2000_6_KTL_L_1 &&
    currentType === CurrentType.SINGLE_PHASE &&
    installedCapacity >= 6 &&
    installedCapacity <= 8.5

  return {
    DCACPercent: Math.round(DCACRatio * 100),
    isCompatible:
      // cf: https://ensol-group.slack.com/archives/C04PDPE1RC1/p1700739722672509
      edgeCaseCompatibility ||
      (isCompatibleWithCurrentType && DCACRatio >= 0.99 && DCACRatio <= 1.25),
  }
}
