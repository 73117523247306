import * as z from "zod"

import { EvChargerType } from "@ensol/shared/material/evCharger"
import { EvChargerSubsidyType } from "@ensol/shared/entities/installations/subsidies/evCharger"

import { extraWorksSchema } from "./extraWorks"

export const evChargerInstallationSchema = z
  .object({
    type: z.nativeEnum(EvChargerType),
    additionalCost: z.number().nonnegative(),
    subsidyType: z.nativeEnum(EvChargerSubsidyType).nullable(),
  })
  .merge(extraWorksSchema)
