import { Stack, MultiSelect, Textarea, SimpleGrid } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { FollowUpStepInput } from "@ensol/types/forms/projects/followUp"
import { Nullable } from "@ensol/types/utils"

import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"
import { DISPLAY_DATE_FORMAT } from "@ensol/shared/utils/format"

import { FormField } from "@ensol/entool/components/entities/Project/FormField"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { ExternalPlantSelect } from "@ensol/entool/components/form/Installation/ExternalPlantSelect"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { FOLLOW_UP_ISSUES_OPTIONS } from "@ensol/entool/utils/projects/options"

type Props<Values> = {
  project: ProjectResponses.Project
  form: UseFormReturnType<Values>
  initialValues: Values
  children?: React.ReactNode
}

export const FollowUpForm = <Values extends Nullable<FollowUpStepInput>>({
  project,
  form,
  initialValues,
  children,
}: Props<Values>) => {
  const { photovoltaicInstallation } = project.installation
  const inverter =
    photovoltaicInstallation !== null
      ? getInverter(photovoltaicInstallation.inverterType)
      : null

  return (
    <SimpleGrid cols={2} spacing="32" mt="16">
      <Stack gap="24">
        <FormField initialValues={initialValues} propertyName="followUpIssues">
          <MultiSelect
            w={FIELD_WIDTH}
            searchable
            hidePickedOptions
            data={FOLLOW_UP_ISSUES_OPTIONS}
            {...form.getInputProps("followUpIssues")}
          />
        </FormField>
        {inverter && (
          <FormField
            initialValues={initialValues}
            propertyName="followUpExternalPlantIdentifier"
          >
            <ExternalPlantSelect
              w="400"
              installationId={project.installationId}
              externalProviderName={inverter.brand}
              {...form.getInputProps("followUpExternalPlantIdentifier")}
            />
          </FormField>
        )}
        <FormField initialValues={initialValues} propertyName="followUpNotes">
          <Textarea
            autosize
            w={FIELD_WIDTH}
            minRows={3}
            {...form.getInputProps("followUpNotes")}
          />
        </FormField>
      </Stack>
      <Stack gap="24">
        <FormField
          initialValues={initialValues}
          propertyName="followUpProductionStartDate"
          isRequired
        >
          <DateInput
            w={FIELD_WIDTH}
            valueFormat={DISPLAY_DATE_FORMAT}
            clearable
            minDate={project.installationEndDate ?? undefined}
            {...form.getInputProps("followUpProductionStartDate")}
          />
        </FormField>
        {children}
      </Stack>
    </SimpleGrid>
  )
}
