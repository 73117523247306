import { Alert, Box } from "@mantine/core"
import { IconCircleX } from "@tabler/icons-react"
import { useRef } from "react"
import { ReactFlow } from "reactflow"

import {
  ProjectMilestone,
  ProjectMilestonesConfig,
} from "@ensol/entool/utils/projects/types"

import { Milestone } from "./Milestone"
import { useMilestonesStepper } from "./useMilestonesStepper"

type Props<MilestoneType> = {
  currentMilestone: MilestoneType | null
  milestonesConfig: ProjectMilestonesConfig<MilestoneType>
  abortedMilestones?: MilestoneType[]
  height: number
}

export type MilestoneNode<MilestoneType> = {
  id: MilestoneType
  data: { id: MilestoneType; label: string; color: string }
}

export const MilestonesStepper = <MilestoneType extends string>({
  currentMilestone,
  milestonesConfig,
  abortedMilestones,
  height,
}: Props<MilestoneType>) => {
  const stepperRef = useRef<HTMLDivElement>(null)
  const { nodes, edges, onInit, isAborted } =
    useMilestonesStepper<MilestoneType>({
      currentMilestone,
      milestonesConfig,
      abortedMilestones,
      stepperRef,
    })

  if (isAborted) {
    return (
      <Alert
        variant="light"
        color="red"
        title="Ce projet a été annulé"
        icon={<IconCircleX size={30} />}
      />
    )
  }

  return (
    <Box style={{ width: "100%", height }} ref={stepperRef}>
      <ReactFlow
        onInit={onInit}
        nodes={nodes}
        edges={edges}
        nodeTypes={{
          milestone: ({ data }: { data: ProjectMilestone<MilestoneType> }) => (
            <Milestone data={data} currentMilestone={currentMilestone} />
          ),
        }}
        panOnDrag={true}
        zoomOnScroll={false}
        zoomOnPinch={false}
        zoomOnDoubleClick={false}
        nodesDraggable={false}
        nodesFocusable={false}
        nodesConnectable={false}
        style={{ pointerEvents: "none" }}
      />
    </Box>
  )
}
