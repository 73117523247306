import { IconGhost2, IconTool } from "@tabler/icons-react"

import { M1ToSendFormSchema } from "@ensol/types/forms/projects/followUp"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { AfterConsentForm } from "@ensol/entool/pages/FollowUpProject/steps/components/AfterConsentForm"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

export const M1Sent = ({ project, ...props }: StepComponentProps) => (
  <AfterConsentForm
    schema={M1ToSendFormSchema}
    initialValues={getInitialValues(M1ToSendFormSchema, project)}
    project={project}
    actions={[
      {
        action: "ignored",
        color: "red",
        label: "M+1 ignoré",
        Icon: IconGhost2,
      },
      {
        action: "actionsRequired",
        color: "orange",
        label: "Actions requises",
        Icon: IconTool,
      },
      { action: "validate", label: "Suivi terminé" },
    ]}
    {...props}
  />
)
