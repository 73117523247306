import { DatesSetArg } from "@fullcalendar/core"
import { useLocalStorage } from "@mantine/hooks"
import dayjs, { extend } from "dayjs"
import objectSupport from "dayjs/plugin/objectSupport"
import { useCallback, useState } from "react"

extend(objectSupport)

type CalendarView = "dayGridMonth" | "timeGridWeek"

export const useCalendar = () => {
  const [openedEventId, setOpenedEventId] = useState<string | null>(null)
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month())

  const [currentCalendarView, setCurrentCalendarView] =
    useLocalStorage<CalendarView>({
      key: "eventsCalendarView",
      defaultValue: "dayGridMonth",
    })

  const changeCalendarView = useCallback(
    (view: DatesSetArg["view"]) => {
      // If tab is inactive we don't want to change the view as it will
      // create ping-pong loop of view change between active and inactive tabs
      if (!document.hidden) {
        setCurrentCalendarView(view.type as CalendarView)
      }
    },
    [setCurrentCalendarView],
  )

  return {
    currentCalendarView,
    onDatesSet: ({ start, view }: DatesSetArg) => {
      setSelectedMonth(dayjs(start).month())
      changeCalendarView(view)
    },
    openedEventId,
    openEvent: ({ event }: { event: { id: string } }) =>
      setOpenedEventId(event.id),
    closeEvent: () => setOpenedEventId(null),
    period: {
      from: dayjs({ month: selectedMonth }).subtract(2, "month").toDate(),
      to: dayjs({ month: selectedMonth }).add(3, "month").toDate(),
    },
  }
}
