import {
  ActionIcon,
  Card,
  Group,
  Text,
  UnstyledButton,
  Stack,
  Image,
} from "@mantine/core"
import { modals } from "@mantine/modals"
import {
  IconDownload,
  IconFileTypeCsv,
  IconPaperclip,
  IconTrash,
} from "@tabler/icons-react"
import { Document, Thumbnail } from "react-pdf"

import { getExtension, getFileUrl } from "@ensol/shared/utils/files"

import { LinkLoader } from "@ensol/entool/components/LinkLoader"
import { useDownloadFileMutation } from "@ensol/entool/queries/files"
import { isImage } from "@ensol/entool/utils/files/images"

import { FILE_THUMBNAIL_HEIGHT_IN_PX, PREVIEW_WIDTH } from "./constants"

type Props = {
  filePath: string
  fileName: string
  width?: string
  onClick?: () => void
  onClear?: () => void
}

export const FileThumbnail = ({
  filePath,
  fileName,
  width,
  onClick,
  onClear,
}: Props) => {
  const type = getExtension(filePath)
  const { mutateAsync: downloadFile, isPending: isDownloading } =
    useDownloadFileMutation(filePath, `${fileName}.${type}`)

  if (type === undefined) {
    return null
  }

  return (
    <Stack h={FILE_THUMBNAIL_HEIGHT_IN_PX} w={width ?? PREVIEW_WIDTH}>
      <Card w="100%" h="100%" padding="lg" radius="md" p="0" withBorder>
        <Stack h="100%" gap="0">
          <Stack
            component={UnstyledButton}
            align="center"
            justify="center"
            flex="1"
            bg="gray.0"
            onClick={onClick}
            style={{
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            {getThumbnail(
              type,
              getFileUrl(import.meta.env.VITE_API_URL, filePath),
            )}
          </Stack>
          {onClear !== undefined && (
            <ActionIcon
              aria-label="Supprimer"
              size="md"
              variant="white"
              pos="absolute"
              p="4"
              onClick={() =>
                modals.openConfirmModal({
                  title: "Supprimer le fichier",
                  children: (
                    <Text size="sm">
                      Êtes-vous sûr(e) de vouloir supprimer ce fichier ? Cette
                      action est irréversible.
                    </Text>
                  ),
                  confirmProps: { color: "red" },
                  centered: true,
                  labels: {
                    confirm: "Supprimer le fichier",
                    cancel: "Annuler",
                  },
                  onConfirm: onClear,
                })
              }
            >
              <IconTrash color="red" />
            </ActionIcon>
          )}
          <Group p="8" justify="space-between">
            <Text size="xs" truncate="end" maw="80%">
              {fileName}.{type}
            </Text>
            <ActionIcon
              variant="transparent"
              aria-label="Télécharger"
              size="sm"
              loading={isDownloading}
              onClick={() => downloadFile()}
            >
              <IconDownload />
            </ActionIcon>
          </Group>
        </Stack>
      </Card>
    </Stack>
  )
}

const getThumbnail = (type: string, fileUrl: string) => {
  if (type === "pdf") {
    return (
      <Stack h="100%" justify="flex-start">
        <Document
          file={fileUrl}
          loading={() => (
            <Stack p="48" align="center" justify="center">
              <LinkLoader />
            </Stack>
          )}
        >
          <Thumbnail
            pageNumber={1}
            height={FILE_THUMBNAIL_HEIGHT_IN_PX}
            scale={2}
          />
        </Document>
      </Stack>
    )
  } else if (isImage(type)) {
    return <Image src={fileUrl} />
  } else if (type === "csv") {
    return <IconFileTypeCsv color="gray" size={40} />
  } else {
    return <IconPaperclip color="gray" size={40} />
  }
}
