import { certificateToSendStepFormSchema } from "@ensol/types/forms/projects/edfOa"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { EdfOaForm } from "@ensol/entool/pages/OpsProject/processes/EdfOa/components/EdfOaForm"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

export const CertificateSigned = ({
  project,
  ...props
}: StepComponentProps) => {
  return (
    <EdfOaForm
      schema={certificateToSendStepFormSchema}
      initialValues={getInitialValues(certificateToSendStepFormSchema, project)}
      project={project}
      actions={[]}
      {...props}
    />
  )
}
