import { ProjectStatus as PrismaProjectStatus } from "@ensol/types/prisma-client"

export enum ProjectStatus {
  CREATED = "CREATED",
  SIGNED = "SIGNED",
  REVIEWED = "REVIEWED",
  STARTED = "STARTED",
  TECHNICALLY_VALIDATED = "TECHNICALLY_VALIDATED",
  ADMINISTRATIVELY_VALIDATED = "ADMINISTRATIVELY_VALIDATED",
  VALIDATED = "VALIDATED",
  DEPOSIT_INVOICE_PAID = "DEPOSIT_INVOICE_PAID",
  INSTALLATION_DONE = "INSTALLATION_DONE",
  FINAL_INVOICE_PAID = "FINAL_INVOICE_PAID",
  DONE = "DONE",
  ABORTED = "ABORTED",
}

export const getStatusIndex = (status: PrismaProjectStatus) =>
  Object.values(ProjectStatus).findIndex(
    (projectStatus) => projectStatus === status,
  )
