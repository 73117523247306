import { beingProcessedStepFormSchema } from "@ensol/types/forms/projects/technicalVisit"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

import { TechnicalVisitForm } from "./components/TechnicalVisitForm"

export const WaitingForSalesChanges = ({
  project,
  ...props
}: StepComponentProps) => (
  <TechnicalVisitForm
    schema={beingProcessedStepFormSchema}
    initialValues={getInitialValues(beingProcessedStepFormSchema, project)}
    project={project}
    actions={[{ action: "validate", label: "En attente du client" }]}
    {...props}
  />
)
