import { IconRotate360 } from "@tabler/icons-react"

import { M1ToSendFormSchema } from "@ensol/types/forms/projects/followUp"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { AfterConsentForm } from "@ensol/entool/pages/FollowUpProject/steps/components/AfterConsentForm"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

export const M1Ignored = ({ project, ...props }: StepComponentProps) => (
  <AfterConsentForm
    schema={M1ToSendFormSchema}
    initialValues={getInitialValues(M1ToSendFormSchema, project)}
    project={project}
    actions={[
      {
        action: "restore",
        color: "blue",
        label: "Repasser en M+1 envoyé",
        Icon: IconRotate360,
      },
    ]}
    {...props}
  />
)
