export enum ExtraWorkType {
  PANELS_REMOVAL = "PANELS_REMOVAL",
  PANELS_RECYCLING = "PANELS_RECYCLING",
  HOT_WATER_TANK_REMOVAL = "HOT_WATER_TANK_REMOVAL",
  HOT_WATER_TANK_RECYCLING = "HOT_WATER_TANK_RECYCLING",
  SIMPLE_GROUNDING = "SIMPLE_GROUNDING",
  COMPLEX_GROUNDING = "COMPLEX_GROUNDING",
  EARTHEN_TRENCH_LESS_15M = "EARTHEN_TRENCH_LESS_15M",
  EARTHEN_TRENCH_MORE_15M = "EARTHEN_TRENCH_MORE_15M",
  CONCRETE_TRENCH_LESS_15M = "CONCRETE_TRENCH_LESS_15M",
  CONCRETE_TRENCH_MORE_15M = "CONCRETE_TRENCH_MORE_15M",
  MODULAR_CLOCK_INSTALLATION = "MODULAR_CLOCK_INSTALLATION",
}

type ExtraWorkInfo = {
  category: string
  name: string
  description: string
  price: number
}

export const EXTRA_WORKS: Record<ExtraWorkType, ExtraWorkInfo> = {
  PANELS_REMOVAL: {
    category: "Panneaux thermiques",
    name: "Retrait de panneaux thermiques",
    description: "Démontage et vidange des panneaux",
    price: 350,
  },
  PANELS_RECYCLING: {
    category: "Panneaux thermiques",
    name: "Recyclage de panneaux thermiques",
    description: "Recyclage des panneaux thermiques",
    price: 150,
  },
  HOT_WATER_TANK_REMOVAL: {
    category: "Ballon d'eau chaude",
    name: "Retrait d'un ballon d'eau chaude",
    description: "Démontage, vidange d'un ballon d'eau chaude",
    price: 200,
  },
  HOT_WATER_TANK_RECYCLING: {
    category: "Ballon d'eau chaude",
    name: "Recyclage d'un ballon d'eau chaude",
    description: "Recyclage d'un ballon d'eau chaude",
    price: 100,
  },
  SIMPLE_GROUNDING: {
    category: "Mise à la terre",
    name: "Mise à la terre simple",
    description: "Remise aux normes de la mise à la terre < 100 ohm",
    price: 150,
  },
  COMPLEX_GROUNDING: {
    category: "Mise à la terre",
    name: "Mise à la terre complexe",
    description: "Remise aux normes de la mise à la terre < 100 ohm",
    price: 300,
  },
  EARTHEN_TRENCH_LESS_15M: {
    category: "Tranchée",
    name: "Tranchée dans de la terre < 15m",
    description: "Réalisation d'une tranchée < 15m",
    price: 250,
  },
  EARTHEN_TRENCH_MORE_15M: {
    category: "Tranchée",
    name: "Tranchée dans de la terre 15-30m",
    description: "Réalisation d'une tranchée 15-30m",
    price: 500,
  },
  CONCRETE_TRENCH_LESS_15M: {
    category: "Tranchée",
    name: "Tranchée dans du béton < 15m",
    description: "Réalisation d'une tranchée sur sol béton < 15m",
    price: 500,
  },
  CONCRETE_TRENCH_MORE_15M: {
    category: "Tranchée",
    name: "Tranchée dans du béton 15-30m",
    description: "Réalisation d'une tranchée sur sol béton 15-30m ",
    price: 650,
  },
  MODULAR_CLOCK_INSTALLATION: {
    category: "Horloge modulaire",
    name: "Pose d'une horloge modulaire",
    description:
      "Installation d'une horloge modulaire - régulation d'un ballon d'eau chaude",
    price: 200,
  },
}

export const getExtraWork = (type: ExtraWorkType): ExtraWorkInfo =>
  EXTRA_WORKS[type]
