import { EmailEvent } from "@ensol/shared/entities/projects/emails"

import {
  StepActions,
  StepComponentProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { PostDepositPaymentForm } from "@ensol/entool/pages/OpsProject/processes/Payment/components/PostDepositPaymentForm"
import { useGetEstimateQuery } from "@ensol/entool/queries/projects"

export const WaitingDeposit = ({ project, processId }: StepComponentProps) => {
  const estimateQuery = useGetEstimateQuery(project.id)

  return (
    <QueryWrapper query={estimateQuery} returnEmptyResults={true}>
      {({ data: estimate }) => (
        <StepActions
          project={project}
          processId={processId}
          actions={[{ action: "validate", label: "Acompte payé" }]}
          emails={[
            {
              eventName: EmailEvent.DEPOSIT_INVOICE_PAID,
              label: "Envoyer le mail d'acompte payé",
            },
          ]}
        >
          <PostDepositPaymentForm estimate={estimate} project={project} />
        </StepActions>
      )}
    </QueryWrapper>
  )
}
