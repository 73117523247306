import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { UnpackArray } from "@ensol/types/utils"

import {
  InstallationStep,
  PreliminaryRequestStep,
  TechnicalVisitStep,
} from "@ensol/shared/entities/projects/processes"
import {
  ProjectStatus,
  getStatusIndex,
} from "@ensol/shared/entities/projects/statuses"

export type SalesTaskCategory =
  | "signedToComplete"
  | "updateQuote"
  | "technicalVisitNonSchedulable"
  | "installationNonSchedulable"
  | "googleReviewToAsk"

export type SalesTaskCategoryConfig = {
  id: SalesTaskCategory
  name: string
  filter: (project: UnpackArray<ProjectResponses.ProjectsList>) => boolean
  getLink: (project: UnpackArray<ProjectResponses.ProjectsList>) => string
}

export const SALES_TASKS_CATEGORIES: SalesTaskCategoryConfig[] = [
  {
    id: "signedToComplete",
    name: "Projets signés à compléter",
    filter: (project) =>
      project.status === ProjectStatus.SIGNED &&
      project.preliminaryRequestStep ===
        PreliminaryRequestStep.WAITING_FOR_INFO,
    getLink: (project) => `/sales/${project.id}`,
  },
  {
    id: "updateQuote",
    name: "Devis à mettre à jour",
    filter: ({ technicalVisitStep }) =>
      technicalVisitStep === TechnicalVisitStep.WAITING_FOR_SALES_CHANGES,
    getLink: (project) => `/installations/${project.installationId}`,
  },
  {
    id: "technicalVisitNonSchedulable",
    name: "VT non planifiable",
    filter: ({ technicalVisitStep }) =>
      technicalVisitStep === TechnicalVisitStep.CANNOT_SCHEDULE,
    getLink: (project) => `/projects/${project.id}?tab=technicalVisitStep`,
  },
  {
    id: "installationNonSchedulable",
    name: "Installation non planifiable",
    filter: ({ installationStep }) =>
      installationStep === InstallationStep.CANNOT_SCHEDULE,
    getLink: (project) => `/projects/${project.id}?tab=installationStep`,
  },
  {
    id: "googleReviewToAsk",
    name: "Avis Google à demander",
    filter: (project) =>
      getStatusIndex(project.status) >=
        getStatusIndex(ProjectStatus.INSTALLATION_DONE) &&
      !project.feedback?.reviewRating &&
      project.feedback?.willNotPostReview !== true,
    getLink: (project) => `/feedbacks/${project.id}`,
  },
]
