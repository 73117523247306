import { IconHomeCheck } from "@tabler/icons-react"

import { FollowUpStepInput } from "@ensol/types/forms/projects/followUp"

import { getProspectFileName } from "@ensol/shared/utils/files"

import { Link } from "@ensol/entool/components/Link"
import { Section } from "@ensol/entool/components/Section"
import { FormField } from "@ensol/entool/components/entities/Project/FormField"
import {
  StepActions,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { MultiFileField } from "@ensol/entool/components/form/File/MultiFileField"
import { UpdateEquipments } from "@ensol/entool/components/form/House/UpdateEquipments"
import { FollowUpForm } from "@ensol/entool/pages/FollowUpProject/steps/components/FollowUpForm"
import { getMonthlyReportUrl } from "@ensol/entool/utils/projects/reports"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const AfterConsentForm = <Input extends FollowUpStepInput>({
  schema,
  initialValues,
  project,
  children,
  ...props
}: ProcessFormProps<Input> & StepActionsProps) => {
  const form = useProcessForm<Input>({
    schema,
    initialValues,
    project,
    url: "follow-up",
  })

  const { followUpProductionStartDate, installation } = project
  const { slug } = installation

  return (
    <StepActions
      project={project}
      validateStep={() => schema.parse(form.values)}
      {...props}
    >
      {children}
      <Section title="Suivi" icon={IconHomeCheck} titleOrder={4}>
        <FollowUpForm
          project={project}
          form={form}
          initialValues={initialValues}
        >
          <FormField
            initialValues={initialValues}
            propertyName="followUpM1ReportsPaths"
            isRequired
          >
            <Link
              label="Accéder au rapport"
              href={getMonthlyReportUrl({
                installationSlug: slug,
                productionStartDate: followUpProductionStartDate,
                isSimulated: false,
              })}
              isExternal
            />
            <MultiFileField
              previewProps={{
                fileName: getProspectFileName(
                  "rapport-M+1",
                  project.installation.house.prospect,
                ),
              }}
              inputProps={{
                accept: ["application/pdf"],
              }}
              {...form.getInputProps("followUpM1ReportsPaths")}
            />
          </FormField>
        </FollowUpForm>
      </Section>
      <UpdateEquipments house={project.installation.house} titleOrder={4} />
    </StepActions>
  )
}
