import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import { computeEvChargerInstallationCost } from "@ensol/shared/entities/installations/costs/evCharger"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

export const EvChargerSubsidyType = {
  TAX_REDUCTION: "TAX_REDUCTION",
} as const

export type EvChargerSubsidyType = keyof typeof EvChargerSubsidyType

type EvChargerSubsidyAmountInput = {
  evChargerInstallation: InstallationsResponses.EvChargerInstallation
}

const MAX_TAX_REDUCTION_SUBSIDY_AMOUNT = 500

const computeTaxReductionSubsidy = ({
  evChargerInstallation,
}: EvChargerSubsidyAmountInput): number => {
  const installationCost = computeEvChargerInstallationCost(
    evChargerInstallation,
  )

  return Math.min(
    MAX_TAX_REDUCTION_SUBSIDY_AMOUNT,
    roundDecimalNumber(0.75 * installationCost),
  )
}

type EvChargerSubsidyInfo = {
  name: string
  shortName: string
  payoutConditions: string
  computeAmount: (input: EvChargerSubsidyAmountInput) => number
}

const currentYear = new Date().getFullYear()
export const EV_CHARGER_SUBSIDIES: Record<
  EvChargerSubsidyType,
  EvChargerSubsidyInfo
> = {
  TAX_REDUCTION: {
    name: "Réduction d'impôts",
    shortName: "Réduction d'impôts",
    payoutConditions: `crédit d'impôt à déclarer en ${currentYear + 1} pour les dépenses de ${currentYear}`,
    computeAmount: computeTaxReductionSubsidy,
  },
}
