import _ from "lodash"

import { ExtraWorks } from "@ensol/types/installation"
import { QuoteDetail } from "@ensol/types/quote"

import { computeExtraWorkUnitPrice } from "@ensol/shared/entities/installations/costs/extraWorks"
import { ExtraWorkType, getExtraWork } from "@ensol/shared/material/extraWorks"

export const computeExtraWorksQuoteDetails = (
  extraWorks: ExtraWorks,
): QuoteDetail[] => {
  const extraWorksByType = _.groupBy(extraWorks, ({ type }) => type)

  const quoteDetails = new Array<QuoteDetail>()
  for (const [type, extraWorks] of Object.entries(extraWorksByType) as [
    ExtraWorkType,
    ExtraWorks,
  ][]) {
    const { category: name, description } = getExtraWork(type)

    const [billedWorks, freeWorks] = _.partition(
      extraWorks,
      ({ isBilled }) => isBilled === true,
    )

    if (billedWorks.length > 0) {
      quoteDetails.push({
        name,
        description,
        quantity: extraWorks.length,
        price: computeExtraWorkUnitPrice(type),
      })
    }

    if (freeWorks.length > 0) {
      quoteDetails.push({
        name,
        description,
        quantity: freeWorks.length,
        price: 0,
      })
    }
  }

  return quoteDetails
}
