import { Card, Group, Text } from "@mantine/core"
import { IconZoomQuestion } from "@tabler/icons-react"

type Props = {
  message?: string
  type: "text" | "file"
}

export const NotFound = ({ message, type }: Props) => (
  <Card
    w="300"
    h={type === "file" ? 150 : 40}
    padding="4"
    radius="md"
    withBorder
    style={{ border: "1px dashed var(--mantine-color-gray-3)" }}
  >
    <Group justify="center" align="center" h="100%" gap={8}>
      <IconZoomQuestion color="grey" size={24} />
      <Text size="xs" c="gray.6" style={{ textAlign: "center" }}>
        {message ?? "Information manquante"}
      </Text>
    </Group>
  </Card>
)
