import { useForm, zodResolver } from "@mantine/form"
import { ZodSchema } from "zod"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { Nullable } from "@ensol/types/utils"

import { useUpdateProcessDataMutation } from "@ensol/entool/queries/projects"
import { useDebounce } from "@ensol/entool/utils/helpers/useDebounce"

export type ProcessFormProps<Input, InitialValues = Input> = {
  schema: ZodSchema<Input>
  initialValues: Nullable<InitialValues>
  project: ProjectResponses.Project
}

export const useProcessForm = <
  Input extends Record<string, unknown>,
  InitialValues = Input,
>({
  schema,
  initialValues,
  project,
  url,
}: ProcessFormProps<Input, InitialValues> & { url: string }) => {
  const { mutateAsync: updateProject } = useUpdateProcessDataMutation<
    Nullable<Input>
  >(url, project.id)
  const debouncedUpdateProject = useDebounce(updateProject, 250)

  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(schema),
    initialValues,
    onValuesChange: (values) => debouncedUpdateProject(values),
  })

  return form
}
