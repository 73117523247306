import { keepPreviousData, useQuery } from "@tanstack/react-query"

import { InstallerResponses } from "@ensol/types/endpoints/installers"
import { InstallersEventsFilters } from "@ensol/types/forms/installers"

import { httpClient } from "@ensol/entool/backend/axios"
import { Option } from "@ensol/entool/utils/form/radio"

export const useInstallersOptions = () => {
  const { data } = useQuery<Option<string>[]>({
    queryKey: ["installers"],
    queryFn: async () => {
      const response =
        await httpClient.get<InstallerResponses.InstallersList>("/installers")
      return response.data.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    },
  })

  return data || []
}

export const useGetInstallersEventsQuery = (filters: InstallersEventsFilters) =>
  useQuery<InstallerResponses.InstallerEvent[]>({
    queryKey: ["installers", "events", filters],
    queryFn: async () => {
      const response = await httpClient.get("/installers/events", {
        params: filters,
      })
      return response.data.installerEvents
    },
    placeholderData: keepPreviousData,
  })
