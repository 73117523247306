import { Group, Select, TextInput } from "@mantine/core"
import { IconSearch } from "@tabler/icons-react"
import { ChangeEvent } from "react"

import { Team } from "@ensol/shared/entities/users"

import { UserSelect } from "@ensol/entool/components/form/UserSelect"
import { useInstallersOptions } from "@ensol/entool/queries/installers"
import { useSuppliersOptions } from "@ensol/entool/queries/suppliers"
import { CONTENT_PADDING_IN_PX } from "@ensol/entool/styles/constants"

type Props = {
  handleSearchChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleInstallerChange: (value: string | null) => void
  handleSupplierChange: (value: string | null) => void
  salesOwnerId?: string
  handleSalesOwnerChange: (value: string | null) => void
  operationsOwnerId?: string
  handleOperationsOwnerChange: (value: string | null) => void
  adminOwnerId?: string
  handleAdminOwnerChange: (value: string | null) => void
}

export const ProjectSearch = ({
  handleSearchChange,
  handleInstallerChange,
  handleSupplierChange,
  salesOwnerId,
  handleSalesOwnerChange,
  operationsOwnerId,
  handleOperationsOwnerChange,
  adminOwnerId,
  handleAdminOwnerChange,
}: Props) => {
  const installersOptions = useInstallersOptions()
  const suppliersOptions = useSuppliersOptions()

  return (
    <Group
      justify="space-between"
      align="flex-end"
      mt={{ base: 12, md: 24 }}
      mb={{ base: 12, md: 24 }}
      px={CONTENT_PADDING_IN_PX}
      style={{ flexWrap: "nowrap" }}
    >
      <TextInput
        w="100%"
        leftSectionPointerEvents="none"
        leftSection={<IconSearch />}
        placeholder="Rechercher un projet par nom, email, téléphone, ou adresse"
        onChange={handleSearchChange}
      />
      <UserSelect
        w="200"
        display={{ base: "none", md: "block" }}
        label="Responsable AE"
        value={salesOwnerId}
        teams={[Team.ACCOUNT_EXECUTIVE, Team.INSIDE_SALES]}
        withDefault={false}
        onChange={handleSalesOwnerChange}
        style={{ flexShrink: 0 }}
        comboboxProps={{ width: 300, position: "bottom-start" }}
        clearable
      />
      <UserSelect
        w="200"
        display={{ base: "none", md: "block" }}
        label="Responsable Ops"
        value={operationsOwnerId}
        teams={[Team.OPERATIONS]}
        withDefault={false}
        onChange={handleOperationsOwnerChange}
        style={{ flexShrink: 0 }}
        comboboxProps={{ width: 300, position: "bottom-start" }}
        clearable
      />
      <UserSelect
        w="200"
        display={{ base: "none", md: "block" }}
        label="Responsable Admin"
        value={adminOwnerId}
        teams={[Team.ADMIN]}
        withDefault={false}
        onChange={handleAdminOwnerChange}
        style={{ flexShrink: 0 }}
        comboboxProps={{ width: 300, position: "bottom-start" }}
        clearable
      />
      <Select
        w="150"
        display={{ base: "none", md: "block" }}
        label="Installateur"
        data={installersOptions}
        onChange={(value) => handleInstallerChange(value)}
        style={{ flexShrink: 0 }}
        clearable
      />
      <Select
        w="150"
        display={{ base: "none", md: "block" }}
        label="Fournisseur"
        data={suppliersOptions}
        onChange={(value) => handleSupplierChange(value)}
        style={{ flexShrink: 0 }}
        clearable
      />
    </Group>
  )
}
