import { validatedWithWorksStepFormSchema } from "@ensol/types/forms/projects/technicalVisit"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

import { TechnicalVisitForm } from "./components/TechnicalVisitForm"

export const ValidatedWithWorks = ({
  project,
  ...props
}: StepComponentProps) => (
  <TechnicalVisitForm
    schema={validatedWithWorksStepFormSchema}
    initialValues={getInitialValues(validatedWithWorksStepFormSchema, project)}
    project={project}
    actions={[
      {
        action: "validate",
        label: "Valider la visite technique",
      },
    ]}
    {...props}
  />
)
