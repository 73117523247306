import dayjs from "dayjs"

import { PaymentStep } from "@ensol/shared/entities/projects/processes"
import {
  SHORT_DISPLAY_DATE_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"

import { InfoComponentProps } from "../config"

export const PaymentInfo = ({ project }: InfoComponentProps) => {
  const { paymentStep, paymentHoldEndDate, installationStartDate } = project

  return (
    <ProcessInfos
      infos={[
        {
          name: "Date installation",
          value: formatDate(installationStartDate, SHORT_DISPLAY_DATE_FORMAT),
          color: dayjs().add(2, "weeks").isAfter(installationStartDate)
            ? "red.8"
            : undefined,
          isHidden:
            paymentStep !== PaymentStep.DEPOSIT_INVOICE_TO_SEND &&
            paymentStep !== PaymentStep.DEPOSIT_INVOICE_WAITING &&
            paymentStep !== PaymentStep.WAITING_FOR_DEPOSIT_PAYMENT &&
            paymentStep !== PaymentStep.DEPOSIT_INVOICE_PAID,
        },
        {
          name: "Date relance",
          value: formatDate(paymentHoldEndDate, SHORT_DISPLAY_DATE_FORMAT),
          color: dayjs().isAfter(paymentHoldEndDate) ? "red.8" : undefined,
          isHidden:
            paymentStep !== PaymentStep.DEPOSIT_INVOICE_TO_SEND &&
            paymentStep !== PaymentStep.DEPOSIT_INVOICE_WAITING,
        },
      ]}
    />
  )
}
