import {
  Autocomplete,
  ComboboxItem,
  ComboboxStringItem,
  Group,
  Loader,
  MantineStyleProps,
  Stack,
  Text,
} from "@mantine/core"

import { useSearchContracts } from "@ensol/entool/queries/switchgrid"

type Props = {
  value?: string
  onChange: (value: string | null) => void
  error?: string
  holderName: string | null
  address: string
} & MantineStyleProps

export const PdlAutocomplete = ({
  value,
  onChange,
  error,
  holderName,
  address,
  ...props
}: Props) => {
  const { matchingContracts, isSearchingContracts } = useSearchContracts(
    holderName,
    address,
  )

  return (
    <Group pos="relative">
      <Autocomplete
        placeholder="Sélectionnez ou entrez un numéro PDL"
        value={value}
        onChange={onChange}
        error={error}
        disabled={isSearchingContracts}
        renderOption={({ option }) => (
          <Stack gap="4">
            <Text size="sm">{option.value}</Text>
            {/* Autocomplete typing is not good here as option is typed
            ComboboxStringItem whereas it can be ComboboxItem */}
            <Text size="xs" c="gray">
              {(option as ComboboxItem).label}
            </Text>
          </Stack>
        )}
        data={
          matchingContracts.length > 0
            ? matchingContracts.map((contract) => ({
                value: `${contract.holderName}, ${contract.address}`,
                label: String(contract.pdl),
              }))
            : [
                {
                  value:
                    "Aucun contrat n'a été trouvé, essayez de modifier le titulaire du contrat, le nom du client ou son adresse.",
                  label: "",
                  disabled: true,
                } as ComboboxStringItem,
              ]
        }
        {...props}
      />
      {isSearchingContracts && (
        <Loader pos="absolute" right={8} top={8} size="sm" color="gray" />
      )}
    </Group>
  )
}
