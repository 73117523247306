import { IconBarrierBlock } from "@tabler/icons-react"
import * as z from "zod"

import { visitScheduledStepFormSchema } from "@ensol/types/forms/projects/consuel"

import { EmailEvent } from "@ensol/shared/entities/projects/emails"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { CONSUEL_PROPERTIES } from "@ensol/entool/pages/OpsProject/processes/Consuel/config"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"
import { validateAction } from "@ensol/entool/utils/projects/validateAction"

import { ConsuelForm } from "./components/ConsuelForm"

export const VisitScheduled = ({ project, processId }: StepComponentProps) => (
  <ConsuelForm
    schema={visitScheduledStepFormSchema}
    initialValues={{
      ...getInitialValues(visitScheduledStepFormSchema, project),
      ...pickDefinedProperties(CONSUEL_PROPERTIES, project),
    }}
    project={project}
    processId={processId}
    emails={[
      {
        eventName: EmailEvent.CONSUEL_SECOND_NOTICE_OF_VISIT,
        label: "Deuxième avis de visite",
      },
    ]}
    actions={[
      {
        action: "workNeeded",
        label: "Travaux nécessaires",
        color: "orange",
        Icon: IconBarrierBlock,
        validateAction: validateAction(
          z.object({
            consuelNonComplianceReportPaths: z
              .array(z.string())
              .min(1, "Champ obligatoire"),
          }),
        ),
      },
      {
        action: "validate",
        label: "Valider le Consuel",
        validateAction: validateAction(
          z.object({
            consuelCertificatePath: z.string({
              invalid_type_error: "Champ obligatoire",
            }),
          }),
        ),
      },
    ]}
  />
)
