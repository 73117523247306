import { Stack, Button, Group, Text, Loader } from "@mantine/core"
import { IconCheckbox } from "@tabler/icons-react"

import { HousesResponses } from "@ensol/types/endpoints/houses"

import { ConsentAskStatus } from "@ensol/shared/entities/houses/switchgrid"

import { CopiableText } from "@ensol/entool/components/CopiableText"
import { InfoBox } from "@ensol/entool/components/InfoBox"

type Props = {
  consentAsk?: HousesResponses.SwitchgridConsentAsk
  openConsentModal: () => void
}

export const CreateConsentAsk = ({ consentAsk, openConsentModal }: Props) => {
  if (consentAsk === undefined) {
    return (
      <Button onClick={openConsentModal} variant="outline" w="300">
        Demander le consentement
      </Button>
    )
  }

  if (consentAsk.isExpired) {
    return (
      <Stack align="flex-end" maw="500">
        <InfoBox
          message="Le consentement a expiré, veuillez en demander un nouveau"
          color="orange"
        />
        <Button onClick={openConsentModal} variant="outline" w="300">
          Demander à nouveau le consentement
        </Button>
      </Stack>
    )
  }

  const { status, askUrl } = consentAsk

  if (status === ConsentAskStatus.PREPARING_ASK) {
    return (
      <Group gap="8">
        <Loader color="gray" size="xs" />
        <Text size="sm">
          La préparation de la demande de consentement est en cours
        </Text>
      </Group>
    )
  }

  if (status === ConsentAskStatus.ADDRESS_CHECK_FAILED) {
    return (
      <Stack align="flex-end" maw="500">
        <InfoBox
          title="La validation de l'adresse et du PDL de la demande a échoué."
          message="Causes possibles : erreur dans l'adresse, une autre personne ou une société est titulaire du contrat..."
          color="red"
        />
        <Button onClick={openConsentModal} variant="outline" w="300">
          Envoyer une nouvelle demande
        </Button>
      </Stack>
    )
  }

  if (status === ConsentAskStatus.PENDING_USER_ACTION && askUrl) {
    return (
      <Stack gap="8" align="flex-end">
        <Group gap="8">
          <Loader color="gray" size="xs" />
          <Text size="sm">
            En attente de la signature du client, copiez et envoyez le lien
            ci-dessous
          </Text>
        </Group>
        <Group gap="8">
          <Text size="sm">Lien de signature : </Text>
          <CopiableText text={askUrl} c="blue" />
        </Group>
        <Button onClick={openConsentModal} variant="outline" w="300" mt="8">
          Remplacer la demande
        </Button>
      </Stack>
    )
  }

  if (status === ConsentAskStatus.ACCEPTED) {
    return (
      <Group gap="4" w="300">
        <IconCheckbox size={20} color="green" />
        <Text size="sm">Le consentement a été signé par le client</Text>
      </Group>
    )
  }
}
