import { Paper, Group, Text } from "@mantine/core"
import { IconFileDollar } from "@tabler/icons-react"

import { QuoteStatusBadge } from "@ensol/entool/components/entities/Installation/QuoteStatusBadge"
import { QuoteDocument } from "@ensol/entool/pages/Installation/Quote/QuoteDocument"
import { useGetQuoteQuery } from "@ensol/entool/queries/installations"

import { GenerateQuoteButton } from "./GenerateQuoteButton"
import { QuoteActions } from "./QuoteActions"

type Props = {
  installationId: string
  installationDate: Date
  quoteFileName: string
  isInstallationValid: boolean
}

export const Quote = ({
  installationId,
  installationDate,
  quoteFileName,
  isInstallationValid,
}: Props) => {
  const { data: quote } = useGetQuoteQuery(installationId)

  return (
    <Paper withBorder shadow="none" w={400} px={24} py={16}>
      <Group justify="space-between">
        <Group gap={6}>
          <IconFileDollar />
          <Text fw="bold">Devis</Text>
          <QuoteStatusBadge status={quote?.status} />
        </Group>
        <GenerateQuoteButton
          installationId={installationId}
          installationDate={installationDate}
          isInstallationValid={isInstallationValid}
        />
      </Group>
      <QuoteActions
        installationId={installationId}
        installationDate={installationDate}
      />
      <QuoteDocument
        installationId={installationId}
        quoteFileName={quoteFileName}
      />
    </Paper>
  )
}
