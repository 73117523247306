import {
  Loader,
  Select,
  SimpleGrid,
  Stack,
  Table,
  Text,
  Title,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconAddressBook } from "@tabler/icons-react"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import {
  HouseUpdateType,
  updateTownHallSchema,
} from "@ensol/types/forms/houses"

import { Link } from "@ensol/entool/components/Link"
import { Section } from "@ensol/entool/components/Section"
import { Field } from "@ensol/entool/components/form/Field"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useUpdateHouseMutation } from "@ensol/entool/queries/houses"
import { useGetTownHalls } from "@ensol/entool/queries/townHalls"
import { useDebounce } from "@ensol/entool/utils/helpers/useDebounce"

type Props = {
  house: HousesResponses.House
}

export const TownHall = ({ house }: Props) => {
  const { data: townHalls, isPending } = useGetTownHalls()
  const { mutateAsync: updateHouse } = useUpdateHouseMutation(house.id)
  const debouncedUpdateHouse = useDebounce(updateHouse, 250)

  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(updateTownHallSchema),
    onValuesChange: async (values) => debouncedUpdateHouse(values),
    initialValues: {
      updateType: HouseUpdateType.UPDATE_TOWN_HALL,
      townHallId: house.townHallId,
    },
  })

  if (!isPending && !townHalls) {
    return
  }

  const selectedTownHall = townHalls?.find(
    (townHall) => townHall.id === form.values.townHallId,
  )

  return (
    <Section title="Mairie" icon={IconAddressBook} titleOrder={4}>
      {isPending ? (
        <Stack w="100%" m="32" align="center">
          <Loader size="sm" color="gray" />
        </Stack>
      ) : (
        <SimpleGrid cols={2} spacing="48" mt="16">
          <Stack gap="24">
            <Field name="Mairie associée" noBorder>
              <Select
                w={FIELD_WIDTH}
                data={townHalls.map(({ id, cityName, postcode }) => ({
                  value: id,
                  label: `${cityName} ${postcode ? `(${postcode})` : ""}`,
                }))}
                searchable
                {...form.getInputProps("townHallId")}
              />
            </Field>
            <Field name="Lien portail" noBorder>
              {selectedTownHall?.portalUrl ? (
                <Link
                  label="Accéder au portail de mairie"
                  href={selectedTownHall.portalUrl}
                  isExternal
                />
              ) : (
                <Text size="sm">-</Text>
              )}
            </Field>
            <Field name="Notes" noBorder>
              <Text size="sm">
                {selectedTownHall?.notes ? selectedTownHall.notes : "-"}
              </Text>
            </Field>
          </Stack>
          {selectedTownHall?.contacts &&
            selectedTownHall.contacts.length > 0 && (
              <Stack gap="24">
                <Title order={5}>Contacts</Title>
                <Table
                  striped
                  data={{
                    head: ["Nom", "Email", "Téléphone", "Notes"],
                    body: selectedTownHall.contacts.map(
                      ({ name, email, phone, notes }) => [
                        name ? name : "-",
                        email ? email : "-",
                        phone ? phone : "-",
                        notes ? notes : "-",
                      ],
                    ),
                  }}
                />
              </Stack>
            )}
        </SimpleGrid>
      )}
    </Section>
  )
}
