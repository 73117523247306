import { Autocomplete, MantineStyleProps } from "@mantine/core"
import _ from "lodash"

import { Location } from "@ensol/types/forms/houses"

import {
  getPlaceDetails,
  useSearchPlace,
} from "@ensol/shared/thirdParties/google/places"

type Props = {
  defaultValue?: string
  handleSelectOption: (placeDetails: Location) => void
} & MantineStyleProps

export const AddressAutocomplete = ({
  defaultValue,
  handleSelectOption,
  ...props
}: Props) => {
  const { predictions, onSearch } = useSearchPlace(
    import.meta.env.VITE_GOOGLE_API_KEY,
  )

  return (
    <>
      <div id="places" />
      <Autocomplete
        defaultValue={defaultValue}
        onChange={_.debounce((value) => onSearch(value), 250)}
        onOptionSubmit={async (placeId: string) => {
          const placeDetails = await getPlaceDetails(
            import.meta.env.VITE_GOOGLE_API_KEY,
            placeId,
          )
          if (placeDetails) {
            handleSelectOption(placeDetails)
          }
        }}
        filter={({ options }) => options}
        data={predictions}
        {...props}
      />
    </>
  )
}
