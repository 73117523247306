import {
  Select,
  Textarea,
  Text,
  Stack,
  Button,
  MultiSelect,
  SimpleGrid,
} from "@mantine/core"
import {
  IconAdjustmentsAlt,
  IconCalendarX,
  IconHomeMove,
  IconListCheck,
  IconSolarPanel,
  IconTools,
} from "@tabler/icons-react"

import { InstallationStepInput } from "@ensol/types/forms/projects/installation"

import { computeInstallationEndDate } from "@ensol/shared/entities/projects/installation"
import {
  InstallationStep,
  getStepIndex,
} from "@ensol/shared/entities/projects/processes"
import { Team } from "@ensol/shared/entities/users"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"
import { getProspectFileName } from "@ensol/shared/utils/files"
import { DISPLAY_DATE_FORMAT, formatDate } from "@ensol/shared/utils/format"

import { Section } from "@ensol/entool/components/Section"
import { FILE_THUMBNAIL_WIDTH_IN_PX } from "@ensol/entool/components/entities/File/constants"
import { FormCheckbox } from "@ensol/entool/components/entities/Project/FormCheckbox"
import { FormField } from "@ensol/entool/components/entities/Project/FormField"
import {
  StepActionsProps,
  StepActions,
} from "@ensol/entool/components/entities/Project/StepActions"
import { TextInfo } from "@ensol/entool/components/entities/Project/TextInfo"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { MultiFileField } from "@ensol/entool/components/form/File/MultiFileField"
import { HouseForm } from "@ensol/entool/components/form/House/SalesVisit/HouseForm"
import { useHouseForm } from "@ensol/entool/components/form/House/SalesVisit/useHouseForm"
import { UpdateRoofType } from "@ensol/entool/components/form/House/UpdateRoofType"
import { ExternalPlantSelect } from "@ensol/entool/components/form/Installation/ExternalPlantSelect"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { UserSelect } from "@ensol/entool/components/form/UserSelect"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useInstallersOptions } from "@ensol/entool/queries/installers"
import { useCreateInstallationCertificateMutation } from "@ensol/entool/queries/projects"
import { booleanOptions } from "@ensol/entool/utils/form/options"
import {
  SCHEDULING_ISSUES_OPTIONS,
  INTEGRATION_KITS_OPTIONS,
} from "@ensol/entool/utils/projects/options"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const InstallationForm = <Input extends InstallationStepInput>({
  schema,
  initialValues,
  project,
  children,
  ...props
}: ProcessFormProps<Input> & StepActionsProps) => {
  const { house } = project.installation
  const { prospect } = house
  const form = useProcessForm<Input>({
    schema,
    initialValues,
    project,
    url: "installation",
  })
  const { mutateAsync: createCertificate, isPending: isCertificateLoading } =
    useCreateInstallationCertificateMutation(project.id)
  const houseForm = useHouseForm(house)
  const installersOptions = useInstallersOptions()

  const { photovoltaicInstallation } = project.installation

  const currentStep = project[props.processId]
  const inverter = photovoltaicInstallation
    ? getInverter(photovoltaicInstallation.inverterType)
    : null

  const updateStartDate = form.getInputProps("installationStartDate").onChange
  const updateEndDate = form.getInputProps("installationEndDate").onChange

  const isInstallationScheduled =
    getStepIndex(currentStep, "installationStep") >=
    getStepIndex(InstallationStep.SCHEDULED, "installationStep")

  return (
    <StepActions
      project={project}
      validateStep={() => schema.parse(form.values)}
      {...props}
    >
      {children}
      {(currentStep === InstallationStep.IN_PROGRESS ||
        currentStep === InstallationStep.NEW_VISIT_NEEDED) && (
        <Section title="Vérifications" icon={IconListCheck} titleOrder={4}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <Stack gap="24">
              <FormCheckbox
                initialValues={initialValues}
                propertyName="isActivationDone"
                {...form.getInputProps("isActivationDone")}
              />
              <FormCheckbox
                initialValues={initialValues}
                propertyName="isInstallationReportValidated"
                {...form.getInputProps("isInstallationReportValidated")}
              />
            </Stack>
            {inverter !== null && (
              <FormField
                initialValues={initialValues}
                propertyName="followUpExternalPlantIdentifier"
              >
                <ExternalPlantSelect
                  w="400"
                  installationId={project.installationId}
                  externalProviderName={inverter.brand}
                  {...form.getInputProps("followUpExternalPlantIdentifier")}
                  onChange={(plantId: string | null) => {
                    form
                      .getInputProps("followUpExternalPlantIdentifier")
                      .onChange(plantId)
                    form
                      .getInputProps("isActivationDone")
                      .onChange(plantId !== null)
                    form
                      .getInputProps("isClientAccountCreated")
                      .onChange(plantId !== null)
                  }}
                />
              </FormField>
            )}
          </SimpleGrid>
        </Section>
      )}
      {currentStep === InstallationStep.SCHEDULED && (
        <Section title="Vérifications" icon={IconListCheck} titleOrder={4}>
          <Stack gap="24" mt="16" mb="32">
            <Stack gap="8">
              <FormCheckbox
                initialValues={initialValues}
                propertyName="areInstallationsCommentsConsidered"
                {...form.getInputProps("areInstallationsCommentsConsidered")}
              />
              <Text
                size="sm"
                p="10px"
                m="0px 30px"
                style={{ borderLeft: "3px solid var(--mantine-color-gray-4)" }}
              >
                {project.installationNotes ? (
                  project.installationNotes
                ) : (
                  <Text c="gray" fs="italic">
                    Aucun commentaires
                  </Text>
                )}
              </Text>
            </Stack>
            <FormCheckbox
              initialValues={initialValues}
              propertyName="isActivationPrepared"
              {...form.getInputProps("isActivationPrepared")}
            />
            <FormCheckbox
              initialValues={initialValues}
              propertyName="isClientAccountCreated"
              {...form.getInputProps("isClientAccountCreated")}
            />
            <FormCheckbox
              initialValues={initialValues}
              propertyName="isInstallationReportPrepared"
              {...form.getInputProps("isInstallationReportPrepared")}
            />
            <FormCheckbox
              initialValues={initialValues}
              propertyName="isTechnicalVisitReportSent"
              {...form.getInputProps("isTechnicalVisitReportSent")}
            />
          </Stack>
        </Section>
      )}
      {(currentStep === InstallationStep.SCHEDULING_ON_HOLD ||
        currentStep === InstallationStep.CANNOT_SCHEDULE) && (
        <Section title="Non planifiable" icon={IconCalendarX} titleOrder={4}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="installationSchedulingHoldEndDate"
              >
                <DateInput
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_FORMAT}
                  clearable
                  {...form.getInputProps("installationSchedulingHoldEndDate")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="installationLastContactDate"
              >
                <DateInput
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_FORMAT}
                  clearable
                  {...form.getInputProps("installationLastContactDate")}
                />
              </FormField>
            </Stack>
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="installationSchedulingIssues"
                isRequired
              >
                <MultiSelect
                  w={FIELD_WIDTH}
                  searchable
                  hidePickedOptions
                  data={SCHEDULING_ISSUES_OPTIONS}
                  {...form.getInputProps("installationSchedulingIssues")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="installationSchedulingIssuesNotes"
              >
                <Textarea
                  autosize
                  w={FIELD_WIDTH}
                  minRows={3}
                  {...form.getInputProps("installationSchedulingIssuesNotes")}
                />
              </FormField>
            </Stack>
          </SimpleGrid>
        </Section>
      )}
      <Section
        title={
          isInstallationScheduled
            ? "Gestion de l'installation"
            : "Planification de l'installation"
        }
        icon={IconAdjustmentsAlt}
        titleOrder={4}
      >
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="installationEstimatedDuration"
            >
              <TextInfo
                value={
                  project.installationEstimatedDuration
                    ? `${project.installationEstimatedDuration} jour${project.installationEstimatedDuration > 1 ? "s" : ""}`
                    : null
                }
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="installationStartDate"
              isRequired={!isInstallationScheduled}
            >
              {isInstallationScheduled ? (
                <TextInfo value={formatDate(project.installationStartDate)} />
              ) : (
                <DateInput
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_FORMAT}
                  clearable
                  maxDate={form.values.installationEndDate ?? undefined}
                  {...form.getInputProps("installationStartDate")}
                  onChange={(value) => {
                    updateStartDate(value)

                    if (
                      form.values.installationEndDate === null &&
                      value !== null
                    ) {
                      updateEndDate(computeInstallationEndDate(project, value))
                    }
                  }}
                />
              )}
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="installationEndDate"
              isRequired={!isInstallationScheduled}
            >
              {isInstallationScheduled ? (
                <TextInfo value={formatDate(project.installationEndDate)} />
              ) : (
                <DateInput
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_FORMAT}
                  clearable
                  minDate={form.values.installationStartDate ?? undefined}
                  {...form.getInputProps("installationEndDate")}
                />
              )}
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="installerId"
              isRequired={!isInstallationScheduled}
            >
              {isInstallationScheduled ? (
                <TextInfo value={project.installer?.name ?? null} />
              ) : (
                <Select
                  w={FIELD_WIDTH}
                  data={installersOptions}
                  {...form.getInputProps("installerId")}
                />
              )}
            </FormField>
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="installationNotes"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("installationNotes")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalExpertId"
            >
              <UserSelect
                teams={[Team.TECHNICAL_EXPERT]}
                w={FIELD_WIDTH}
                withDefault={false}
                {...form.getInputProps("technicalExpertId")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="siteManagerId"
            >
              <UserSelect
                teams={[Team.SITE_MANAGER]}
                w={FIELD_WIDTH}
                withDefault={false}
                {...form.getInputProps("siteManagerId")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="installationReportPath"
            >
              <FileField
                previewProps={{
                  fileName: getProspectFileName(
                    "compte-rendu-installation",
                    prospect,
                  ),
                }}
                inputProps={{ accept: ["application/pdf"] }}
                {...form.getInputProps("installationReportPath")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="installationPhotosPaths"
            >
              <MultiFileField
                previewProps={{
                  fileName: getProspectFileName("photo-installation", prospect),
                }}
                inputProps={{
                  accept: ["image/png", "image/jpeg"],
                }}
                {...form.getInputProps("installationPhotosPaths")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="installationSecurityReportPath"
            >
              <FileField
                previewProps={{
                  fileName: getProspectFileName(
                    "compte-rendu-sécurité",
                    prospect,
                  ),
                }}
                inputProps={{ accept: ["application/pdf"] }}
                {...form.getInputProps("installationSecurityReportPath")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="installationCertificatePath"
              isRequired
            >
              <Button
                onClick={async () => {
                  const { installationCertificatePath } =
                    await createCertificate()
                  form
                    .getInputProps("installationCertificatePath")
                    .onChange(installationCertificatePath)
                }}
                loading={isCertificateLoading}
                disabled={project.installationCertificatePath !== null}
                w={FILE_THUMBNAIL_WIDTH_IN_PX}
              >
                Générer le bon de fin de chantier
              </Button>
              <FileField
                previewProps={{
                  fileName: getProspectFileName("bon-fin-chantier", prospect),
                }}
                inputProps={{ accept: ["application/pdf"] }}
                {...form.getInputProps("installationCertificatePath")}
              />
            </FormField>
          </Stack>
        </SimpleGrid>
      </Section>
      {"installationNewVisitDate" in initialValues && (
        <Section title="Besoin de repasser" icon={IconHomeMove} titleOrder={4}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <FormField
              initialValues={initialValues}
              propertyName="installationNewVisitDate"
              isRequired
            >
              <DateInput
                w={FIELD_WIDTH}
                valueFormat={DISPLAY_DATE_FORMAT}
                clearable
                minDate={form.values.installationStartDate ?? new Date()}
                {...form.getInputProps("installationNewVisitDate")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="installationNewVisitReason"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("installationNewVisitReason")}
              />
            </FormField>
          </SimpleGrid>
        </Section>
      )}
      <Section title="Matériel" icon={IconSolarPanel} titleOrder={4}>
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <UpdateRoofType house={project.installation.house} />
            <FormField
              initialValues={initialValues}
              propertyName="integrationKitType"
            >
              <Select
                w={FIELD_WIDTH}
                data={INTEGRATION_KITS_OPTIONS}
                {...form.getInputProps("integrationKitType")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="hardwareNotes"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("hardwareNotes")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="isPLCNeeded"
              isRequired
            >
              <RadioGroup
                options={booleanOptions}
                {...form.getInputProps("isPLCNeeded")}
              />
            </FormField>
          </Stack>
        </SimpleGrid>
      </Section>
      <Section title="Infos visite technique" icon={IconTools} titleOrder={4}>
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitReportsPath"
            >
              <MultiFileField
                previewProps={{
                  fileName: getProspectFileName("compte-rendu-VT", prospect),
                }}
                inputProps={{ accept: ["application/pdf"] }}
                {...form.getInputProps("technicalVisitReportsPath")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitK2ReportsPath"
            >
              <MultiFileField
                previewProps={{
                  fileName: getProspectFileName("rapport-k2-VT", prospect),
                }}
                inputProps={{
                  accept: {
                    "application/pdf": [".pdf"],
                    "application/octet-stream": [".k2o"],
                  },
                }}
                {...form.getInputProps("technicalVisitK2ReportsPath")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitPanelsLayoutPath"
            >
              <FileField
                previewProps={{
                  fileName: getProspectFileName("calepinage-VT", prospect),
                }}
                inputProps={{ accept: ["image/png", "image/jpeg"] }}
                {...form.getInputProps("technicalVisitPanelsLayoutPath")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <UpdateRoofType house={project.installation.house} />
            <FormField
              initialValues={initialValues}
              propertyName="integrationKitType"
            >
              <Stack align="flex-end" gap={4}>
                <Select
                  w={FIELD_WIDTH}
                  data={INTEGRATION_KITS_OPTIONS}
                  {...form.getInputProps("integrationKitType")}
                />
              </Stack>
            </FormField>
          </Stack>
        </SimpleGrid>
      </Section>
      <HouseForm house={house} form={houseForm} />
    </StepActions>
  )
}
