import { EnsolError } from "@ensol/shared/utils/errors"

import {
  StepActions,
  StepComponentProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useGetInstallationQuery } from "@ensol/entool/queries/installations"

import { GenerateEstimateForm } from "./components/GenerateEstimateForm"

export const DepositToSend = ({ project, processId }: StepComponentProps) => {
  const simulationQuery = useGetInstallationQuery(project.installationId)

  return (
    <StepActions
      project={project}
      processId={processId}
      actions={[{ action: "validate", label: "En attente paiement acompte" }]}
      validateStep={() => {
        if (project.pennylaneEstimateId === null) {
          throw new EnsolError("Le devis PennyLane n'a pas été créé")
        }
      }}
    >
      <QueryWrapper query={simulationQuery}>
        {({ data: installation }) => (
          <GenerateEstimateForm project={project} installation={installation} />
        )}
      </QueryWrapper>
    </StepActions>
  )
}
