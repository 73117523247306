import {
  StepActions,
  StepComponentProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { PostDepositPaymentForm } from "@ensol/entool/pages/OpsProject/processes/Payment/components/PostDepositPaymentForm"
import { useGetEstimateQuery } from "@ensol/entool/queries/projects"

export const FinalInvoiceToSend = ({
  project,
  processId,
}: StepComponentProps) => {
  const estimateQuery = useGetEstimateQuery(project.id)

  return (
    <QueryWrapper query={estimateQuery} returnEmptyResults={true}>
      {({ data: estimate }) => (
        <StepActions
          project={project}
          processId={processId}
          actions={[
            { action: "validate", label: "En attente de paiement final" },
          ]}
        >
          <PostDepositPaymentForm estimate={estimate} project={project} />
        </StepActions>
      )}
    </QueryWrapper>
  )
}
