import { Text } from "@mantine/core"
import dayjs from "dayjs"

import {
  HardwareStep,
  InstallationStep,
  PaymentStep,
  PreliminaryRequestStep,
  getStepIndex,
} from "@ensol/shared/entities/projects/processes"
import {
  SHORT_DISPLAY_DATE_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { IssuesBadges } from "@ensol/entool/components/entities/Project/IssuesBadges"
import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"
import { CheckBadge } from "@ensol/entool/pages/OpsProjects/components/CheckBadge"
import { TechnicalVisitBadge } from "@ensol/entool/pages/OpsProjects/components/TechnicalVisitBadge"

import { InfoComponentProps } from "../config"

export const InstallationInfo = ({ project }: InfoComponentProps) => {
  const {
    preliminaryRequestExpectedValidationDate,
    installationStep,
    installationSchedulingHoldEndDate,
    installationLastContactDate,
    installationSchedulingIssues,
    installationStartDate,
    installationNewVisitDate,
    installationReportPath,
  } = project
  const daysBeforeInstallation = dayjs(installationStartDate).diff(
    dayjs(),
    "day",
  )

  return (
    <ProcessInfos
      infos={[
        {
          name: "Valid. DP prévue",
          value: formatDate(
            preliminaryRequestExpectedValidationDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          isHidden:
            installationStep !== InstallationStep.PENDING &&
            installationStep !== InstallationStep.SCHEDULED,
        },
        {
          name: "DP validée ?",
          dataType: "component",
          value: (
            <CheckBadge
              isChecked={
                getStepIndex(
                  project.preliminaryRequestStep,
                  "preliminaryRequestStep",
                ) >=
                getStepIndex(
                  PreliminaryRequestStep.APPROVED,
                  "preliminaryRequestStep",
                )
              }
            />
          ),
          isHidden:
            installationStep !== InstallationStep.PENDING &&
            installationStep !== InstallationStep.SCHEDULED,
        },
        {
          name: "VT validée ?",
          dataType: "component",
          value: <TechnicalVisitBadge project={project} />,
          isHidden:
            installationStep !== InstallationStep.PENDING &&
            installationStep !== InstallationStep.SCHEDULED,
        },
        {
          name: "Acompte payé ?",
          dataType: "component",
          value: (
            <CheckBadge
              isChecked={
                getStepIndex(project.paymentStep, "paymentStep") >=
                getStepIndex(PaymentStep.DEPOSIT_INVOICE_PAID, "paymentStep")
              }
            />
          ),
          isHidden:
            installationStep !== InstallationStep.PENDING &&
            installationStep !== InstallationStep.SCHEDULED,
        },
        {
          name: "Matériel commandé ?",
          dataType: "component",
          value: (
            <CheckBadge
              isChecked={
                getStepIndex(project.hardwareStep, "hardwareStep") >=
                getStepIndex(HardwareStep.PREPARATION_PENDING, "hardwareStep")
              }
            />
          ),
          isHidden:
            installationStep !== InstallationStep.PENDING &&
            installationStep !== InstallationStep.SCHEDULED,
        },
        {
          name: "Problèmes",
          dataType: "component",
          value:
            installationSchedulingIssues.length > 0 ? (
              <IssuesBadges issues={installationSchedulingIssues} />
            ) : (
              <Text size="sm" fw={500}>
                -
              </Text>
            ),
          isHidden:
            installationStep !== InstallationStep.SCHEDULING_ON_HOLD &&
            installationStep !== InstallationStep.CANNOT_SCHEDULE,
        },
        {
          name: "À rappeler le",
          value: formatDate(
            installationSchedulingHoldEndDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          isHidden:
            installationStep !== InstallationStep.SCHEDULING_ON_HOLD &&
            installationStep !== InstallationStep.CANNOT_SCHEDULE,
          color: dayjs().isAfter(installationSchedulingHoldEndDate)
            ? "red.8"
            : undefined,
        },
        {
          name: "Dernier contact",
          value: formatDate(
            installationLastContactDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          isHidden:
            installationStep !== InstallationStep.SCHEDULING_ON_HOLD &&
            installationStep !== InstallationStep.CANNOT_SCHEDULE,
        },
        {
          name: "Date installation",
          value: formatDate(installationStartDate, SHORT_DISPLAY_DATE_FORMAT),
        },
        {
          name: "Date de passage",
          value: formatDate(
            installationNewVisitDate,
            SHORT_DISPLAY_DATE_FORMAT,
          ),
          isHidden: installationStep !== InstallationStep.NEW_VISIT_NEEDED,
        },
        {
          name: "Temps restant",
          value: `${daysBeforeInstallation} jours`,
          isHidden:
            installationStartDate === null || daysBeforeInstallation < 0,
          color: daysBeforeInstallation <= 7 ? "red" : undefined,
        },
        {
          name: "Installateur",
          value: project.installer?.name,
          isHidden:
            installationStep !== InstallationStep.COMPLETED &&
            installationStep !== InstallationStep.IN_PROGRESS &&
            installationStep !== InstallationStep.NEW_VISIT_NEEDED,
        },
        {
          name: "CR installation reçu",
          dataType: "component",
          value: <CheckBadge isChecked={installationReportPath !== null} />,
          isHidden:
            installationStep !== InstallationStep.COMPLETED &&
            installationStep !== InstallationStep.UPDATE_INSTALLATION &&
            installationStep !== InstallationStep.CERTIFICATE_SENT &&
            installationStep !== InstallationStep.CERTIFICATE_SIGNED,
        },
      ]}
    />
  )
}
