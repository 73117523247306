import { UseFormReturnType } from "@mantine/form"
import { IconAdjustments } from "@tabler/icons-react"
import { useEffect, useState } from "react"

import { EnergyResponses } from "@ensol/types/endpoints/energy"
import { HousesResponses } from "@ensol/types/endpoints/houses"
import { SalesUpdateInstallationInput } from "@ensol/types/forms/installations"
import {
  ConsumptionDataSource,
  ProductionDataSource,
} from "@ensol/types/simulation"

import { Section } from "@ensol/entool/components/Section"
import { Field } from "@ensol/entool/components/form/Field"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { NumberInputWithSuggestion } from "@ensol/entool/components/form/NumberInputWithSuggestion"
import { ConsumptionParameters } from "@ensol/entool/pages/Installation/SimulationParameters/Consumption"
import { ProductionParameters } from "@ensol/entool/pages/Installation/SimulationParameters/Production"

import { Settings } from "./Settings"
import { SwitchgridParameters } from "./Switchgrid"

type Props<Values> = {
  form: UseFormReturnType<Values>
  installationValues: SalesUpdateInstallationInput
  energyStats?: EnergyResponses.Stats | null
  house: HousesResponses.House<{
    include: {
      switchgridConsent: true
      switchgridOrder: true
      roofSections: true
    }
  }>
  isNewInstallation: boolean
  slug?: string
}

export const SimulationParameters = <
  Values extends {
    productionDataSource: ProductionDataSource
    consumptionDataSource: ConsumptionDataSource
    yearlyConsumption: number
    monthlyBill: number
    autoConsumptionPercent: number
    horizonProfileFilePath?: string | null
  },
>({
  form,
  installationValues,
  energyStats,
  house,
  isNewInstallation,
  slug,
}: Props<Values>) => {
  const [
    autoConsumptionPercentSuggestion,
    setAutoConsumptionPercentSuggestion,
  ] = useState<number>()

  useEffect(() => {
    if (energyStats) {
      const autoConsumptionPercent = energyStats.autoConsumptionPercent

      // If the field is untouched, do not autofill the value but update the suggestion value instead
      if (form.isTouched("autoConsumptionPercent")) {
        setAutoConsumptionPercentSuggestion(energyStats.autoConsumptionPercent)
      } else if (
        // Else, if the form is pristine (ie no update since page load), don't update the value
        // Otherwise, update the value only if it's different from the current value
        form.isDirty() &&
        autoConsumptionPercent !== form.values.autoConsumptionPercent
      ) {
        form
          .getInputProps("autoConsumptionPercent")
          .onChange(autoConsumptionPercent)
        form.setTouched((touchedFields) => ({
          ...touchedFields,
          autoConsumptionPercent: false,
        }))
      }
    }
  }, [form, energyStats])

  return (
    <Section
      title="Paramètres de simulation"
      icon={IconAdjustments}
      Actions={<Settings />}
      isCollapsible
    >
      <ProductionParameters
        form={form}
        installationValues={installationValues}
        house={house}
      />
      <SwitchgridParameters houseId={house.id} />
      <ConsumptionParameters form={form} house={house} />
      <Field name="Taux d'autoconsommation" noBorder={isNewInstallation}>
        <NumberInputWithSuggestion
          w={200}
          min={0}
          ml="auto"
          suffix="%"
          suggestion={autoConsumptionPercentSuggestion}
          {...form.getInputProps("autoConsumptionPercent")}
        />
      </Field>
      {!isNewInstallation && slug && (
        <Field name="Masque Solaire" noBorder>
          <FileField
            previewProps={{
              fileName: `masque-solaire-${slug}`,
            }}
            inputProps={{
              uploadUrl: "/horizon-profile",
              accept: ["text/csv"],
            }}
            {...form.getInputProps("horizonProfileFilePath")}
          />
        </Field>
      )}
    </Section>
  )
}
