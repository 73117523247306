import {
  StepActions,
  StepComponentProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useGetInstallationQuery } from "@ensol/entool/queries/installations"

import { GenerateEstimateForm } from "./components/GenerateEstimateForm"

export const DepositWaiting = ({ project, processId }: StepComponentProps) => {
  const simulationQuery = useGetInstallationQuery(project.installationId)

  return (
    <StepActions
      project={project}
      processId={processId}
      actions={[{ action: "validate", label: "Facture d'acompte à envoyer" }]}
    >
      <QueryWrapper query={simulationQuery}>
        {({ data: installation }) => (
          <GenerateEstimateForm project={project} installation={installation} />
        )}
      </QueryWrapper>
    </StepActions>
  )
}
