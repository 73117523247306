import { PreliminaryRequestStep } from "@ensol/shared/entities/projects/processes"

import { StepComponentGetter } from "@ensol/entool/components/entities/Project/ProcessView"

import { WaitingForInfo } from "./1-WaitingForInfo"
import { IncompleteSubmitted } from "./10-IncompleteSubmitted"
import { Rejected } from "./11-Rejected"
import { Approved } from "./12-Approved"
import { AuthorisationReceived } from "./13-AuthorisationReceived"
import { PreliminaryValidation } from "./2-PreliminaryValidation"
import { SubmissionOnHold } from "./3-SubmissionOnHold"
import { SubmissionPending } from "./4-SubmissionPending"
import { WaitingForSolteo } from "./5-WaitingForSolteo"
import { Submitted } from "./6-Submitted"
import { Acknowledgment } from "./7-Acknowledgment"
import { Incomplete } from "./8-Incomplete"
import { IncompleteWaitingForStakeholder } from "./9-IncompleteWaitingForStakeholder"

export const getPreliminaryRequestStepComponent: StepComponentGetter = (
  currentStep,
) => {
  switch (currentStep) {
    case PreliminaryRequestStep.WAITING_FOR_INFO:
      return { StepComponent: WaitingForInfo, isCancellable: false }
    case PreliminaryRequestStep.PRELIMINARY_VALIDATION:
      return { StepComponent: PreliminaryValidation, isCancellable: false }
    case PreliminaryRequestStep.SUBMISSION_ON_HOLD:
      return { StepComponent: SubmissionOnHold, isCancellable: false }
    case PreliminaryRequestStep.SUBMISSION_PENDING:
      return { StepComponent: SubmissionPending, isCancellable: false }
    case PreliminaryRequestStep.WAITING_FOR_SOLTEO:
      return { StepComponent: WaitingForSolteo }
    case PreliminaryRequestStep.SUBMITTED:
      return { StepComponent: Submitted }
    case PreliminaryRequestStep.ACKNOWLEDGMENT:
      return { StepComponent: Acknowledgment }
    case PreliminaryRequestStep.INCOMPLETE:
      return { StepComponent: Incomplete }
    case PreliminaryRequestStep.INCOMPLETE_WAITING_FOR_STAKEHOLDER:
      return { StepComponent: IncompleteWaitingForStakeholder }
    case PreliminaryRequestStep.INCOMPLETE_SUBMITTED:
      return { StepComponent: IncompleteSubmitted }
    case PreliminaryRequestStep.REJECTED:
      return { StepComponent: Rejected, isCancellable: false }
    case PreliminaryRequestStep.APPROVED:
      return { StepComponent: Approved }
    case PreliminaryRequestStep.AUTHORISATION_RECEIVED:
      return { StepComponent: AuthorisationReceived }

    default:
      return {
        StepComponent: null,
      }
  }
}
