import { showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useCallback } from "react"
import { useParams } from "react-router-dom"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { SwitchgridResponses } from "@ensol/types/endpoints/switchgrid"
import { DataConsentInput } from "@ensol/types/forms/houses/dataConsent"

import { OrderStatus } from "@ensol/shared/entities/houses/switchgrid"

import { httpClient } from "@ensol/entool/backend/axios"
import { queryClient } from "@ensol/entool/backend/queryClient"

const useInvalidateHouseQuery = () => {
  const { houseId } = useParams()

  return useCallback(async () => {
    if (houseId) {
      await queryClient.invalidateQueries({
        queryKey: ["houses", houseId],
      })
    }
  }, [houseId])
}

const useInvalidateInstallationQuery = () => {
  const { installationId } = useParams()

  return useCallback(async () => {
    if (installationId) {
      await queryClient.invalidateQueries({
        queryKey: ["installations", installationId],
      })
    }
  }, [installationId])
}

export const useSearchContracts = (name: string | null, address: string) => {
  const { data, isPending } = useQuery<SwitchgridResponses.Contracts>({
    queryKey: ["switchgrid", "search-contract", name, address],
    queryFn: async () => {
      if (name === null) {
        return { contracts: [] }
      }

      const response = await httpClient.get("/switchgrid/search-contract", {
        params: { name, address },
      })
      return response.data
    },
  })

  return {
    matchingContracts: data?.contracts ?? [],
    isSearchingContracts: isPending,
  }
}

export const useAskConsentMutation = (houseId: string) => {
  const invalidateHouseQuery = useInvalidateHouseQuery()
  const invalidateInstallationQuery = useInvalidateInstallationQuery()

  return useMutation<void, string, DataConsentInput>({
    mutationFn: async (dataConsentInput) => {
      const response = await httpClient.post(
        `/switchgrid/${houseId}/ask`,
        dataConsentInput,
      )
      return response.data
    },
    onSuccess: async () => {
      showNotification({ message: "Demande de consentement envoyée !" })
      await invalidateHouseQuery()
      await invalidateInstallationQuery()
      await queryClient.invalidateQueries({
        queryKey: ["switchgrid", houseId, "ask"],
      })
    },
  })
}

export const useGetConsentAskQuery = (houseId: string) => {
  return useQuery<HousesResponses.SwitchgridConsentAsk>({
    queryKey: ["switchgrid", houseId, "ask"],
    queryFn: async () => {
      const response = await httpClient.get(`/switchgrid/${houseId}/ask`)
      return response.data
    },
  })
}

export const useCreateOrderMutation = (houseId: string) => {
  const invalidateHouseQuery = useInvalidateHouseQuery()
  const invalidateInstallationQuery = useInvalidateInstallationQuery()

  return useMutation({
    mutationFn: async () => {
      const response = await httpClient.post(`/switchgrid/${houseId}/order`)
      return response.data
    },
    onSuccess: async () => {
      showNotification({ message: "Demande de données envoyée !" })
      await invalidateHouseQuery()
      await invalidateInstallationQuery()
      await queryClient.invalidateQueries({
        queryKey: ["switchgrid", houseId, "order"],
      })
    },
  })
}

export const useGetOrderQuery = (houseId: string) => {
  const invalidateHouseQuery = useInvalidateHouseQuery()
  const invalidateInstallationQuery = useInvalidateInstallationQuery()

  return useQuery<HousesResponses.SwitchgridOrder>({
    queryKey: ["switchgrid", houseId, "order"],
    queryFn: async () => {
      const response = await httpClient.get(`/switchgrid/${houseId}/order`)

      if (response.data.status === OrderStatus.SUCCEEDED) {
        await invalidateHouseQuery()
        await invalidateInstallationQuery()
      }

      return response.data
    },
    refetchInterval: (query) => {
      if (query.state.data?.status === OrderStatus.PENDING) {
        return 5000
      }
      return false
    },
  })
}
