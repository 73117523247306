import * as z from "zod"

import { transformSchemaForApi } from "../utils"
import { integrationKitTypeField } from "./shared"

export const beforeVisitStepsFormSchema = z.object({
  technicalVisitNotes: z.string().optional(),
  technicalVisitStartDate: z.date().nullable().optional(),
  technicalVisitExpectedValidationDate: z.date().nullable().optional(),
  technicalVisitInstallerId: z.string().nullable().optional(),
})

export const schedulingOnHoldStepFormSchema = beforeVisitStepsFormSchema.extend(
  {
    technicalVisitSchedulingHoldEndDate: z.date().nullable().optional(),
    technicalVisitLastContactDate: z.date().nullable().optional(),
    technicalVisitSchedulingIssues: z.array(z.string()),
    technicalVisitSchedulingIssuesNotes: z.string().optional(),
  },
)

export const scheduledStepFormSchema = schedulingOnHoldStepFormSchema.extend({
  installerId: z.string().nullable().optional(),
  isTechnicalVisitReady: z.boolean().optional(),
  technicalVisitStartDate: z.date({ invalid_type_error: "Champ obligatoire" }),
  technicalVisitInstallerId: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
})

export const completedStepFormSchema = scheduledStepFormSchema.extend({
  technicalVisitDifficulty: z.string().nullable().optional(),
  technicalVisitPanelsLayoutPath: z.string().nullable().optional(),
  technicalVisitK2ReportsPath: z.array(z.string()),
  technicalVisitReportsPath: z.array(z.string()).min(1, "Champ obligatoire"),
  technicalVisitReportReceivedDate: z.date({
    invalid_type_error: "Champ obligatoire",
  }),
  salesVisitPanelsLayoutPath: z.string().nullable().optional(),
  salesVisitK2ReportPath: z.string().nullable().optional(),
  technicalVisitNextSteps: z.string().optional(),
})

export const beingProcessedStepFormSchema = completedStepFormSchema.extend({
  technicalVisitClientComments: z.string().optional(),
  technicalVisitInternalComments: z.string().optional(),
  installationNotes: z.string().optional(),
  installationEstimatedDuration: z.number({
    invalid_type_error: "Champ obligatoire",
  }),
  technicalVisitValidationStatus: z.array(z.string()),
  integrationKitType: integrationKitTypeField.nullable().optional(),
  isPLCNeeded: z.boolean({ invalid_type_error: "Champ obligatoire" }),
  hardwareNotes: z.string().optional(),
})

export const validatedWithWorksStepFormSchema =
  beingProcessedStepFormSchema.extend({
    technicalVisitEndOfWorksDate: z.date({
      invalid_type_error: "Champ obligatoire",
    }),
    technicalVisitWorksPhotos: z.array(z.string()),
  })

export type TechnicalVisitStepInput =
  | z.infer<typeof beforeVisitStepsFormSchema>
  | z.infer<typeof schedulingOnHoldStepFormSchema>
  | z.infer<typeof scheduledStepFormSchema>
  | z.infer<typeof completedStepFormSchema>
  | z.infer<typeof beingProcessedStepFormSchema>
  | z.infer<typeof validatedWithWorksStepFormSchema>

export const technicalVisitSchema = transformSchemaForApi(
  validatedWithWorksStepFormSchema,
)
