import { Stack, Group } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconDeviceFloppy } from "@tabler/icons-react"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import {
  UpdateInstallationStepInput,
  updateInstallationStepSchema,
} from "@ensol/types/forms/installations"
import { InstallationUpdateType } from "@ensol/types/installation"

import { computeTotalDiscount } from "@ensol/shared/entities/installations/savings"
import { EnsolError } from "@ensol/shared/utils/errors"

import { queryClient } from "@ensol/entool/backend/queryClient"
import {
  StepActions,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { HouseSection } from "@ensol/entool/components/form/House/HouseSection"
import { BatteryInstallation } from "@ensol/entool/components/form/Installation/BatteryInstallation"
import { PhotovoltaicInstallation } from "@ensol/entool/components/form/Installation/PhotovoltaicInstallation"
import { useUpdateInstallationMutation } from "@ensol/entool/queries/installations"
import { getSubformActions } from "@ensol/entool/utils/form/subforms"
import { isFormValid } from "@ensol/entool/utils/form/validation"

type Props = {
  installation: InstallationsResponses.Installation
}

export const UpdateInstallationStep = ({
  installation,
  project,
  actions,
  children,
  ...props
}: Props & StepActionsProps) => {
  const {
    house,
    photovoltaicInstallation,
    batteryInstallation,
    evChargerInstallation,
  } = installation
  const { mutateAsync: saveInstallation } =
    useUpdateInstallationMutation<UpdateInstallationStepInput>(
      installation.id,
      () =>
        queryClient.invalidateQueries({ queryKey: ["projects", project.id] }),
    )

  const form = useForm<UpdateInstallationStepInput>({
    validateInputOnChange: true,
    validate: zodResolver(updateInstallationStepSchema),
    initialValues: {
      updateType: InstallationUpdateType.TECHNICAL_VISIT,
      photovoltaicInstallation,
      batteryInstallation,
      evChargerInstallation,
    },
  })

  return (
    <StepActions
      project={project}
      actions={
        form.isDirty()
          ? [
              {
                action: "validate",
                label: "Enregistrer les modifications",
                isStepCompletion: false,
                onClick: async () => {
                  form.validate()
                  if (!isFormValid(form)) {
                    throw new EnsolError("L'installation n'est pas valide")
                  }
                  await saveInstallation(form.values)
                  form.resetDirty()
                },
                Icon: IconDeviceFloppy,
              },
            ]
          : actions
      }
      {...props}
    >
      {children}
      <Stack align="flex-start" gap="xl" w="100%">
        <Group w="100%" justify="space-between" align="flex-start">
          <Stack w="100%">
            <HouseSection house={house} titleOrder={3} />
            <PhotovoltaicInstallation
              photovoltaicInstallation={form.values.photovoltaicInstallation}
              house={installation.house}
              isNewInstallation={false}
              totalDiscount={computeTotalDiscount(installation.discount)}
              {...getSubformActions(form, "photovoltaicInstallation")}
            />
            <BatteryInstallation
              batteryInstallation={form.values.batteryInstallation}
              inverterType={form.values.photovoltaicInstallation?.inverterType}
              {...getSubformActions(form, "batteryInstallation")}
            />
          </Stack>
        </Group>
      </Stack>
    </StepActions>
  )
}
