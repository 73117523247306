import { Loader } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { useEffect, useMemo } from "react"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { SalesUpdateInstallationInput } from "@ensol/types/forms/installations"
import { ProductionDataSource } from "@ensol/types/simulation"

import { getCoords } from "@ensol/shared/entities/houses/coords"
import { hasPhotovoltaicInstallation } from "@ensol/shared/entities/installations/characteristics"
import { formatNumber } from "@ensol/shared/utils/format"

import { Field } from "@ensol/entool/components/form/Field"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { useEnergyProductionPerDataSource } from "@ensol/entool/queries/energy"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { PRODUCTION_DATA_SOURCE_OPTIONS } from "@ensol/entool/utils/installations/options"

type Props<Values> = {
  form: UseFormReturnType<Values>
  installationValues: SalesUpdateInstallationInput
  house: HousesResponses.House<{
    include: {
      switchgridConsent: true
      switchgridOrder: true
      roofSections: true
    }
  }>
}

export const ProductionParameters = <
  Values extends {
    productionDataSource: ProductionDataSource
  },
>({
  form,
  installationValues,
  house,
}: Props<Values>) => {
  const {
    data: energyProductionPerDataSource,
    isPending: isEnergyProductionLoading,
  } = useEnergyProductionPerDataSource({
    coords: getCoords(house),
    installation: installationValues,
  })

  const productionDataSourceOptions = useMemo(() => {
    const photovoltaicInstallation = installationValues.photovoltaicInstallation
    const roofSectionsCount =
      photovoltaicInstallation?.roofSectionsWithPanels.filter(
        ({ panelsCount }) => panelsCount > 0,
      ).length ?? 0

    if (!hasPhotovoltaicInstallation(installationValues)) {
      return PRODUCTION_DATA_SOURCE_OPTIONS
    }

    return PRODUCTION_DATA_SOURCE_OPTIONS.map((option) => {
      const energyProduction = energyProductionPerDataSource?.[option.value]

      if (
        option.value === ProductionDataSource.GOOGLE &&
        roofSectionsCount > 1
      ) {
        return {
          ...option,
          disabled: true,
          subtitle: "Un seul pan de toit autorisé",
        }
      }

      return {
        ...option,
        disabled: !isEnergyProductionLoading && !energyProduction,
        subtitle: isEnergyProductionLoading ? (
          <Loader size={24} type="dots" />
        ) : energyProduction ? (
          `Production: ${formatNumber(energyProduction)} kWh/an`
        ) : (
          "Indisponible"
        ),
      }
    })
  }, [
    installationValues,
    energyProductionPerDataSource,
    isEnergyProductionLoading,
  ])

  useEffect(() => {
    if (
      findSelectedOption(
        form.values.productionDataSource,
        productionDataSourceOptions,
      )?.disabled
    ) {
      form.setFieldError("productionDataSource", "Source invalide")
    } else {
      form.clearFieldError("productionDataSource")
    }
  }, [form, productionDataSourceOptions])

  return (
    <Field name="Source des données de production">
      <RadioGroup
        options={productionDataSourceOptions}
        {...form.getInputProps("productionDataSource")}
      />
    </Field>
  )
}
