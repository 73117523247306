import * as z from "zod"

import { submittedStepFormSchema } from "@ensol/types/forms/projects/consuel"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { CONSUEL_PROPERTIES } from "@ensol/entool/pages/OpsProject/processes/Consuel/config"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"
import { validateAction } from "@ensol/entool/utils/projects/validateAction"

import { ConsuelForm } from "./components/ConsuelForm"

export const ComplianceCertificateSigned = ({
  project,
  processId,
}: StepComponentProps) => (
  <ConsuelForm
    schema={submittedStepFormSchema}
    initialValues={{
      ...getInitialValues(submittedStepFormSchema, project),
      ...pickDefinedProperties(CONSUEL_PROPERTIES, project),
    }}
    project={project}
    processId={processId}
    actions={[
      {
        action: "validate",
        label: "Valider le Consuel",
        validateAction: validateAction(
          z.object({
            consuelApprovalDate: z.date({
              invalid_type_error: "Champ obligatoire",
            }),
            consuelCertificatePath: z.string({
              invalid_type_error: "Champ obligatoire",
            }),
          }),
        ),
      },
    ]}
  />
)
