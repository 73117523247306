import {
  getInverter,
  InverterBrand,
  InverterType,
} from "./photovoltaic/inverters"

type BatteryInfo = {
  name: string
  brand: InverterBrand
  capacity: number
  maxOutput: number
  installationCost: number
  price: number
  warranty: number
  techDocUrl: string
  disabled: boolean
}

export const BatteryType = {
  HUAWEI_5KWH: "HUAWEI_5KWH",
  HUAWEI_10KWH: "HUAWEI_10KWH",
  HUAWEI_15KWH: "HUAWEI_15KWH",
  HUAWEI_7KWH: "HUAWEI_7KWH",
  HUAWEI_14KWH: "HUAWEI_14KWH",
  HUAWEI_21KWH: "HUAWEI_21KWH",
  ENPHASE_3T: "ENPHASE_3T",
  ENPHASE_10T: "ENPHASE_10T",
  ENPHASE_5P: "ENPHASE_5P",
  ENPHASE_5P_2: "ENPHASE_5P_2",
  ENPHASE_5P_3: "ENPHASE_5P_3",
} as const

export type BatteryType = keyof typeof BatteryType

export const BATTERIES: Record<BatteryType, BatteryInfo> = {
  HUAWEI_5KWH: {
    name: "Huawei LUNA2000 5kWh",
    brand: "Huawei",
    capacity: 5,
    maxOutput: 2.5,
    installationCost: 500,
    price: 2836.55,
    warranty: 10,
    techDocUrl:
      "https://solar.huawei.com/en-GB/download?p=%2F-%2Fmedia%2FSolar%2Fattachment%2Fpdf%2Ffr%2Fdatasheet%2FLUNA2000-5-15-S0.pdf",
    disabled: true,
  },
  HUAWEI_10KWH: {
    name: "Huawei LUNA2000 10kWh",
    brand: "Huawei",
    capacity: 10,
    maxOutput: 5,
    installationCost: 500,
    price: 4862.52,
    warranty: 10,
    techDocUrl:
      "https://solar.huawei.com/en-GB/download?p=%2F-%2Fmedia%2FSolar%2Fattachment%2Fpdf%2Ffr%2Fdatasheet%2FLUNA2000-5-15-S0.pdf",
    disabled: true,
  },
  HUAWEI_15KWH: {
    name: "Huawei LUNA2000 15kWh",
    brand: "Huawei",
    capacity: 15,
    maxOutput: 5,
    installationCost: 500,
    price: 6888.49,
    warranty: 10,
    techDocUrl:
      "https://solar.huawei.com/en-GB/download?p=%2F-%2Fmedia%2FSolar%2Fattachment%2Fpdf%2Ffr%2Fdatasheet%2FLUNA2000-5-15-S0.pdf",
    disabled: true,
  },
  HUAWEI_7KWH: {
    name: "Huawei LUNA2000 S1 7kWh",
    brand: "Huawei",
    capacity: 6.9,
    maxOutput: 3.5,
    installationCost: 500,
    price: 3308.92,
    warranty: 15,
    techDocUrl:
      "https://solar.huawei.com/be-fr/professionals/all-products/LUNA2000-7-14-21-S1/specs",
    disabled: false,
  },
  HUAWEI_14KWH: {
    name: "Huawei LUNA2000 S1 14kWh",
    brand: "Huawei",
    capacity: 13.8,
    maxOutput: 3.5,
    installationCost: 500,
    price: 5674.19,
    warranty: 15,
    techDocUrl:
      "https://solar.huawei.com/be-fr/professionals/all-products/LUNA2000-7-14-21-S1/specs",
    disabled: false,
  },
  HUAWEI_21KWH: {
    name: "Huawei LUNA2000 S1 21kWh",
    brand: "Huawei",
    capacity: 20.7,
    maxOutput: 10.5,
    installationCost: 500,
    price: 8039.46,
    warranty: 15,
    techDocUrl:
      "https://solar.huawei.com/be-fr/professionals/all-products/LUNA2000-7-14-21-S1/specs",
    disabled: false,
  },
  ENPHASE_3T: {
    name: "Enphase 3T",
    brand: "Enphase",
    capacity: 3.36,
    maxOutput: 1.28,
    installationCost: 500,
    price: 2202.59,
    warranty: 15,
    techDocUrl:
      "https://enphase.com/sites/default/files/2021-08/Enphase-DS-Encharge-3T-FR.pdf",
    disabled: true,
  },
  ENPHASE_10T: {
    name: "Enphase 10T",
    brand: "Enphase",
    capacity: 10.08,
    maxOutput: 3.84,
    installationCost: 500,
    price: 5982.03,
    warranty: 15,
    techDocUrl:
      "https://enphase.com/sites/default/files/2021-08/Enphase-DS-Encharge-10T-FR.pdf",
    disabled: true,
  },
  ENPHASE_5P: {
    name: "Enphase 5P",
    brand: "Enphase",
    capacity: 5,
    maxOutput: 3840,
    installationCost: 500,
    price: 3102.25,
    warranty: 15,
    techDocUrl:
      "https://enphase.com/fr-fr/download/iq-battery-5p-fiche-technique",
    disabled: false,
  },
  ENPHASE_5P_2: {
    name: "Enphase 5P×2",
    brand: "Enphase",
    capacity: 10,
    maxOutput: 7680,
    installationCost: 500,
    price: 6204.5,
    warranty: 15,
    techDocUrl:
      "https://enphase.com/fr-fr/download/iq-battery-5p-fiche-technique",
    disabled: false,
  },
  ENPHASE_5P_3: {
    name: "Enphase 5P×3",
    brand: "Enphase",
    capacity: 15,
    maxOutput: 11520,
    installationCost: 500,
    price: 9306.75,
    warranty: 15,
    techDocUrl:
      "https://enphase.com/fr-fr/download/iq-battery-5p-fiche-technique",
    disabled: false,
  },
}

export const getBattery = (batteryType: BatteryType): BatteryInfo =>
  BATTERIES[batteryType]

export const findBestBatteryForConfig = (
  inverterType: InverterType,
): BatteryType => {
  const inverter = getInverter(inverterType)
  return inverter.brand === "Enphase"
    ? BatteryType.ENPHASE_3T
    : BatteryType.HUAWEI_5KWH
}
