import { beforeM1FormSchema } from "@ensol/types/forms/projects/followUp"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { AfterConsentForm } from "@ensol/entool/pages/FollowUpProject/steps/components/AfterConsentForm"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

export const J7ToSend = ({ project, ...props }: StepComponentProps) => (
  <AfterConsentForm
    schema={beforeM1FormSchema}
    initialValues={getInitialValues(beforeM1FormSchema, project)}
    project={project}
    actions={[{ action: "validate", label: "M+1 à envoyer" }]}
    {...props}
  />
)
