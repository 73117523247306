import { useUser } from "@clerk/clerk-react"
import { AppShell as BaseAppShell, Avatar, Group, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import * as Sentry from "@sentry/react"
import { useEffect } from "react"
import { Link, Outlet } from "react-router-dom"

import { Logo } from "@ensol/entool/components/layout/Logo"
import { Menu } from "@ensol/entool/components/layout/Menu"
import { NAVBAR_HEIGHT_IN_PX } from "@ensol/entool/styles/constants"
import { devices } from "@ensol/entool/styles/theme"

export const AppShell = () => {
  const isMobile = useMediaQuery(devices.sm)
  const { isLoaded, isSignedIn, user } = useUser()

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      Sentry.setUser({
        id: user.id,
        email: user.emailAddresses[0].emailAddress,
      })
    }
  }, [isLoaded, isSignedIn, user])

  return (
    <BaseAppShell header={{ height: NAVBAR_HEIGHT_IN_PX }} padding="0">
      <BaseAppShell.Header>
        <Group
          h="100%"
          px="lg"
          justify="space-between"
          bg={
            import.meta.env.VITE_ENVIRONMENT !== "production"
              ? "orange.2"
              : "white"
          }
        >
          <Group>
            <Link to="/">
              <Logo height={isMobile ? 24 : 28} />
            </Link>
            <Text mt={16} fz={isMobile ? "sm" : "md"}>
              Entool
            </Text>
          </Group>
          <Group h="100%" gap="0">
            <Menu />
            {isLoaded && isSignedIn && (
              <Group gap={8}>
                <Avatar src={user.imageUrl} />
                {!isMobile && <Text>{user.fullName}</Text>}
              </Group>
            )}
          </Group>
        </Group>
      </BaseAppShell.Header>

      <BaseAppShell.Main display="flex" mah="100dvh">
        <Outlet />
      </BaseAppShell.Main>
    </BaseAppShell>
  )
}
