import { Avatar, Group, Stack, TextInput } from "@mantine/core"
import { useLocalStorage, useMediaQuery } from "@mantine/hooks"
import { IconSearch } from "@tabler/icons-react"
import _ from "lodash"
import { useState } from "react"

import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"
import { Team } from "@ensol/shared/entities/users"

import { ProjectCard } from "@ensol/entool/components/entities/Project/Card"
import {
  ColumnWrapper,
  Column,
} from "@ensol/entool/components/entities/Project/Column"
import { UserSelect } from "@ensol/entool/components/form/UserSelect"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useListProjectsQuery } from "@ensol/entool/queries/projects"
import { CONTENT_PADDING_IN_PX } from "@ensol/entool/styles/constants"
import { devices } from "@ensol/entool/styles/theme"

import { SALES_TASKS_VIEWS } from "./config"

export const SalesTasks = () => {
  const isMobile = useMediaQuery(devices.sm)

  const [salesOwnerId, setSalesOwnerId, clearSalesOwnerId] = useLocalStorage({
    key: "salesOwnerId",
  })
  const [search, setSearch] = useState("")
  const projectsQuery = useListProjectsQuery({
    filters: {
      search,
      salesOwnerId,
      statuses: [
        { status: ProjectStatus.CREATED, isIncluded: false },
        { status: ProjectStatus.ABORTED, isIncluded: false },
      ],
    },
    enabled:
      isMobile !== undefined &&
      (isMobile ? search !== "" || salesOwnerId !== null : true),
  })

  return (
    <Stack flex="1" miw="0">
      <Stack mb={{ base: 12, md: 24 }} px={CONTENT_PADDING_IN_PX} maw="100dvw">
        <Group wrap="nowrap" align="flex-end">
          <TextInput
            flex="1"
            leftSectionPointerEvents="none"
            leftSection={<IconSearch />}
            placeholder="Rechercher un projet par nom, email, téléphone, ou adresse"
            onChange={_.debounce((e) => setSearch(e.target.value), 250)}
          />
          <UserSelect
            teams={[Team.ACCOUNT_EXECUTIVE, Team.INSIDE_SALES]}
            w={{ base: 150, md: 250 }}
            withDefault={false}
            value={salesOwnerId}
            onChange={(value) =>
              value !== null ? setSalesOwnerId(value) : clearSalesOwnerId()
            }
            label="Responsable"
            clearable
          />
        </Group>
      </Stack>
      <QueryWrapper
        query={projectsQuery}
        disabledQueryMessage="Entrez le nom, le téléphone ou l'email d'un client pour commencer"
      >
        {({ data }) => (
          <ColumnWrapper>
            {SALES_TASKS_VIEWS.map(({ name, filter, getLink, Component }) => {
              const projects = data.filter(filter)
              return (
                <Column
                  key={name}
                  backgroundColor="blue"
                  title={name}
                  titleColor="blue"
                  count={projects.length}
                >
                  {projects.map((project) => (
                    <ProjectCard
                      key={project.id}
                      project={project}
                      shouldDisplayDaysSinceLastEvent={false}
                      lastEventDate={undefined}
                      warnDelayInDays={undefined}
                      to={getLink(project)}
                    >
                      <Avatar
                        pos="absolute"
                        right={12}
                        top={12}
                        size="32"
                        src={project.salesOwner?.avatarUrl}
                      />
                      <Component project={project} />
                    </ProjectCard>
                  ))}
                </Column>
              )
            })}
          </ColumnWrapper>
        )}
      </QueryWrapper>
    </Stack>
  )
}
