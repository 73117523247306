import { MultiSelect, Select, SimpleGrid, Stack, Textarea } from "@mantine/core"
import { DateTimePicker } from "@mantine/dates"
import {
  IconAdjustmentsAlt,
  IconBarrierBlock,
  IconCalendarX,
  IconListCheck,
  IconMap,
  IconSolarPanel,
} from "@tabler/icons-react"

import { TechnicalVisitStepInput } from "@ensol/types/forms/projects/technicalVisit"

import { getInstallationCharacteristicsTextSummary } from "@ensol/shared/entities/installations/characteristics"
import {
  TechnicalVisitStep,
  getStepIndex,
} from "@ensol/shared/entities/projects/processes"
import { getProspectFileName } from "@ensol/shared/utils/files"
import {
  DISPLAY_DATE_FORMAT,
  DISPLAY_DATE_TIME_FORMAT,
} from "@ensol/shared/utils/format"

import { CopiableText } from "@ensol/entool/components/CopiableText"
import { Section } from "@ensol/entool/components/Section"
import { FormCheckbox } from "@ensol/entool/components/entities/Project/FormCheckbox"
import { FormField } from "@ensol/entool/components/entities/Project/FormField"
import {
  StepActions,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { Field } from "@ensol/entool/components/form/Field"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { MultiFileField } from "@ensol/entool/components/form/File/MultiFileField"
import { UpdateRoofType } from "@ensol/entool/components/form/House/UpdateRoofType"
import { NumberInput } from "@ensol/entool/components/form/NumberInput"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useInstallersOptions } from "@ensol/entool/queries/installers"
import { booleanOptions } from "@ensol/entool/utils/form/options"
import {
  SCHEDULING_ISSUES_OPTIONS,
  INTEGRATION_KITS_OPTIONS,
  TECHNICAL_VISIT_DIFFICULTY_OPTIONS,
  TECHNICAL_VISIT_VALIDATION_STATUSES_OPTIONS,
} from "@ensol/entool/utils/projects/options"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const TechnicalVisitForm = <Input extends TechnicalVisitStepInput>({
  schema,
  initialValues,
  project,
  children,
  ...props
}: ProcessFormProps<Input> & StepActionsProps) => {
  const form = useProcessForm<Input>({
    schema,
    initialValues,
    project,
    url: "technical-visit",
  })
  const installersOptions = useInstallersOptions()
  const installationCharacteristicsSummary =
    getInstallationCharacteristicsTextSummary(project.installation)

  const { prospect } = project.installation.house
  const currentStep = project[props.processId]

  const isScheduled =
    getStepIndex(currentStep, "technicalVisitStep") >=
    getStepIndex(TechnicalVisitStep.SCHEDULED, "technicalVisitStep")

  return (
    <StepActions
      project={project}
      validateStep={() => schema.parse(form.values)}
      {...props}
    >
      {"technicalVisitEndOfWorksDate" in initialValues && (
        <Section title="Travaux" icon={IconBarrierBlock} titleOrder={4}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitEndOfWorksDate"
              isRequired
            >
              <DateInput
                w={FIELD_WIDTH}
                valueFormat={DISPLAY_DATE_FORMAT}
                clearable
                {...form.getInputProps("technicalVisitEndOfWorksDate")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitWorksPhotos"
            >
              <MultiFileField
                previewProps={{
                  fileName: getProspectFileName(
                    "photos-travaux-post-VT",
                    prospect,
                  ),
                }}
                inputProps={{
                  accept: ["image/png", "image/jpeg", "image/heic"],
                }}
                {...form.getInputProps("technicalVisitWorksPhotos")}
              />
            </FormField>
          </SimpleGrid>
        </Section>
      )}
      {currentStep === TechnicalVisitStep.SCHEDULED && (
        <Section title="Vérifications" icon={IconListCheck} titleOrder={4}>
          <FormCheckbox
            initialValues={initialValues}
            propertyName="isTechnicalVisitReady"
            {...form.getInputProps("isTechnicalVisitReady")}
          />
        </Section>
      )}
      {(currentStep === TechnicalVisitStep.SCHEDULING_ON_HOLD ||
        currentStep === TechnicalVisitStep.CANNOT_SCHEDULE) && (
        <Section title="Non planifiable" icon={IconCalendarX} titleOrder={4}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="technicalVisitSchedulingHoldEndDate"
              >
                <DateInput
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_FORMAT}
                  clearable
                  {...form.getInputProps("technicalVisitSchedulingHoldEndDate")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="technicalVisitLastContactDate"
              >
                <DateInput
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_FORMAT}
                  clearable
                  {...form.getInputProps("technicalVisitLastContactDate")}
                />
              </FormField>
            </Stack>
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="technicalVisitSchedulingIssues"
                isRequired
              >
                <MultiSelect
                  w={FIELD_WIDTH}
                  searchable
                  hidePickedOptions
                  data={SCHEDULING_ISSUES_OPTIONS}
                  {...form.getInputProps("technicalVisitSchedulingIssues")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="technicalVisitSchedulingIssuesNotes"
              >
                <Textarea
                  autosize
                  w={FIELD_WIDTH}
                  minRows={3}
                  {...form.getInputProps("technicalVisitSchedulingIssuesNotes")}
                />
              </FormField>
            </Stack>
          </SimpleGrid>
        </Section>
      )}
      <Section
        title={
          isScheduled
            ? "Gestion visite technique"
            : "Planification de la visite technique"
        }
        icon={IconAdjustmentsAlt}
        titleOrder={4}
      >
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitStartDate"
              isRequired
            >
              <DateTimePicker
                w={FIELD_WIDTH}
                valueFormat={DISPLAY_DATE_TIME_FORMAT}
                clearable
                {...form.getInputProps("technicalVisitStartDate")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitExpectedValidationDate"
            >
              <DateInput
                w={FIELD_WIDTH}
                valueFormat={DISPLAY_DATE_FORMAT}
                clearable
                {...form.getInputProps("technicalVisitExpectedValidationDate")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitValidationStatus"
            >
              <MultiSelect
                w={FIELD_WIDTH}
                searchable
                hidePickedOptions
                data={TECHNICAL_VISIT_VALIDATION_STATUSES_OPTIONS}
                {...form.getInputProps("technicalVisitValidationStatus")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitReportsPath"
              isRequired
            >
              <MultiFileField
                previewProps={{
                  fileName: getProspectFileName("compte-rendu-VT", prospect),
                }}
                inputProps={{ accept: ["application/pdf"] }}
                {...form.getInputProps("technicalVisitReportsPath")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitReportReceivedDate"
              isRequired
            >
              <DateInput
                w={FIELD_WIDTH}
                valueFormat={DISPLAY_DATE_FORMAT}
                clearable
                {...form.getInputProps("technicalVisitReportReceivedDate")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitInstallerId"
              isRequired
            >
              <Select
                w={FIELD_WIDTH}
                data={installersOptions}
                {...form.getInputProps("technicalVisitInstallerId")}
              />
            </FormField>
            <FormField initialValues={initialValues} propertyName="installerId">
              <Select
                w={FIELD_WIDTH}
                data={installersOptions}
                {...form.getInputProps("installerId")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="installationEstimatedDuration"
              isRequired
            >
              <NumberInput
                w={FIELD_WIDTH}
                suffix=" jours"
                {...form.getInputProps("installationEstimatedDuration")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitNotes"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("technicalVisitNotes")}
              />
            </FormField>
            {!isScheduled && (
              <Field name="Informations installation" noBorder>
                <CopiableText
                  text={installationCharacteristicsSummary}
                  w={FIELD_WIDTH}
                />
              </Field>
            )}
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitDifficulty"
            >
              <RadioGroup
                options={TECHNICAL_VISIT_DIFFICULTY_OPTIONS}
                {...form.getInputProps("technicalVisitDifficulty")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitNextSteps"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("technicalVisitNextSteps")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitClientComments"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("technicalVisitClientComments")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitInternalComments"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("technicalVisitInternalComments")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="installationNotes"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("installationNotes")}
              />
            </FormField>
          </Stack>
        </SimpleGrid>
      </Section>
      {"technicalVisitK2ReportsPath" in initialValues && (
        <Section title="Calepinages" icon={IconMap} titleOrder={4}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="technicalVisitK2ReportsPath"
              >
                <MultiFileField
                  previewProps={{
                    fileName: getProspectFileName("rapport-k2-VT", prospect),
                  }}
                  inputProps={{
                    accept: {
                      "application/pdf": [".pdf"],
                      "application/octet-stream": [".k2o"],
                    },
                  }}
                  {...form.getInputProps("technicalVisitK2ReportsPath")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="technicalVisitPanelsLayoutPath"
              >
                <FileField
                  previewProps={{
                    fileName: getProspectFileName("calepinage-VT", prospect),
                  }}
                  inputProps={{ accept: ["image/png", "image/jpeg"] }}
                  {...form.getInputProps("technicalVisitPanelsLayoutPath")}
                />
              </FormField>
            </Stack>
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="salesVisitK2ReportPath"
              >
                <FileField
                  previewProps={{
                    fileName: getProspectFileName("rapport-K2-VC", prospect),
                  }}
                  inputProps={{
                    accept: {
                      "application/pdf": [".pdf"],
                    },
                  }}
                  {...form.getInputProps("salesVisitK2ReportPath")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="salesVisitPanelsLayoutPath"
              >
                <FileField
                  previewProps={{
                    fileName: getProspectFileName("calepinage-VC", prospect),
                  }}
                  inputProps={{ accept: ["image/png", "image/jpeg"] }}
                  {...form.getInputProps("salesVisitPanelsLayoutPath")}
                />
              </FormField>
            </Stack>
          </SimpleGrid>
        </Section>
      )}
      <Section title="Matériel" icon={IconSolarPanel} titleOrder={4}>
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <UpdateRoofType house={project.installation.house} />
            <FormField
              initialValues={initialValues}
              propertyName="integrationKitType"
            >
              <Select
                w={FIELD_WIDTH}
                data={INTEGRATION_KITS_OPTIONS}
                {...form.getInputProps("integrationKitType")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="isPLCNeeded"
              isRequired
            >
              <RadioGroup
                options={booleanOptions}
                {...form.getInputProps("isPLCNeeded")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="hardwareNotes"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("hardwareNotes")}
              />
            </FormField>
          </Stack>
        </SimpleGrid>
      </Section>
      {children}
    </StepActions>
  )
}
