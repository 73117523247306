import { EdfOaStep } from "@ensol/shared/entities/projects/processes"

import { StepComponentGetter } from "@ensol/entool/components/entities/Project/ProcessView"

import { EmailSent } from "./1-EmailSent"
import { CertificateToSend } from "./2-CertificateToSend"
import { CertificateSent } from "./3-CertificateSent"
import { CertificateSigned } from "./4-CertificateSigned"

export const getEdfOaStepComponent: StepComponentGetter = (currentStep) => {
  switch (currentStep) {
    case EdfOaStep.EMAIL_SENT:
      return { StepComponent: EmailSent, isCancellable: false }
    case EdfOaStep.CERTIFICATE_TO_SEND:
      return { StepComponent: CertificateToSend }
    case EdfOaStep.CERTIFICATE_SENT:
      return { StepComponent: CertificateSent }
    case EdfOaStep.CERTIFICATE_SIGNED:
      return { StepComponent: CertificateSigned }
    default:
      return { StepComponent: null }
  }
}
