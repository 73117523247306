import { Tabs } from "@mantine/core"
import { IconSun } from "@tabler/icons-react"
import { Helmet } from "react-helmet-async"

import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"

import { PageLayout } from "@ensol/entool/components/layout/PageLayout"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { CONTENT_PADDING_IN_PX } from "@ensol/entool/styles/constants"
import { useTabsNavigation } from "@ensol/entool/utils/helpers/useTabsNavigation"
import {
  PROJECT_STATUSES,
  STATUSES_COLOR,
  STATUSES_VIEW_ID,
} from "@ensol/entool/utils/projects/statuses"
import { PROJECTS_PROCESS_VIEWS } from "@ensol/entool/utils/projects/views"

import { ProjectSearch } from "./Search"
import { View } from "./View"
import { PROJECT_PROCESS_LIST_VIEWS } from "./config"
import { Overview } from "./processes/Overview"
import { useProjects } from "./useProjects"

export const OpsProjectsPage = () => {
  const { currentTab, handleTabChange } = useTabsNavigation([
    { id: STATUSES_VIEW_ID },
    ...PROJECTS_PROCESS_VIEWS,
  ])
  const {
    projectsQuery,
    isSearching,
    handleSearchChange,
    handleInstallerChange,
    handleSupplierChange,
    salesOwnerId,
    handleSalesOwnerChange,
    operationsOwnerId,
    handleOperationsOwnerChange,
    adminOwnerId,
    handleAdminOwnerChange,
  } = useProjects()

  return (
    <PageLayout title="Operations" contentContainerProps={{ px: 0 }}>
      <Helmet>
        <title>Liste des Operations | Entool</title>
      </Helmet>
      <Tabs
        value={currentTab}
        variant="outline"
        onChange={handleTabChange}
        styles={{
          root: {
            display: "flex",
            flexDirection: "column",
            flex: 1,
            minHeight: 0,
            minWidth: 0,
          },
          panel: { display: "flex" },
        }}
        keepMounted={false}
      >
        <Tabs.List
          px={CONTENT_PADDING_IN_PX}
          style={{ overflowX: "auto", flexWrap: "nowrap" }}
        >
          <Tabs.Tab
            size="xs"
            value={STATUSES_VIEW_ID}
            leftSection={<IconSun />}
          >
            Projets
          </Tabs.Tab>
          {PROJECTS_PROCESS_VIEWS.map(({ id, name }) => (
            <Tabs.Tab key={id} value={id}>
              {name}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        <ProjectSearch
          handleSearchChange={handleSearchChange}
          handleInstallerChange={handleInstallerChange}
          handleSupplierChange={handleSupplierChange}
          salesOwnerId={salesOwnerId}
          handleSalesOwnerChange={handleSalesOwnerChange}
          operationsOwnerId={operationsOwnerId}
          handleOperationsOwnerChange={handleOperationsOwnerChange}
          adminOwnerId={adminOwnerId}
          handleAdminOwnerChange={handleAdminOwnerChange}
        />
        <QueryWrapper
          query={projectsQuery}
          disabledQueryMessage="Entrez le nom, le téléphone ou l'email d'un client pour commencer"
        >
          {({ data }) => (
            <>
              <Tabs.Panel flex="1" mih="0" value={STATUSES_VIEW_ID}>
                <View
                  projects={data}
                  propertyName={STATUSES_VIEW_ID}
                  milestonesConfig={PROJECT_STATUSES}
                  doneMilestone={ProjectStatus.DONE}
                  abortedMilestones={[ProjectStatus.ABORTED]}
                  warnDelayInDays={30}
                  currentTab={currentTab}
                  viewColor={STATUSES_COLOR}
                  isSearching={isSearching}
                  Component={Overview}
                  orderBy={[[], []]}
                />
              </Tabs.Panel>
              {PROJECT_PROCESS_LIST_VIEWS.map(
                ({
                  id,
                  color,
                  milestones,
                  doneMilestone,
                  abortedMilestones,
                  warnDelayInDays,
                  Component,
                  orderBy,
                }) => (
                  <Tabs.Panel key={id} flex="1" mih="0" value={id}>
                    <View
                      projects={data.filter((project) => {
                        const currentStep = project[id]

                        return (
                          project.status !== ProjectStatus.ABORTED ||
                          (currentStep !== null &&
                            abortedMilestones?.includes(currentStep))
                        )
                      })}
                      propertyName={id}
                      milestonesConfig={milestones}
                      doneMilestone={doneMilestone}
                      abortedMilestones={abortedMilestones}
                      warnDelayInDays={warnDelayInDays}
                      currentTab={currentTab}
                      viewColor={color}
                      isSearching={isSearching}
                      orderBy={orderBy}
                      Component={Component}
                    />
                  </Tabs.Panel>
                ),
              )}
            </>
          )}
        </QueryWrapper>
      </Tabs>
    </PageLayout>
  )
}
