import { Stack, TextInput } from "@mantine/core"
import { IconSearch } from "@tabler/icons-react"
import _ from "lodash"
import { useState } from "react"
import { Helmet } from "react-helmet-async"

import { ListProspectsFilters } from "@ensol/types/forms/prospects"

import { PageLayout } from "@ensol/entool/components/layout/PageLayout"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { ProspectsList } from "@ensol/entool/pages/Prospects/ProspectsList"
import { useListProspectsQuery } from "@ensol/entool/queries/prospects"

export const ProspectsPage = () => {
  const [search, setSearch] = useState<string>("")
  const [orderBy, setOrderBy] = useState<
    NonNullable<ListProspectsFilters["orderBy"]>
  >({
    field: "createdAt",
    direction: "desc",
  })

  const prospectsQuery = useListProspectsQuery({ filters: { search, orderBy } })

  const handleOrderChange = (
    field: NonNullable<ListProspectsFilters["orderBy"]>["field"],
  ) => {
    setOrderBy({
      field,
      direction:
        orderBy.field === field
          ? orderBy.direction === "asc"
            ? "desc"
            : "asc"
          : "asc",
    })
  }
  return (
    <PageLayout title="Prospects">
      <Helmet>
        <title>Prospects | Entool</title>
      </Helmet>
      <Stack flex="1" mt="24">
        <TextInput
          mb={8}
          leftSectionPointerEvents="none"
          leftSection={<IconSearch />}
          placeholder="Rechercher un prospect par nom, email ou téléphone"
          onChange={_.debounce((e) => setSearch(e.target.value), 250)}
        />
        <QueryWrapper query={prospectsQuery}>
          {({ data }) => (
            <ProspectsList
              prospects={data}
              orderBy={orderBy}
              onOrderChange={handleOrderChange}
            />
          )}
        </QueryWrapper>
      </Stack>
    </PageLayout>
  )
}
