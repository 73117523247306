import {
  PreliminaryRequestStep,
  ProcessStep,
  TechnicalVisitStep,
  ConsuelStep,
  EnedisStep,
  HardwareStep,
  InstallationStep,
  PaymentStep,
  GreendealStep,
  EdfOaStep,
  FollowUpStep,
  OpsProcessId,
  ProcessId,
} from "@ensol/shared/entities/projects/processes"

import {
  PRELIMINARY_REQUEST_STEPS,
  TECHNICAL_VISIT_STEPS,
  INSTALLATION_STEPS,
  PAYMENT_STEPS,
  HARDWARE_STEPS,
  GREENDEAL_STEPS,
  ENEDIS_STEPS,
  CONSUEL_STEPS,
  SUBSIDIES_STEPS,
  FOLLOW_UP_STEPS,
} from "./processes"
import { ProjectMilestonesConfig } from "./types"

export type ProjectProcessView<T> = {
  id: T
  name: string
  color: string
  warnDelayInDays: number
  milestones: ProjectMilestonesConfig<ProcessStep>
  doneMilestone?: ProcessStep
  abortedMilestones?: ProcessStep[]
}

export const PROJECTS_PROCESS_VIEWS: ProjectProcessView<OpsProcessId>[] = [
  {
    id: "preliminaryRequestStep",
    name: "Demande Préalable",
    color: "blue",
    warnDelayInDays: 30,
    milestones: PRELIMINARY_REQUEST_STEPS,
    doneMilestone: PreliminaryRequestStep.AUTHORISATION_RECEIVED,
    abortedMilestones: [PreliminaryRequestStep.REJECTED],
  },
  {
    id: "technicalVisitStep",
    name: "Visite Technique",
    color: "blue",
    warnDelayInDays: 30,
    milestones: TECHNICAL_VISIT_STEPS,
    doneMilestone: TechnicalVisitStep.VALIDATED,
    abortedMilestones: [TechnicalVisitStep.REJECTED],
  },
  {
    id: "hardwareStep",
    name: "Matériel",
    color: "blue",
    warnDelayInDays: 30,
    milestones: HARDWARE_STEPS,
    doneMilestone: HardwareStep.ORDER_INSTALLED,
  },
  {
    id: "paymentStep",
    name: "Paiements",
    color: "blue",
    warnDelayInDays: 30,
    milestones: PAYMENT_STEPS,
    doneMilestone: PaymentStep.FINAL_INVOICE_PAID,
  },
  {
    id: "installationStep",
    name: "Installation",
    color: "blue",
    warnDelayInDays: 30,
    milestones: INSTALLATION_STEPS,
    doneMilestone: InstallationStep.CERTIFICATE_SIGNED,
  },
  {
    id: "enedisStep",
    name: "Enedis",
    color: "blue",
    warnDelayInDays: 15,
    milestones: ENEDIS_STEPS,
    doneMilestone: EnedisStep.ACTIVATED,
  },
  {
    id: "consuelStep",
    name: "Consuel",
    color: "blue",
    warnDelayInDays: 15,
    milestones: CONSUEL_STEPS,
    doneMilestone: ConsuelStep.VALIDATED,
  },
  {
    id: "greendealStep",
    name: "Green Deal",
    color: "blue",
    warnDelayInDays: 30,
    milestones: GREENDEAL_STEPS,
    doneMilestone: GreendealStep.VALIDATED,
  },
  {
    id: "edfOaStep",
    name: "EDF OA",
    color: "blue",
    warnDelayInDays: 10,
    milestones: SUBSIDIES_STEPS,
    doneMilestone: EdfOaStep.CERTIFICATE_SIGNED,
  },
]

export const FOLLOW_UP_PROCESS_VIEW: ProjectProcessView<ProcessId> = {
  id: "followUpStep",
  name: "Suivi",
  color: "blue",
  warnDelayInDays: 30,
  milestones: FOLLOW_UP_STEPS,
  doneMilestone: FollowUpStep.DONE,
  abortedMilestones: [FollowUpStep.CONSENT_REFUSED, FollowUpStep.M1_IGNORED],
}
