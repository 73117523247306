import { useLocalStorage, useMediaQuery } from "@mantine/hooks"
import _ from "lodash"
import { useState } from "react"

import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"

import { useListProjectsQuery } from "@ensol/entool/queries/projects"
import { devices } from "@ensol/entool/styles/theme"

export const useProjects = () => {
  const isMobile = useMediaQuery(devices.sm)

  const [search, setSearch] = useState("")
  const [installerId, setInstallerId] = useState<string | null>(null)
  const [supplierId, setSupplierId] = useState<string | null>(null)
  const [salesOwnerId, setSalesOwnerId, clearSalesOwnerId] = useLocalStorage({
    key: "salesOwnerId",
  })
  const [operationsOwnerId, setOperationsOwnerId, clearOperationsOwnerId] =
    useLocalStorage({
      key: "operationsOwnerId",
    })
  const [adminOwnerId, setAdminOwnerId, clearAdminOwnerId] = useLocalStorage({
    key: "adminOwnerId",
  })

  const projectsQuery = useListProjectsQuery({
    filters: {
      search,
      installerId: installerId ?? undefined,
      supplierId: supplierId ?? undefined,
      salesOwnerId: salesOwnerId ?? undefined,
      operationsOwnerId: operationsOwnerId ?? undefined,
      adminOwnerId: adminOwnerId ?? undefined,
      statuses: [{ status: ProjectStatus.CREATED, isIncluded: false }],
    },
    enabled: isMobile !== undefined && (isMobile ? search !== "" : true),
  })

  return {
    projectsQuery,
    isSearching: search !== "",
    handleSearchChange: _.debounce((e) => setSearch(e.target.value), 250),
    handleInstallerChange: setInstallerId,
    handleSupplierChange: setSupplierId,
    salesOwnerId,
    handleSalesOwnerChange: (value: string | null) =>
      value !== null ? setSalesOwnerId(value) : clearSalesOwnerId(),
    operationsOwnerId,
    handleOperationsOwnerChange: (value: string | null) =>
      value !== null ? setOperationsOwnerId(value) : clearOperationsOwnerId(),
    adminOwnerId,
    handleAdminOwnerChange: (value: string | null) =>
      value !== null ? setAdminOwnerId(value) : clearAdminOwnerId(),
  }
}
