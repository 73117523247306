import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"

import { ProjectMilestonesConfig } from "./types"

export const PROJECT_STATUSES: ProjectMilestonesConfig<ProjectStatus> = [
  {
    id: ProjectStatus.SIGNED,
    label: "Projet signé",
    color: "blue",
    nodePosition: { x: 0, y: 50 },
    nextSteps: [ProjectStatus.REVIEWED],
  },
  {
    id: ProjectStatus.REVIEWED,
    label: "Projet renseigné",
    color: "blue",
    nodePosition: { x: 200, y: 50 },
    nextSteps: [ProjectStatus.STARTED],
  },
  {
    id: ProjectStatus.STARTED,
    label: "Lancement projet",
    color: "blue",
    nodePosition: { x: 400, y: 50 },
    nextSteps: [
      ProjectStatus.TECHNICALLY_VALIDATED,
      ProjectStatus.ADMINISTRATIVELY_VALIDATED,
    ],
  },
  {
    id: ProjectStatus.TECHNICALLY_VALIDATED,
    color: "blue",
    label: "Validation Technique",
    nodePosition: { x: 600, y: 0 },
    nextSteps: [ProjectStatus.VALIDATED],
  },
  {
    id: ProjectStatus.ADMINISTRATIVELY_VALIDATED,
    label: "Validation Administrative",
    color: "blue",
    nodePosition: { x: 600, y: 100 },
    nextSteps: [ProjectStatus.VALIDATED],
  },
  {
    id: ProjectStatus.VALIDATED,
    label: "Projet validé",
    color: "blue",
    nodePosition: { x: 800, y: 50 },
    nextSteps: [ProjectStatus.DEPOSIT_INVOICE_PAID],
  },
  {
    id: ProjectStatus.DEPOSIT_INVOICE_PAID,
    label: "Acompte payé",
    color: "blue",
    nodePosition: { x: 1000, y: 50 },
    nextSteps: [ProjectStatus.INSTALLATION_DONE],
  },
  {
    id: ProjectStatus.INSTALLATION_DONE,
    label: "Installation réalisée",
    color: "blue",
    nodePosition: { x: 1200, y: 50 },
    nextSteps: [ProjectStatus.FINAL_INVOICE_PAID],
  },
  {
    id: ProjectStatus.FINAL_INVOICE_PAID,
    label: "Facture solde payée",
    color: "blue",
    nodePosition: { x: 1400, y: 50 },
    nextSteps: [ProjectStatus.DONE],
  },
  {
    id: ProjectStatus.DONE,
    label: "Projet terminé",
    color: "green",
    nodePosition: { x: 1600, y: 50 },
    nextSteps: [],
  },
  {
    id: ProjectStatus.ABORTED,
    label: "Projet annulé",
    color: "red",
    nextSteps: [],
  },
]

export const STATUSES_COLOR = "yellow.8"
export const STATUSES_VIEW_ID = "status"
