import { useMemo } from "react"

import { typedKeys } from "@ensol/types/utils"

import { checkInverterCompatibility } from "@ensol/shared/entities/installations/energy"
import { CurrentType } from "@ensol/shared/material/currentType"
import {
  getInverter,
  INVERTERS,
  InverterType,
} from "@ensol/shared/material/photovoltaic/inverters"
import {
  OPTIMIZERS,
  OptimizerType,
} from "@ensol/shared/material/photovoltaic/optimizers"
import { PANELS, PanelType } from "@ensol/shared/material/photovoltaic/panels"

import { Option } from "@ensol/entool/utils/form/radio"

const PANEL_OPTIONS: Option<PanelType>[] = typedKeys(PANELS).map(
  (panelType) => {
    const { name, disabled } = PANELS[panelType]

    return { label: name, value: panelType, disabled }
  },
)

const INVERTER_OPTIONS: Option<InverterType>[] = typedKeys(INVERTERS).map(
  (inverterType) => {
    const { name } = INVERTERS[inverterType]

    return {
      label: name,
      value: inverterType,
    }
  },
)

const OPTIMIZER_OPTIONS: Option<OptimizerType>[] = typedKeys(OPTIMIZERS).map(
  (optimizerType) => {
    const { name } = OPTIMIZERS[optimizerType]

    return {
      label: name,
      value: optimizerType,
    }
  },
)

type PhotovoltaicMaterialOptionsInput = {
  panelsCount: number
  panelType: PanelType
  inverterType: InverterType
  currentType: CurrentType
}

type PhotovoltaicMaterialOptions = {
  panelsOptions: Option<PanelType>[]
  invertersOptions: Option<InverterType>[]
  optimizerOptions: Option<OptimizerType>[]
  minOptimizerCount: number
  maxOptimizerCount: number
}

export const usePhotovoltaicMaterialOptions = ({
  panelsCount,
  panelType,
  inverterType,
  currentType,
}: PhotovoltaicMaterialOptionsInput): PhotovoltaicMaterialOptions => {
  const panelsOptions = useMemo(() => {
    return PANEL_OPTIONS.map((panel) => ({
      ...panel,
      subtitle: panel.disabled && "Ce panneau n'est plus disponible",
    })).filter((panel) => !panel.disabled || panel.value === panelType)
  }, [panelType])

  const invertersOptions = useMemo(() => {
    return INVERTER_OPTIONS.map((inverter) => {
      const { DCACPercent, isCompatible } =
        panelsCount && panelType
          ? checkInverterCompatibility(
              inverter.value,
              panelType,
              panelsCount,
              currentType,
            )
          : { DCACPercent: undefined, isCompatible: false }

      return {
        ...inverter,
        subtitle: DCACPercent ? `Rapport DC/AC: ${DCACPercent}%` : "",
        incompatible: !isCompatible,
        disabled: !isCompatible,
      }
    }).filter(
      (inverter) => !inverter.disabled || inverter.value === inverterType,
    )
  }, [panelsCount, panelType, currentType, inverterType])

  const optimizerOptions = useMemo(() => {
    return OPTIMIZER_OPTIONS.map((optimizer) => {
      const inverter = getInverter(inverterType)
      const disabled = !inverter.isCentralInverter
      return {
        ...optimizer,
        // For now, no need for an isolated helper, but that could be handy later if rules complexify
        disabled,
        incompatible: disabled,
        subtitle: disabled
          ? "Utilisable avec onduleur central uniquement"
          : undefined,
      }
    })
  }, [inverterType])

  return {
    panelsOptions,
    invertersOptions,
    optimizerOptions,
    minOptimizerCount: currentType === CurrentType.SINGLE_PHASE ? 4 : 6,
    maxOptimizerCount: currentType === CurrentType.SINGLE_PHASE ? 25 : 50,
  }
}
