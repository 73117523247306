import { PaymentStep } from "@ensol/shared/entities/projects/processes"

import { StepComponentGetter } from "@ensol/entool/components/entities/Project/ProcessView"

import { DepositWaiting } from "./1-DepositWaiting"
import { DepositToSend } from "./2-DepositToSend"
import { WaitingDeposit } from "./3-WaitingDeposit"
import { DepositPaid } from "./4-DepositPaid"
import { FinalInvoiceToSend } from "./5-FinalInvoiceToSend"
import { WaitingFinalInvoice } from "./6-WaitingFinalInvoice"
import { FinalInvoicePaid } from "./7-FinalInvoicePaid"

export const getPaymentStepComponent: StepComponentGetter = (currentStep) => {
  switch (currentStep) {
    case PaymentStep.DEPOSIT_INVOICE_WAITING:
      return { StepComponent: DepositWaiting, isCancellable: false }
    case PaymentStep.DEPOSIT_INVOICE_TO_SEND:
      return { StepComponent: DepositToSend, isCancellable: false }
    case PaymentStep.WAITING_FOR_DEPOSIT_PAYMENT:
      return { StepComponent: WaitingDeposit }
    case PaymentStep.DEPOSIT_INVOICE_PAID:
      return { StepComponent: DepositPaid }
    case PaymentStep.FINAL_INVOICE_TO_SEND:
      return { StepComponent: FinalInvoiceToSend }
    case PaymentStep.WAITING_FOR_FINAL_PAYMENT:
      return { StepComponent: WaitingFinalInvoice }
    case PaymentStep.FINAL_INVOICE_PAID:
      return { StepComponent: FinalInvoicePaid }
    default:
      return { StepComponent: null }
  }
}
