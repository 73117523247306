import { Stack } from "@mantine/core"

import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import { useGetQuoteQuery } from "@ensol/entool/queries/installations"

type Props = {
  installationId: string
  quoteFileName: string
}

export const QuoteDocument = ({ installationId, quoteFileName }: Props) => {
  const { data: quote } = useGetQuoteQuery(installationId)

  if (quote === undefined || quote.quotePath === null) {
    return null
  }

  return (
    <Stack data-testid="quote-preview" w="100%">
      <MultiFilePreview
        data-testid="quote-preview"
        filesPaths={[quote?.quotePath]}
        fileName={quoteFileName}
        width="350"
      />
    </Stack>
  )
}
