import _ from "lodash"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { computePhotovoltaicInstallationCapacity } from "@ensol/shared/entities/installations/energy"
import { getBattery } from "@ensol/shared/material/batteries"
import { getEvCharger } from "@ensol/shared/material/evCharger"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"
import { getOptimizer } from "@ensol/shared/material/photovoltaic/optimizers"
import { getPanel } from "@ensol/shared/material/photovoltaic/panels"

export type InstallationCharacteristic = {
  label: string
  value: string | null
}

export const computeInstallationCharacteristics = (
  installation: ProjectResponses.Project["installation"],
) => {
  const {
    house,
    photovoltaicInstallation,
    batteryInstallation,
    evChargerInstallation,
  } = installation

  return {
    house: [
      {
        label: "Coordonnées de la maison",
        value: `${house.lat},${house.lng}`,
      },
      {
        label: "Besoin de nacelle",
        value:
          house.isPodNeeded !== null
            ? house.isPodNeeded
              ? "Oui"
              : "Non"
            : null,
      },
      {
        label: "Accès aux combles",
        value:
          house.isAtticAccessible !== null
            ? house.isAtticAccessible
              ? "Oui"
              : "Non"
            : null,
      },
    ],
    photovoltaicInstallation:
      photovoltaicInstallation !== null
        ? computePhotovoltaicInstallationCharacteristics(
            photovoltaicInstallation,
          )
        : [],
    batteryInstallation:
      batteryInstallation !== null
        ? computeBatteryInstallationCharacteristics(batteryInstallation)
        : [],
    evChargerInstallation:
      evChargerInstallation !== null
        ? computeEvChargerInstallationCharacteristics(evChargerInstallation)
        : [],
  }
}

const computePhotovoltaicInstallationCharacteristics = (
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation,
): InstallationCharacteristic[] => {
  const {
    panelType,
    panelsCount,
    inverterType,
    optimizerType,
    optimizerCount,
  } = photovoltaicInstallation

  const panel = getPanel(panelType)
  const inverter = getInverter(inverterType)
  const optimizer = getOptimizer(optimizerType)
  const installedCapacity = computePhotovoltaicInstallationCapacity(
    photovoltaicInstallation,
  )

  return [
    { label: "Capacité", value: `${installedCapacity} kWc` },
    { label: "Panneaux", value: `${panelsCount} × ${panel.name}` },
    { label: "Onduleur", value: inverter.name },
    {
      label: "Optimiseurs",
      value:
        optimizer && optimizerCount
          ? `${optimizerCount} × ${optimizer.name}`
          : null,
    },
  ]
}

const computeBatteryInstallationCharacteristics = (
  batteryInstallation: InstallationsResponses.BatteryInstallation,
): InstallationCharacteristic[] => {
  const battery = getBattery(batteryInstallation.type)

  return [{ label: "Batterie", value: battery.name }]
}

const computeEvChargerInstallationCharacteristics = (
  evChargerInstallation: InstallationsResponses.EvChargerInstallation,
): InstallationCharacteristic[] => {
  const evCharger = getEvCharger(evChargerInstallation.type)

  return [{ label: "Borne", value: evCharger.name }]
}

export const getInstallationCharacteristicsTextSummary = (
  installation: ProjectResponses.Project["installation"],
) =>
  _.chain(computeInstallationCharacteristics(installation))
    .values()
    .flatten()
    .map(
      ({ label, value }: InstallationCharacteristic) =>
        `${label}: ${value !== null ? value : "-"}`,
    )
    .join("\n")
    .value()

export const getInstallationProductsCount = (
  installation: InstallationsResponses.Installation,
) => {
  let productsCount = 0

  if (installation.photovoltaicInstallation !== null) {
    productsCount += 1
  }

  if (installation.batteryInstallation !== null) {
    productsCount += 1
  }

  if (installation.evChargerInstallation !== null) {
    productsCount += 1
  }

  return productsCount
}

export const hasPhotovoltaicInstallation = (installation: {
  photovoltaicInstallation: Pick<
    InstallationsResponses.PhotovoltaicInstallation,
    "roofSectionsWithPanels"
  > | null
}): installation is InstallationsResponses.Installation & {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation
} => {
  return (
    installation.photovoltaicInstallation !== null &&
    installation.photovoltaicInstallation.roofSectionsWithPanels.filter(
      ({ panelsCount }) => panelsCount > 0,
    ).length > 0
  )
}
