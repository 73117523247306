import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { QuoteDetails } from "@ensol/types/quote"

import { computeAllCosts } from "@ensol/shared/entities/installations/costs"
import { computeTotalDiscount } from "@ensol/shared/entities/installations/savings"
import { computeBatteryQuoteDetails } from "@ensol/shared/entities/quotes/details/battery"
import { computeEvChargerQuoteDetails } from "@ensol/shared/entities/quotes/details/evCharger"
import { computePhotovoltaicQuoteDetails } from "@ensol/shared/entities/quotes/details/photovoltaic"

export const computeQuoteDetails = (
  installation: InstallationsResponses.Installation,
): QuoteDetails => {
  const {
    photovoltaicInstallation,
    batteryInstallation,
    evChargerInstallation,
  } = installation

  const { installationCost, netInstallationCost } = computeAllCosts({
    photovoltaicInstallation,
    batteryInstallation,
    evChargerInstallation,
    house: installation.house,
    totalDiscount: computeTotalDiscount(installation.discount),
  })

  return {
    ...(photovoltaicInstallation
      ? {
          photovoltaic: computePhotovoltaicQuoteDetails({
            photovoltaicInstallation,
            house: installation.house,
          }),
        }
      : {}),
    ...(batteryInstallation
      ? { battery: computeBatteryQuoteDetails(batteryInstallation) }
      : {}),
    ...(evChargerInstallation
      ? { evCharger: computeEvChargerQuoteDetails(evChargerInstallation) }
      : {}),
    discounts: computeTotalDiscount(installation.discount),
    totals: { installationCost, netInstallationCost },
  }
}
