import { showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { ProspectResponses } from "@ensol/types/endpoints/prospects"
import { HouseInput } from "@ensol/types/forms/houses"

import { httpClient } from "@ensol/entool/backend/axios"

export const useGetHouseQuery = (houseId: string | null) => {
  return useQuery<
    HousesResponses.House<{
      include: {
        switchgridConsent: true
        switchgridOrder: true
        prospect: true
        roofSections: true
        installations: true
      }
    }>
  >({
    queryKey: ["houses", houseId],
    queryFn: async () => {
      const response = await httpClient.get(`/houses/${houseId}`)
      return response.data
    },
    enabled: houseId !== null,
  })
}

export const useUpdateHouseMutation = (
  houseId: string,
  onSuccess?: (
    data: HousesResponses.House<{ include: { roofSections: true } }>,
  ) => void,
) => {
  return useMutation<
    ProspectResponses.HouseWithRoofSections,
    AxiosError,
    HouseInput
  >({
    mutationFn: async (house) => {
      const response = await httpClient.put(`/houses/${houseId}`, house)
      return response.data
    },
    onSuccess,
    onError: () => {
      showNotification({
        message:
          "Erreur lors de la mise à jour de la maison, les données n'ont pas été sauvegardées",
        color: "red",
      })
    },
  })
}
