import { Field } from "@ensol/entool/components/form/Field"
import { PROJECT_FIELDS_LABELS } from "@ensol/entool/utils/projects/fieldsLabels"

type Props<Values> = {
  initialValues: Values
  propertyName: keyof typeof PROJECT_FIELDS_LABELS
  name?: string
  isRequired?: boolean
  children: React.ReactNode
}

export const FormField = <Values extends Record<string, unknown>>({
  initialValues,
  propertyName,
  name,
  isRequired,
  children,
}: Props<Values>) => {
  if (!(propertyName in initialValues)) {
    return null
  }

  return (
    <Field
      name={name ?? PROJECT_FIELDS_LABELS[propertyName]}
      noBorder
      withAsterisk={isRequired}
    >
      {children}
    </Field>
  )
}
