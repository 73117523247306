import { FollowUpStep } from "@ensol/shared/entities/projects/processes"

import { StepComponentGetter } from "@ensol/entool/components/entities/Project/ProcessView"
import { WaitingForCertificate } from "@ensol/entool/pages/FollowUpProject/steps/1-WaitingForCertificate"
import { WaitingForConsent } from "@ensol/entool/pages/FollowUpProject/steps/2-WaitingForConsent"
import { J7ToSend } from "@ensol/entool/pages/FollowUpProject/steps/3-J7ToSend"
import { M1ToSend } from "@ensol/entool/pages/FollowUpProject/steps/4-M1ToSend"
import { M1Sent } from "@ensol/entool/pages/FollowUpProject/steps/5-M1Sent"
import { ActionsRequired } from "@ensol/entool/pages/FollowUpProject/steps/6-ActionsRequired"
import { M1Ignored } from "@ensol/entool/pages/FollowUpProject/steps/7-M1Ignored"
import { Done } from "@ensol/entool/pages/FollowUpProject/steps/8-Done"

export const getFollowUpStepComponent: StepComponentGetter = (currentStep) => {
  switch (currentStep) {
    case FollowUpStep.PENDING:
      return { StepComponent: WaitingForCertificate, isCancellable: false }
    case FollowUpStep.WAITING_FOR_CONSENT:
      return { StepComponent: WaitingForConsent }
    case FollowUpStep.J7_TO_SEND:
      return { StepComponent: J7ToSend }
    case FollowUpStep.M1_TO_SEND:
      return { StepComponent: M1ToSend }
    case FollowUpStep.M1_SENT:
      return { StepComponent: M1Sent }
    case FollowUpStep.ACTIONS_REQUIRED:
      return { StepComponent: ActionsRequired }
    case FollowUpStep.M1_IGNORED:
      return { StepComponent: M1Ignored }
    case FollowUpStep.DONE:
      return { StepComponent: Done }
    default:
      return {
        StepComponent: null,
      }
  }
}
