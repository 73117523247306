import { ActionIcon, Tooltip } from "@mantine/core"
import { IconInfoCircle } from "@tabler/icons-react"
import dayjs from "dayjs"
import { useMemo } from "react"

type Props = {
  solarImageryDate?: Date
}

export const ImageryDateInfo = ({ solarImageryDate }: Props) => {
  const tooltipLabel = useMemo(() => {
    const solarLabel = solarImageryDate
      ? `Prise de vue: ${dayjs(solarImageryDate).format("MMMM YYYY")}`
      : ""

    if (!solarLabel) return "Date indisponible."
    return solarLabel
  }, [solarImageryDate])

  return (
    <Tooltip label={tooltipLabel} multiline w={170}>
      <ActionIcon
        variant="subtle"
        size="sm"
        color="gray"
        style={{ borderRadius: 24 }}
      >
        <IconInfoCircle />
      </ActionIcon>
    </Tooltip>
  )
}
