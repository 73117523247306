import { InstallerResponses } from "@ensol/types/endpoints/installers"
import { ProjectResponses } from "@ensol/types/endpoints/projects"

import {
  ProjectEventStatus,
  ProjectEventType,
} from "@ensol/shared/entities/projects/events"

import {
  EVENT_STATUSES_COLORS,
  INSTALLER_EVENT_COLOR,
} from "@ensol/entool/utils/projects/events"

export const formatProjectsEvents = (
  projectsEventsResponse: ProjectResponses.ProjectsEvents,
) =>
  projectsEventsResponse.events.map(
    ({ type, start, end, status, metadata }) => ({
      id: `${metadata.projectId}-${type}`,
      title: `${metadata.clientName}, ${metadata.installerName}`,
      start,
      end,
      allDay: type === ProjectEventType.INSTALLATION,
      textColor: status === ProjectEventStatus.PROPOSAL ? "black" : "white",
      backgroundColor: EVENT_STATUSES_COLORS[status],
      borderColor: EVENT_STATUSES_COLORS[status],
      startEditable:
        status !== ProjectEventStatus.PROPOSAL &&
        status !== ProjectEventStatus.COMPLETED,
      durationEditable:
        type === ProjectEventType.INSTALLATION &&
        status !== ProjectEventStatus.PROPOSAL &&
        status !== ProjectEventStatus.COMPLETED,
      extendedProps: { ...metadata, type, status },
    }),
  )

export const formatInstallersEvents = ({
  installersEventsResponse,
}: {
  installersEventsResponse: InstallerResponses.InstallerEvent[]
}) =>
  installersEventsResponse.map(({ name, start, end, isAllDay }) => ({
    title: name,
    start,
    end,
    allDay: isAllDay,
    backgroundColor: "white",
    textColor: INSTALLER_EVENT_COLOR,
    borderColor: INSTALLER_EVENT_COLOR,
  }))
