import { useUser } from "@clerk/clerk-react"
import { Stack, Text, Card, SimpleGrid, Group } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { Link } from "react-router-dom"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { computePhotovoltaicInstallationCapacity } from "@ensol/shared/entities/installations/energy"
import { getFullName } from "@ensol/shared/entities/prospects"
import { formatDate } from "@ensol/shared/utils/format"

import { Map } from "@ensol/entool/components/Map"
import { PageLayout } from "@ensol/entool/components/layout/PageLayout"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useLatestProjectsQuery } from "@ensol/entool/queries/projects"
import { devices, theme } from "@ensol/entool/styles/theme"
import { getZIndex } from "@ensol/entool/styles/z-index"

export const Home = () => {
  const { user } = useUser()
  const isMobile = useMediaQuery(devices.sm)

  const latestProjectsQuery = useLatestProjectsQuery()

  return (
    <PageLayout title={`Bonjour ${user?.firstName} ☀️`}>
      <Stack gap="24" w="100%">
        <Text fz={{ base: 16, md: 20 }} fw="bold">
          Derniers projets signés par l&apos;équipe !
        </Text>
        <QueryWrapper query={latestProjectsQuery}>
          {({ data }) => (
            <SimpleGrid
              cols={isMobile ? 1 : 3}
              spacing={isMobile ? 20 : 32}
              pb="24"
            >
              {data.map((project) => (
                <SignedProject key={project.id} project={project} />
              ))}
            </SimpleGrid>
          )}
        </QueryWrapper>
      </Stack>
    </PageLayout>
  )
}

type SignedProjectProps = {
  project: ProjectResponses.Project
}

const SignedProject = ({ project }: SignedProjectProps) => {
  const isMobile = useMediaQuery(devices.sm)

  const { house, photovoltaicInstallation } = project.installation
  const { prospect } = house

  return (
    <Card
      component={Link}
      withBorder
      p="0"
      style={{ borderRadius: 16 }}
      pos="relative"
      to={`/projects/${project.id}`}
    >
      <Stack
        w="100%"
        h="100%"
        p={{ base: 16, md: 24 }}
        bg={`linear-gradient(to bottom, ${theme.colors?.blue![7]}CC 0%,rgba(255,255,255,0)`}
        pos="absolute"
        style={{ zIndex: getZIndex("overlay") }}
      >
        <Stack gap="0">
          <Group justify="space-between">
            <Text fw="bold" fz={{ base: 16, md: 20 }} c="white">
              {getFullName(prospect)} -{" "}
              {photovoltaicInstallation !== null &&
                `${computePhotovoltaicInstallationCapacity(photovoltaicInstallation)} kWc`}
            </Text>
            {!isMobile && (
              <Text fw="bold" fz={{ base: "sm", md: "md" }} c="white">
                Signé le {formatDate(project.signatureDate)}
              </Text>
            )}
          </Group>
          <Text fz={{ base: "sm", md: "md" }} c="white">
            {house.streetAddress}, {house.postcode} {house.city}
          </Text>
          {isMobile && (
            <Text fz={{ base: "sm", md: "md" }} c="white" mt="16">
              Signé le {formatDate(project.signatureDate)}
            </Text>
          )}
        </Stack>
      </Stack>
      <Map
        coords={{ lat: house.lat, lng: house.lng }}
        mapId={`map-project-${project.id}`}
        style={{ height: isMobile ? 150 : 250, opacity: 0.6 }}
      />
    </Card>
  )
}
