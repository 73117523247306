import _ from "lodash"

import { ExtraWorks } from "@ensol/types/installation"

import { withMargin } from "@ensol/shared/entities/installations/costs"
import { ExtraWorkType, getExtraWork } from "@ensol/shared/material/extraWorks"

export const computeExtraWorkUnitPrice = (type: ExtraWorkType) =>
  withMargin(getExtraWork(type).price)

export const computeExtraWorksCosts = ({
  extraWorks,
}: {
  extraWorks: ExtraWorks
}): number =>
  _.chain(extraWorks)
    .filter(({ isBilled }) => isBilled === true)
    .map(({ type }) => computeExtraWorkUnitPrice(type))
    .sum()
    .value()
