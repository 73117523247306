import _ from "lodash"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import {
  AllCosts,
  withMargin,
} from "@ensol/shared/entities/installations/costs"
import { computeExtraWorksCosts } from "@ensol/shared/entities/installations/costs/extraWorks"
import {
  computeInvertersCount,
  computePhotovoltaicInstallationCapacity,
} from "@ensol/shared/entities/installations/energy"
import { Subsidy } from "@ensol/shared/entities/installations/subsidies"
import {
  PHOTOVOLTAIC_SUBSIDIES,
  PhotovoltaicSubsidyType,
} from "@ensol/shared/entities/installations/subsidies/photovoltaic"
import {
  CURRENT_CONNECTIONS,
  CurrentType,
} from "@ensol/shared/material/currentType"
import { getCompatibleElectricalSafeGuard } from "@ensol/shared/material/photovoltaic/electricalSafeGuards"
import {
  getInverter,
  InverterType,
} from "@ensol/shared/material/photovoltaic/inverters"
import {
  getOptimizer,
  OptimizerType,
} from "@ensol/shared/material/photovoltaic/optimizers"
import { getPanel, PanelType } from "@ensol/shared/material/photovoltaic/panels"
import { getPhotovoltaicSmartMeter } from "@ensol/shared/material/photovoltaic/smartMeters"
import {
  ROOF_MULTI_SECTIONS_COST,
  ROOF_TYPES,
  RoofType,
} from "@ensol/shared/material/roofType"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

// MATERIAL COSTS {€)
// Build kits
const K_2_CROSS_HOOK_PRICE = 10.4
const K_2_MIDDLE_CLAMP_XS_PRICE = 2.48
const K_2_END_CLAMP_XS_PRICE = 2.6
const K_2_TERRAGRIF_PRICE = 2.5
const K_2_SINGLE_RAIL_PRICE = 20.6
const K_2_CONNECTEUR_SINGLE_RAIL_36_PRICE = 5.96
const RENUSOL_PRICE = 75 // €

// WORKFORCE COSTS {€)
const SECURING_COST_FOR_R1_LEVEL = 110 // €, for R1 level
const SECURING_COST_FOR_I35 = 400 // €, if inclination is > =35°
const TECHNICAL_VISIT_COST = 250 // €
const DELIVERY_COST = 360 // €

// Fixed installation cost defined by Solutions30 is 1300€, including single phase connections & roof with tiles.
// So individual costs are clearer, we subtract those values to the fixed installation cost.
// Details:
// - Proposed Solutions30 fixed installation cost: 1300€
// - Single phase connections cost: 180€
// - Roof with tiles cost: 250€
// - Rationalized fixed installation cost: 1300 - 180 - 250 = 870€
const INSTALLATION_FIXED_COST = 1300 - 180 - 250

export const PHOTOVOLTAIC_ADMIN_FEE_COST = 249

type VATInput = {
  photovoltaicInstallation: { panelType: PanelType; panelsCount: number }
  house: Pick<HousesResponses.House, "constructionYear">
}

export const computePhotovoltaicVAT = ({
  photovoltaicInstallation,
  house,
}: VATInput): 0.1 | 0.2 => {
  const currentYear = new Date().getFullYear()
  const houseAge = currentYear - house.constructionYear
  const installedCapacity = computePhotovoltaicInstallationCapacity(
    photovoltaicInstallation,
  )

  if (installedCapacity <= 3 && houseAge >= 2) return 0.1
  return 0.2
}

export const computePanelUnitPrice = (
  panelsCount: number,
  panelType: PanelType,
): number => {
  const panel = getPanel(panelType)
  return withMargin(
    panel.price + (DELIVERY_COST + TECHNICAL_VISIT_COST) / panelsCount,
  )
}

export const computeInverterUnitPrice = (
  inverterType: InverterType,
): number => {
  const inverter = getInverter(inverterType)
  return withMargin(inverter.price)
}

export const computeOptimizerUnitPrice = (
  optimizerType: OptimizerType | null,
): number => {
  const optimizer = getOptimizer(optimizerType)
  return optimizer ? withMargin(optimizer.price) : 0
}

export const computePhotovoltaicSmartMeterPrice = (
  inverterType: InverterType,
): number => {
  const smartMeter = getPhotovoltaicSmartMeter(inverterType)
  return withMargin(smartMeter.price)
}

export const computeBuildKitUnitPrice = (hasFlatRoof: boolean): number => {
  if (hasFlatRoof) return withMargin(RENUSOL_PRICE)
  return withMargin(
    2 * K_2_CROSS_HOOK_PRICE +
      2 * K_2_MIDDLE_CLAMP_XS_PRICE +
      K_2_END_CLAMP_XS_PRICE +
      K_2_TERRAGRIF_PRICE +
      K_2_SINGLE_RAIL_PRICE +
      K_2_CONNECTEUR_SINGLE_RAIL_36_PRICE,
  )
}

export const computeElectricalSafeGuardUnitPrice = (
  panelsCount: number,
  inverterType: InverterType,
  currentType: CurrentType,
) => {
  const electricalSafeGuard = getCompatibleElectricalSafeGuard(
    panelsCount,
    inverterType,
    currentType,
  )
  const inverter = getInverter(inverterType)

  return withMargin(
    electricalSafeGuard.acPrice +
      (inverter.isCentralInverter ? electricalSafeGuard.dcPrice : 0),
  )
}

type PhotovoltaicMaterialCostInput = {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation
  house: Pick<HousesResponses.House, "currentType" | "hasFlatRoof">
}

export const computePhotovoltaicMaterialCost = ({
  photovoltaicInstallation,
  house,
}: PhotovoltaicMaterialCostInput): number => {
  const {
    panelType,
    panelsCount,
    inverterType,
    optimizerType,
    optimizerCount,
  } = photovoltaicInstallation
  const invertersCount = computeInvertersCount(inverterType, panelsCount)

  return (
    panelsCount *
      (computePanelUnitPrice(panelsCount, panelType) +
        computeBuildKitUnitPrice(house.hasFlatRoof)) +
    (optimizerCount ?? 0) * computeOptimizerUnitPrice(optimizerType) +
    invertersCount * computeInverterUnitPrice(inverterType) +
    computePhotovoltaicSmartMeterPrice(inverterType) +
    computeElectricalSafeGuardUnitPrice(
      panelsCount,
      inverterType,
      house.currentType,
    )
  )
}

const computeLogisticsCost = (panelsCount: number): number => {
  if (panelsCount <= 16) return 90
  if (panelsCount <= 24) return 150
  return 330
}

const computeVariablePanelUnitInstallationUnitCost = (
  panelNumber: number,
): number => {
  if (panelNumber <= 8) return 80
  if (panelNumber <= 16) return 75
  if (panelNumber <= 24) return 70
  return 65
}

const computeVariablePanelInstallationUnitCost = (
  panelsCount: number,
): number => {
  return _.sum(
    _.times(panelsCount, (i) =>
      computeVariablePanelUnitInstallationUnitCost(i + 1),
    ),
  )
}

const computeRoofTypeInstallationCost = (roofType: RoofType): number => {
  return ROOF_TYPES[roofType].cost
}

export const computeAdditionalRoofSectionsCost = (
  roofSectionsWithPanels: InstallationsResponses.PhotovoltaicInstallation["roofSectionsWithPanels"],
) => {
  const roofSectionsCount = roofSectionsWithPanels.reduce(
    (acc, section) => (section.panelsCount > 0 ? acc + 1 : acc),
    0,
  )
  return (roofSectionsCount - 1) * ROOF_MULTI_SECTIONS_COST
}

type PhotovoltaicWorkforceCostInput = {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation
  house: Pick<HousesResponses.House, "currentType" | "roofType">
}

export const computePhotovoltaicWorkforceCost = ({
  photovoltaicInstallation,
  house,
}: PhotovoltaicWorkforceCostInput) => {
  const { panelsCount, roofSectionsWithPanels } = photovoltaicInstallation

  const inclinationSecuringCost = roofSectionsWithPanels
    .filter((sec) => sec.panelsCount > 0)
    .some((section) => section.roofSection.inclination >= 35)
    ? SECURING_COST_FOR_I35
    : 0

  return withMargin(
    INSTALLATION_FIXED_COST +
      computeVariablePanelInstallationUnitCost(panelsCount) +
      computeRoofTypeInstallationCost(house.roofType) +
      computeLogisticsCost(panelsCount) +
      SECURING_COST_FOR_R1_LEVEL +
      inclinationSecuringCost +
      computeAdditionalRoofSectionsCost(roofSectionsWithPanels) +
      CURRENT_CONNECTIONS[house.currentType].cost,
  )
}

export type InstallationCostInput = {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation
  house: Pick<
    HousesResponses.House,
    "constructionYear" | "currentType" | "roofType" | "hasFlatRoof"
  >
}

export const computePhotovoltaicInstallationCost = ({
  photovoltaicInstallation,
  house,
}: InstallationCostInput): number => {
  const materialCostHT = computePhotovoltaicMaterialCost({
    photovoltaicInstallation,
    house,
  })
  const extraWorksCostHT = computeExtraWorksCosts(photovoltaicInstallation)
  const workforceCostHT = computePhotovoltaicWorkforceCost({
    photovoltaicInstallation,
    house,
  })

  const totalCostHT =
    materialCostHT +
    extraWorksCostHT +
    workforceCostHT +
    PHOTOVOLTAIC_ADMIN_FEE_COST

  return roundDecimalNumber(
    totalCostHT *
      (1 +
        computePhotovoltaicVAT({
          photovoltaicInstallation,
          house,
        })),
  )
}

type AllCostsInput = {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation
  house: Pick<
    HousesResponses.House,
    "constructionYear" | "currentType" | "roofType" | "hasFlatRoof"
  >
  totalDiscount: number
}

export const computeAllPhotovoltaicCosts = ({
  photovoltaicInstallation,
  house,
  totalDiscount,
}: AllCostsInput): AllCosts & {
  subsidy: Subsidy<PhotovoltaicSubsidyType> | null
} => {
  const vatRate = computePhotovoltaicVAT({
    photovoltaicInstallation,
    house,
  })

  const installationCost = computePhotovoltaicInstallationCost({
    photovoltaicInstallation,
    house,
  })

  const grossInstallationCost = roundDecimalNumber(
    installationCost / (1 + vatRate),
  )

  const subsidyType = photovoltaicInstallation.subsidyType
  const subsidy =
    subsidyType !== null
      ? {
          subsidyType,
          subsidyAmount: PHOTOVOLTAIC_SUBSIDIES[subsidyType].computeAmount({
            photovoltaicInstallation,
            house,
            totalDiscount,
          }),
        }
      : null

  const netInstallationCost = roundDecimalNumber(
    installationCost - (subsidy?.subsidyAmount ?? 0),
  )

  return {
    installationCost,
    grossInstallationCost,
    netInstallationCost,
    subsidy,
  }
}
