import {
  ConsumptionDataSource,
  ProductionDataSource,
} from "@ensol/types/simulation"

import { getOptionsFromList } from "@ensol/entool/utils/form/options"
import { Option } from "@ensol/entool/utils/form/radio"

export const PRODUCTION_DATA_SOURCE_OPTIONS: Option<ProductionDataSource>[] =
  getOptionsFromList(Object.values(ProductionDataSource))

export const CONSUMPTION_DATA_SOURCE_OPTIONS: Option<ConsumptionDataSource>[] =
  [
    { value: ConsumptionDataSource.SWITCHGRID, label: "ENEDIS" },
    { value: ConsumptionDataSource.BILL, label: "FACTURE" },
  ]
