import { M1ToSendFormSchema } from "@ensol/types/forms/projects/followUp"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { AfterConsentForm } from "@ensol/entool/pages/FollowUpProject/steps/components/AfterConsentForm"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"

export const M1ToSend = ({ project, ...props }: StepComponentProps) => (
  <AfterConsentForm
    schema={M1ToSendFormSchema}
    initialValues={getInitialValues(M1ToSendFormSchema, project)}
    project={project}
    actions={[{ action: "validate", label: "M+1 envoyé" }]}
    {...props}
  />
)
