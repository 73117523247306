import { useLocalStorage } from "@mantine/hooks"
import { useState } from "react"

import { ProjectEventType } from "@ensol/shared/entities/projects/events"

export const useEventsMenu = () => {
  const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false)

  const [eventTypes, setEventTypes] = useLocalStorage({
    key: "eventsTypesFilter",
    defaultValue: Object.values(ProjectEventType),
  })
  const [installers, setInstallers] = useLocalStorage<string[]>({
    key: "eventsInstallerFilter",
    defaultValue: [],
  })
  const [showInstallerEvents, setShowInstallerEvents] = useLocalStorage({
    key: "shouldDisplayInstallerEvents",
    defaultValue: false,
  })

  return {
    isMenuDrawerOpen,
    openMenuDrawer: () => setIsMenuDrawerOpen(true),
    closeMenuDrawer: () => setIsMenuDrawerOpen(false),
    eventTypes,
    setEventTypes,
    installers,
    setInstallers,
    showInstallerEvents,
    setShowInstallerEvents,
  }
}
