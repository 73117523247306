import { completedStepFormSchema } from "@ensol/types/forms/projects/installation"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/pages/OpsProject/utils"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"

import { InstallationForm } from "./components/InstallationForm"
import { INSTALLATION_PROPERTIES } from "./config"

export const CertificateSigned = ({
  project,
  ...props
}: StepComponentProps) => (
  <InstallationForm
    schema={completedStepFormSchema}
    initialValues={{
      ...pickDefinedProperties(INSTALLATION_PROPERTIES, project),
      ...getInitialValues(completedStepFormSchema, project),
    }}
    project={project}
    actions={[]}
    {...props}
  />
)
