import {
  PreliminaryRequestStep,
  TechnicalVisitStep,
  GreendealStep,
  PaymentStep,
  HardwareStep,
  EnedisStep,
  ConsuelStep,
  EdfOaStep,
  FollowUpStep,
  InstallationStep,
} from "@ensol/shared/entities/projects/processes"

import { ProjectMilestonesConfig } from "./types"

export const PRELIMINARY_REQUEST_STEPS: ProjectMilestonesConfig<PreliminaryRequestStep> =
  [
    {
      id: PreliminaryRequestStep.WAITING_FOR_INFO,
      label: "Projet signé",
      color: "blue",
      nodePosition: { x: 0, y: 60 },
      nextSteps: [PreliminaryRequestStep.PRELIMINARY_VALIDATION],
    },
    {
      id: PreliminaryRequestStep.PRELIMINARY_VALIDATION,
      label: "Projet à valider",
      color: "blue",
      nodePosition: { x: 200, y: 60 },
      nextSteps: [
        PreliminaryRequestStep.SUBMISSION_PENDING,
        PreliminaryRequestStep.SUBMISSION_ON_HOLD,
      ],
    },
    {
      id: PreliminaryRequestStep.SUBMISSION_ON_HOLD,
      label: "Demande préalable en attente",
      color: "orange",
      nodePosition: { x: 400, y: 120 },
      nextSteps: [PreliminaryRequestStep.SUBMISSION_PENDING],
    },
    {
      id: PreliminaryRequestStep.SUBMISSION_PENDING,
      label: "Projet complet",
      color: "blue",
      nodePosition: { x: 600, y: 60 },
      nextSteps: [PreliminaryRequestStep.WAITING_FOR_SOLTEO],
    },
    {
      id: PreliminaryRequestStep.WAITING_FOR_SOLTEO,
      label: "En attente de Solteo",
      color: "blue",
      nodePosition: { x: 800, y: 49 },
      nextSteps: [PreliminaryRequestStep.SUBMITTED],
    },
    {
      id: PreliminaryRequestStep.SUBMITTED,
      label: "Envoyé",
      color: "blue",
      nodePosition: { x: 1000, y: 60 },
      nextSteps: [PreliminaryRequestStep.ACKNOWLEDGMENT],
    },
    {
      id: PreliminaryRequestStep.ACKNOWLEDGMENT,
      label: "Accusé de réception",
      color: "blue",
      nodePosition: { x: 1200, y: 49 },
      nextSteps: [
        PreliminaryRequestStep.INCOMPLETE,
        PreliminaryRequestStep.REJECTED,
        PreliminaryRequestStep.APPROVED,
      ],
    },
    {
      id: PreliminaryRequestStep.INCOMPLETE,
      label: "Incomplet",
      color: "orange",
      nodePosition: { x: 1400, y: 11 },
      nextSteps: [PreliminaryRequestStep.INCOMPLETE_WAITING_FOR_STAKEHOLDER],
    },
    {
      id: PreliminaryRequestStep.INCOMPLETE_WAITING_FOR_STAKEHOLDER,
      label: "Incomplet - en attente",
      color: "orange",
      nodePosition: { x: 1600, y: 0 },
      nextSteps: [PreliminaryRequestStep.INCOMPLETE_SUBMITTED],
    },
    {
      id: PreliminaryRequestStep.INCOMPLETE_SUBMITTED,
      label: "Incomplet - envoyé",
      color: "blue",
      nodePosition: { x: 1800, y: 11 },
      nextSteps: [PreliminaryRequestStep.APPROVED],
    },
    {
      id: PreliminaryRequestStep.APPROVED,
      label: "Approuvé",
      color: "blue",
      nodePosition: { x: 2000, y: 100 },
      nextSteps: [PreliminaryRequestStep.AUTHORISATION_RECEIVED],
    },
    {
      id: PreliminaryRequestStep.AUTHORISATION_RECEIVED,
      label: "Autorisation reçue",
      color: "green",
      nodePosition: { x: 2200, y: 100 },
      nextSteps: [],
    },
    {
      id: PreliminaryRequestStep.REJECTED,
      label: "Rejeté",
      color: "red",
      nextSteps: [],
    },
  ]

export const TECHNICAL_VISIT_STEPS: ProjectMilestonesConfig<TechnicalVisitStep> =
  [
    {
      id: TechnicalVisitStep.PENDING,
      label: "Prêt à planifier",
      color: "blue",
      nodePosition: { x: 0, y: 30 },
      nextSteps: [
        TechnicalVisitStep.REQUESTED,
        TechnicalVisitStep.SCHEDULING_ON_HOLD,
      ],
    },
    {
      id: TechnicalVisitStep.SCHEDULING_ON_HOLD,
      label: "Non planifiable, à rappeler",
      color: "orange",
      nodePosition: { x: 200, y: 65 },
      nextSteps: [
        TechnicalVisitStep.CANNOT_SCHEDULE,
        TechnicalVisitStep.REQUESTED,
      ],
    },
    {
      id: TechnicalVisitStep.CANNOT_SCHEDULE,
      label: "Impossible à planifier",
      color: "red",
      nodePosition: { x: 400, y: 105 },
      nextSteps: [],
    },
    {
      id: TechnicalVisitStep.REQUESTED,
      label: "Demandée",
      color: "blue",
      nodePosition: { x: 400, y: 30 },
      nextSteps: [
        TechnicalVisitStep.INVITATION_REJECTED,
        TechnicalVisitStep.SCHEDULED,
      ],
    },
    {
      id: TechnicalVisitStep.INVITATION_REJECTED,
      label: "Invitation rejetée",
      color: "orange",
      nodePosition: { x: 600, y: 0 },
      nextSteps: [],
    },
    {
      id: TechnicalVisitStep.SCHEDULED,
      label: "Prévue",
      color: "blue",
      nodePosition: { x: 600, y: 90 },
      nextSteps: [TechnicalVisitStep.COMPLETED],
    },
    {
      id: TechnicalVisitStep.COMPLETED,
      label: "Réalisée - En attente CR",
      color: "blue",
      nodePosition: { x: 800, y: 80 },
      nextSteps: [TechnicalVisitStep.BEING_PROCESSED],
    },
    {
      id: TechnicalVisitStep.BEING_PROCESSED,
      label: "En cours de traitement",
      color: "blue",
      nodePosition: { x: 1000, y: 80 },
      nextSteps: [
        TechnicalVisitStep.VALIDATED,
        TechnicalVisitStep.VALIDATED_WITH_WORKS,
        TechnicalVisitStep.UPDATE_INSTALLATION,
        TechnicalVisitStep.ON_HOLD,
      ],
    },
    {
      id: TechnicalVisitStep.UPDATE_INSTALLATION,
      label: "Mise à jour de l'installation",
      color: "blue",
      nodePosition: { x: 1400, y: 130 },
      nextSteps: [
        TechnicalVisitStep.WAITING_FOR_SALES_CHANGES,
        TechnicalVisitStep.ON_HOLD,
      ],
    },
    {
      id: TechnicalVisitStep.WAITING_FOR_SALES_CHANGES,
      label: "Nouveau devis à envoyer",
      color: "blue",
      nodePosition: { x: 1600, y: 130 },
      nextSteps: [TechnicalVisitStep.WAITING_FOR_SIGNATURE],
    },
    {
      id: TechnicalVisitStep.WAITING_FOR_SIGNATURE,
      label: "En attente signature client",
      color: "blue",
      nodePosition: { x: 1800, y: 130 },
      nextSteps: [TechnicalVisitStep.VALIDATED],
    },
    {
      id: TechnicalVisitStep.VALIDATED_WITH_WORKS,
      label: "Validée avec travaux",
      color: "orange",
      nodePosition: { x: 1800, y: 0 },
      nextSteps: [TechnicalVisitStep.VALIDATED],
    },
    {
      id: TechnicalVisitStep.ON_HOLD,
      label: "Travaux importants",
      color: "red",
      nodePosition: { x: 1600, y: 260 },
      nextSteps: [],
    },
    {
      id: TechnicalVisitStep.VALIDATED,
      label: "Validée",
      color: "green",
      nodePosition: { x: 2000, y: 90 },
      nextSteps: [],
    },
    {
      id: TechnicalVisitStep.REJECTED,
      label: "Rejetée",
      color: "red",
      nextSteps: [],
    },
  ]

export const GREENDEAL_STEPS: ProjectMilestonesConfig<GreendealStep> = [
  {
    id: GreendealStep.EMAIL_PENDING,
    label: "Email à envoyer",
    color: "blue",
    nodePosition: { x: 0, y: 50 },
    nextSteps: [GreendealStep.SUBMISSION_PENDING],
  },
  {
    id: GreendealStep.SUBMISSION_PENDING,
    label: "En attente",
    color: "blue",
    nodePosition: { x: 200, y: 50 },
    nextSteps: [GreendealStep.SUBMITTED, GreendealStep.DONE_BY_CLIENT],
  },
  {
    id: GreendealStep.SUBMITTED,
    label: "Soumis",
    color: "blue",
    nodePosition: { x: 400, y: 0 },
    nextSteps: [GreendealStep.FILE_COMPLETED],
  },
  {
    id: GreendealStep.DONE_BY_CLIENT,
    label: "Fait par le client",
    color: "green",
    nodePosition: { x: 400, y: 100 },
    nextSteps: [],
  },
  {
    id: GreendealStep.FILE_COMPLETED,
    label: "Dossier complété",
    color: "blue",
    nodePosition: { x: 600, y: 0 },
    nextSteps: [GreendealStep.VALIDATED],
  },
  {
    id: GreendealStep.VALIDATED,
    label: "Validé",
    color: "green",
    nodePosition: { x: 800, y: 0 },
    nextSteps: [],
  },
]

export const INSTALLATION_STEPS: ProjectMilestonesConfig<InstallationStep> = [
  {
    id: InstallationStep.PENDING,
    label: "Prêt à planifier",
    color: "blue",
    nodePosition: { x: 0, y: 20 },
    nextSteps: [
      InstallationStep.REQUESTED,
      InstallationStep.SCHEDULING_ON_HOLD,
    ],
  },
  {
    id: InstallationStep.SCHEDULING_ON_HOLD,
    label: "Non planifiable, à rappeler",
    color: "orange",
    nodePosition: { x: 200, y: 65 },
    nextSteps: [InstallationStep.CANNOT_SCHEDULE, InstallationStep.REQUESTED],
  },
  {
    id: InstallationStep.CANNOT_SCHEDULE,
    label: "Impossible à planifier",
    color: "red",
    nodePosition: { x: 400, y: 105 },
    nextSteps: [],
  },
  {
    id: InstallationStep.REQUESTED,
    label: "Demandée",
    color: "blue",
    nodePosition: { x: 400, y: 20 },
    nextSteps: [InstallationStep.SCHEDULED],
  },
  {
    id: InstallationStep.SCHEDULED,
    label: "Prévue",
    color: "blue",
    nodePosition: { x: 600, y: 20 },
    nextSteps: [InstallationStep.PROJECT_PREPARED],
  },
  {
    id: InstallationStep.PROJECT_PREPARED,
    label: "Chantier préparé",
    color: "blue",
    nodePosition: { x: 800, y: 20 },
    nextSteps: [InstallationStep.IN_PROGRESS],
  },
  {
    id: InstallationStep.IN_PROGRESS,
    label: "En cours",
    color: "blue",
    nodePosition: { x: 1000, y: 20 },
    nextSteps: [InstallationStep.COMPLETED, InstallationStep.NEW_VISIT_NEEDED],
  },
  {
    id: InstallationStep.NEW_VISIT_NEEDED,
    label: "Besoin de repasser",
    color: "orange",
    nodePosition: { x: 1200, y: 90 },
    nextSteps: [InstallationStep.COMPLETED],
  },
  {
    id: InstallationStep.COMPLETED,
    label: "Complétée - Bon à envoyer",
    color: "blue",
    nodePosition: { x: 1400, y: 8 },
    nextSteps: [
      InstallationStep.UPDATE_INSTALLATION,
      InstallationStep.CERTIFICATE_SENT,
    ],
  },
  {
    id: InstallationStep.UPDATE_INSTALLATION,
    label: "Mise à jour de l'installation",
    color: "blue",
    nodePosition: { x: 1600, y: 80 },
    nextSteps: [InstallationStep.CERTIFICATE_SENT],
  },
  {
    id: InstallationStep.CERTIFICATE_SENT,
    label: "Bon de fin de chantier envoyé",
    color: "blue",
    nodePosition: { x: 1800, y: 8 },
    nextSteps: [InstallationStep.CERTIFICATE_SIGNED],
  },
  {
    id: InstallationStep.CERTIFICATE_SIGNED,
    label: "Bon de fin de chantier signé",
    color: "green",
    nodePosition: { x: 2000, y: 8 },
    nextSteps: [],
  },
]

export const PAYMENT_STEPS: ProjectMilestonesConfig<PaymentStep> = [
  {
    id: PaymentStep.DEPOSIT_INVOICE_WAITING,
    label: "Facture d'acompte en attente",
    color: "blue",
    nodePosition: { x: 0, y: 20 },
    nextSteps: [PaymentStep.DEPOSIT_INVOICE_TO_SEND],
  },
  {
    id: PaymentStep.DEPOSIT_INVOICE_TO_SEND,
    label: "Facture d'acompte à envoyer",
    color: "blue",
    nodePosition: { x: 200, y: 20 },
    nextSteps: [PaymentStep.WAITING_FOR_DEPOSIT_PAYMENT],
  },
  {
    id: PaymentStep.WAITING_FOR_DEPOSIT_PAYMENT,
    label: "En attente paiement acompte",
    color: "blue",
    nodePosition: { x: 400, y: 20 },
    nextSteps: [PaymentStep.DEPOSIT_INVOICE_PAID],
  },
  {
    id: PaymentStep.DEPOSIT_INVOICE_PAID,
    label: "Acompte payé",
    color: "blue",
    nodePosition: { x: 600, y: 30 },
    nextSteps: [PaymentStep.FINAL_INVOICE_TO_SEND],
  },
  {
    id: PaymentStep.FINAL_INVOICE_TO_SEND,
    label: "Facture finale à envoyer",
    color: "blue",
    nodePosition: { x: 800, y: 20 },
    nextSteps: [PaymentStep.WAITING_FOR_FINAL_PAYMENT],
  },
  {
    id: PaymentStep.WAITING_FOR_FINAL_PAYMENT,
    label: "En attente de paiement final",
    color: "blue",
    nodePosition: { x: 1000, y: 20 },
    nextSteps: [PaymentStep.FINAL_INVOICE_PAID],
  },
  {
    id: PaymentStep.FINAL_INVOICE_PAID,
    label: "Paiement final effectué",
    color: "green",
    nodePosition: { x: 1200, y: 20 },
    nextSteps: [],
  },
]

export const HARDWARE_STEPS: ProjectMilestonesConfig<HardwareStep> = [
  {
    id: HardwareStep.PREORDER_PENDING,
    label: "Précommande en attente",
    color: "blue",
    nodePosition: { x: 0, y: 20 },
    nextSteps: [HardwareStep.PREORDER_DONE],
  },
  {
    id: HardwareStep.PREORDER_DONE,
    label: "Précommande faite",
    color: "blue",
    nodePosition: { x: 200, y: 30 },
    nextSteps: [HardwareStep.ORDER_PENDING],
  },
  {
    id: HardwareStep.ORDER_PENDING,
    label: "Commande en attente",
    color: "blue",
    nodePosition: { x: 400, y: 20 },
    nextSteps: [HardwareStep.PREPARATION_PENDING],
  },
  {
    id: HardwareStep.PREPARATION_PENDING,
    label: "Préparation en attente",
    color: "blue",
    nodePosition: { x: 600, y: 20 },
    nextSteps: [HardwareStep.DELIVERY_PENDING],
  },
  {
    id: HardwareStep.DELIVERY_PENDING,
    label: "Livraison en attente",
    color: "blue",
    nodePosition: { x: 800, y: 30 },
    nextSteps: [HardwareStep.ORDER_DELIVERED],
  },
  {
    id: HardwareStep.ORDER_DELIVERED,
    label: "Commande livrée",
    color: "blue",
    nodePosition: { x: 1000, y: 30 },
    nextSteps: [HardwareStep.ORDER_CHECKED],
  },
  {
    id: HardwareStep.ORDER_CHECKED,
    label: "Commande livrée & vérifiée",
    color: "blue",
    nodePosition: { x: 1200, y: 20 },
    nextSteps: [HardwareStep.ORDER_INSTALLED],
  },
  {
    id: HardwareStep.ORDER_INSTALLED,
    label: "Commande livrée & installée",
    color: "green",
    nodePosition: { x: 1400, y: 20 },
    nextSteps: [],
  },
]

export const ENEDIS_STEPS: ProjectMilestonesConfig<EnedisStep> = [
  {
    id: EnedisStep.PENDING,
    label: "En attente",
    color: "blue",
    nodePosition: { x: 0, y: 50 },
    nextSteps: [EnedisStep.SUBMITTED],
  },
  {
    id: EnedisStep.SUBMITTED,
    label: "Soumis",
    color: "blue",
    nodePosition: { x: 200, y: 50 },
    nextSteps: [EnedisStep.INCOMPLETE, EnedisStep.VALIDATED],
  },
  {
    id: EnedisStep.INCOMPLETE,
    label: "Incomplet",
    color: "orange",
    nodePosition: { x: 400, y: 0 },
    nextSteps: [],
  },
  {
    id: EnedisStep.VALIDATED,
    label: "Validé",
    color: "blue",
    nodePosition: { x: 400, y: 100 },
    nextSteps: [EnedisStep.ACTIVATION_PENDING],
  },
  {
    id: EnedisStep.ACTIVATION_PENDING,
    label: "En attente mise en service",
    color: "blue",
    nodePosition: { x: 600, y: 90 },
    nextSteps: [EnedisStep.ACTIVATED],
  },
  {
    id: EnedisStep.ACTIVATED,
    label: "Mis en service",
    color: "green",
    nodePosition: { x: 800, y: 100 },
    nextSteps: [],
  },
]

export const CONSUEL_STEPS: ProjectMilestonesConfig<ConsuelStep> = [
  {
    id: ConsuelStep.PENDING,
    label: "En attente",
    color: "blue",
    nodePosition: { x: 0, y: 20 },
    nextSteps: [ConsuelStep.WAITING_FOR_SOLTEO],
  },
  {
    id: ConsuelStep.WAITING_FOR_SOLTEO,
    label: "En attente Solteo",
    color: "blue",
    nodePosition: { x: 200, y: 20 },
    nextSteps: [ConsuelStep.SUBMITTED],
  },
  {
    id: ConsuelStep.SUBMITTED,
    label: "Soumis",
    color: "blue",
    nodePosition: { x: 400, y: 20 },
    nextSteps: [ConsuelStep.VISIT_NEEDED, ConsuelStep.VALIDATED],
  },
  {
    id: ConsuelStep.VISIT_NEEDED,
    label: "Avis de visite",
    color: "orange",
    nodePosition: { x: 600, y: 70 },
    nextSteps: [ConsuelStep.VISIT_SCHEDULED],
  },
  {
    id: ConsuelStep.VISIT_SCHEDULED,
    label: "Visite programmée",
    color: "blue",
    nodePosition: { x: 780, y: 70 },
    nextSteps: [ConsuelStep.WORK_NEEDED, ConsuelStep.VALIDATED],
  },
  {
    id: ConsuelStep.WORK_NEEDED,
    label: "Travaux nécessaires",
    color: "orange",
    nodePosition: { x: 960, y: 120 },
    nextSteps: [ConsuelStep.WORK_SCHEDULED],
  },
  {
    id: ConsuelStep.WORK_SCHEDULED,
    label: "Travaux prévus",
    color: "blue",
    nodePosition: { x: 1140, y: 130 },
    nextSteps: [ConsuelStep.WORK_DONE],
  },
  {
    id: ConsuelStep.WORK_DONE,
    label: "Travaux terminés",
    color: "blue",
    nodePosition: { x: 1320, y: 130 },
    nextSteps: [ConsuelStep.COMPLIANCE_CERTIFICATE_SIGNED],
  },
  {
    id: ConsuelStep.COMPLIANCE_CERTIFICATE_SIGNED,
    label: "Attestation signée",
    color: "blue",
    nodePosition: { x: 1500, y: 130 },
    nextSteps: [ConsuelStep.VALIDATED],
  },
  {
    id: ConsuelStep.VALIDATED,
    label: "Validé",
    color: "green",
    nodePosition: { x: 1680, y: 20 },
    nextSteps: [],
  },
]

export const SUBSIDIES_STEPS: ProjectMilestonesConfig<EdfOaStep> = [
  {
    id: EdfOaStep.EMAIL_SENT,
    label: "Email envoyé",
    color: "blue",
    nodePosition: { x: 0, y: 20 },
    nextSteps: [EdfOaStep.CERTIFICATE_TO_SEND],
  },
  {
    id: EdfOaStep.CERTIFICATE_TO_SEND,
    label: "Attestation à envoyer",
    color: "blue",
    nodePosition: { x: 200, y: 10 },
    nextSteps: [EdfOaStep.CERTIFICATE_SENT],
  },
  {
    id: EdfOaStep.CERTIFICATE_SENT,
    label: "Attestation envoyée",
    color: "blue",
    nodePosition: { x: 400, y: 20 },
    nextSteps: [EdfOaStep.CERTIFICATE_SIGNED],
  },
  {
    id: EdfOaStep.CERTIFICATE_SIGNED,
    label: "Attestation signée",
    color: "blue",
    nodePosition: { x: 600, y: 20 },
    nextSteps: [],
  },
]

export const FOLLOW_UP_STEPS: ProjectMilestonesConfig<FollowUpStep> = [
  {
    id: FollowUpStep.PENDING,
    label: "Installation terminée",
    color: "blue",
    nodePosition: { x: 0, y: 20 },
    nextSteps: [FollowUpStep.WAITING_FOR_CONSENT],
  },
  {
    id: FollowUpStep.WAITING_FOR_CONSENT,
    label: "En attente consentement",
    color: "blue",
    nodePosition: { x: 200, y: 20 },
    nextSteps: [FollowUpStep.J7_TO_SEND],
  },
  {
    id: FollowUpStep.J7_TO_SEND,
    label: "J+7 à envoyer",
    color: "blue",
    nodePosition: { x: 400, y: 30 },
    nextSteps: [FollowUpStep.M1_TO_SEND],
  },
  {
    id: FollowUpStep.M1_TO_SEND,
    label: "M+1 à envoyer",
    color: "blue",
    nodePosition: { x: 600, y: 30 },
    nextSteps: [FollowUpStep.M1_SENT],
  },
  {
    id: FollowUpStep.M1_SENT,
    label: "M+1 envoyé",
    color: "blue",
    nodePosition: { x: 800, y: 30 },
    nextSteps: [
      FollowUpStep.ACTIONS_REQUIRED,
      FollowUpStep.M1_IGNORED,
      FollowUpStep.DONE,
    ],
  },
  {
    id: FollowUpStep.ACTIONS_REQUIRED,
    label: "Actions requises",
    color: "orange",
    nodePosition: { x: 1000, y: 90 },
    nextSteps: [FollowUpStep.DONE],
  },
  {
    id: FollowUpStep.DONE,
    label: "Suivi terminé",
    color: "green",
    nodePosition: { x: 1200, y: 30 },
    nextSteps: [],
  },
  {
    id: FollowUpStep.M1_IGNORED,
    label: "M+1 ignoré",
    color: "red",
    nextSteps: [],
  },
  {
    id: FollowUpStep.CONSENT_REFUSED,
    label: "Consentement refusé",
    color: "red",
    nextSteps: [],
  },
]
