import { showNotification } from "@mantine/notifications"
import { useQuery } from "@tanstack/react-query"
import _ from "lodash"

import { EnergyResponses } from "@ensol/types/endpoints/energy"
import { HousesResponses } from "@ensol/types/endpoints/houses"
import { Location } from "@ensol/types/forms/houses"
import { CreateInstallationInput } from "@ensol/types/forms/installations"
import {
  ConsumptionDataSource,
  ProductionDataSource,
} from "@ensol/types/simulation"

import { OrderStatus } from "@ensol/shared/entities/houses/switchgrid"
import { hasPhotovoltaicInstallation } from "@ensol/shared/entities/installations/characteristics"

import { httpClient } from "@ensol/entool/backend/axios"

type UseInstallationEnergyProductionInput = {
  coords: Pick<Location, "lat" | "lng">
  installation: Pick<
    CreateInstallationInput,
    "photovoltaicInstallation" | "horizonProfileFilePath"
  >
}

export const useEnergyProductionPerDataSource = ({
  coords,
  installation,
}: UseInstallationEnergyProductionInput) =>
  useQuery<Record<ProductionDataSource, number> | null>({
    queryKey: [
      "energy",
      "production",
      coords,
      _.pick(installation.photovoltaicInstallation, [
        "panelType",
        "inverterType",
        "roofSectionsWithPanels",
      ]),
      installation.horizonProfileFilePath,
    ],
    queryFn: async () => {
      if (!hasPhotovoltaicInstallation(installation)) {
        return null
      }

      try {
        const yearlyProductions = await Promise.all(
          Object.values(ProductionDataSource).map(async (source) => {
            const response = await httpClient.post<EnergyResponses.Production>(
              "/energy/production",
              { coords, ...installation, productionDataSource: source },
            )
            return { source, yearlyProduction: response.data?.yearlyProduction }
          }),
        )

        return yearlyProductions.reduce(
          (energyProductionPerDataSource, { source, yearlyProduction }) => ({
            ...energyProductionPerDataSource,
            [source]: yearlyProduction,
          }),
          {} as Record<ProductionDataSource, number>,
        )
      } catch (error) {
        showNotification({
          title: "Erreur lors du calcul de la production",
          message:
            "La production des différentes sources n'a pas pu être récupérée. Veuillez contacter l'équipe technique et l'équipe énergie.",
          color: "red",
        })

        return null
      }
    },
  })

type UseInstallationEnergyStatsInput = {
  coords: Pick<Location, "lat" | "lng">
  installation: Pick<
    CreateInstallationInput,
    | "photovoltaicInstallation"
    | "batteryInstallation"
    | "productionDataSource"
    | "consumptionDataSource"
    | "yearlyConsumption"
    | "horizonProfileFilePath"
  >
  switchgridOrderId: string | null
}

export const useInstallationEnergyStats = ({
  coords,
  installation,
  switchgridOrderId,
}: UseInstallationEnergyStatsInput) =>
  useQuery({
    queryKey: [
      "energy",
      "stats",
      coords,
      _.pick(installation.photovoltaicInstallation, [
        "panelType",
        "inverterType",
        "roofSectionsWithPanels",
      ]),
      installation.batteryInstallation,
      installation.productionDataSource,
      installation.consumptionDataSource,
      installation.yearlyConsumption,
      installation.horizonProfileFilePath,
      switchgridOrderId,
    ],
    queryFn: async () => {
      if (!hasPhotovoltaicInstallation(installation)) {
        return null
      }

      try {
        const response = await httpClient.post<EnergyResponses.Stats>(
          "/energy/stats",
          { coords, switchgridOrderId, ...installation },
        )
        return response.data
      } catch (error) {
        showNotification({
          title: "Erreur lors du calcul des données énergétiques",
          message:
            "La production, la consommation et le taux d'autoconsommation n'ont pas pu être récupérés. Veuillez contacter l'équipe technique et l'équipe énergie.",
          color: "red",
        })
        return null
      }
    },
  })

export const useSwitchgridConsumption = (
  house: HousesResponses.House<{
    include: { switchgridOrder: true; switchgridConsent: true }
  }>,
) => {
  const { switchgridOrder, switchgridConsent } = house

  return useQuery({
    queryKey: [
      "energy",
      "consumption",
      switchgridOrder?.orderId,
      switchgridOrder?.orderStatus,
    ],
    queryFn: async () => {
      try {
        if (
          switchgridConsent === null ||
          switchgridOrder === null ||
          switchgridOrder.orderId === null ||
          (switchgridOrder.orderStatus !== OrderStatus.SUCCEEDED &&
            switchgridOrder.orderStatus !== OrderStatus.RETRY_LATER) ||
          switchgridConsent.expirationDate < new Date()
        ) {
          return null
        }

        const response = await httpClient.post<EnergyResponses.Consumption>(
          "/energy/consumption",
          {
            consumptionDataSource: ConsumptionDataSource.SWITCHGRID,
            switchgridOrderId: switchgridOrder?.orderId,
          },
        )

        return response.data
      } catch (error) {
        showNotification({
          title: "Erreur lors de la récupération de la consommation",
          message:
            "La production des différentes sources n'a pas pu être récupérée. Veuillez contacter l'équipe technique et l'équipe énergie.",
          color: "red",
        })

        return null
      }
    },
  })
}
