import { Alert, Button, CopyButton, Stack } from "@mantine/core"
import {
  IconAlertTriangle,
  IconClipboardCheck,
  IconClipboardText,
  IconSignature,
} from "@tabler/icons-react"

import { QuoteStatus, isQuoteOutdated } from "@ensol/shared/entities/quotes"

import { LinkLoader } from "@ensol/entool/components/LinkLoader"
import {
  useGenerateQuoteSigningUrlMutation,
  useGetQuoteQuery,
} from "@ensol/entool/queries/installations"

type Props = {
  installationId: string
  installationDate: Date
}

export const QuoteActions = ({ installationId, installationDate }: Props) => {
  const { data: quote } = useGetQuoteQuery(installationId)
  const isOutdated = isQuoteOutdated(installationDate, quote)
  const { mutateAsync: generateQuoteSigningUrl, isPending: isQuoteUrlPending } =
    useGenerateQuoteSigningUrlMutation(installationId)

  if (quote === undefined) {
    return null
  }

  if (quote.pandadocDocumentId === null) {
    return null
  }

  if (isOutdated) {
    return (
      <Stack gap="16" my="16" data-testid="outdated-quote-indicator">
        <Alert
          variant="light"
          color="orange"
          title="Devis obsolète"
          icon={<IconAlertTriangle size={30} />}
        >
          L&apos;installation a changé depuis la dernière génération du devis.
          Pour générer un devis à nouveau cliquer sur &quot;Mettre à jour le
          devis&quot;
        </Alert>
      </Stack>
    )
  }

  return (
    <Stack mt="16" gap="12" align="flex-start" data-testid="quote-links">
      {quote.pandadocSigningUrl === null ? (
        quote.status === QuoteStatus.CREATED && (
          <Button
            data-testid="generate-quote-link"
            color="black"
            p="0"
            leftSection={isQuoteUrlPending ? <LinkLoader /> : <IconSignature />}
            onClick={() => generateQuoteSigningUrl()}
            disabled={isQuoteUrlPending}
            variant="transparent"
          >
            Générer l&apos;url de signature du devis
          </Button>
        )
      ) : (
        <CopyButton value={quote.pandadocSigningUrl}>
          {({ copied, copy }) => (
            <Button
              data-testid="copy-quote-link"
              color="black"
              p="0"
              variant="transparent"
              leftSection={
                copied ? <IconClipboardCheck /> : <IconClipboardText />
              }
              onClick={copy}
            >
              {copied
                ? "Url de signature copiée !"
                : "Copier l'url de signature dans le presse papier"}
            </Button>
          )}
        </CopyButton>
      )}
    </Stack>
  )
}
