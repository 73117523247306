import { SimpleGrid } from "@mantine/core"
import { IconInfoCircle } from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import {
  PreliminaryRequestStep,
  getStepIndex,
} from "@ensol/shared/entities/projects/processes"
import { getProspectFileName } from "@ensol/shared/utils/files"

import { NotFound } from "@ensol/entool/components/NotFound"
import { Section } from "@ensol/entool/components/Section"
import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import { Field } from "@ensol/entool/components/form/Field"
import { getHousePhotoFileName } from "@ensol/entool/utils/house/photos"

type Props = {
  project: ProjectResponses.Project
}

const MISSING_INFO_MESSAGE =
  "Information manquante, demander à l'AE en charge du projet de la compléter."

export const PreliminaryRequestInfo = ({ project }: Props) => {
  const { house, quote } = project.installation
  const { prospect } = house
  const currentStep = project.preliminaryRequestStep

  const housePhotos = [
    ...house.roofPhotos,
    ...house.fromStreetPhotos,
    ...house.fromFrontPhotos,
    ...house.atticPhotos,
    ...house.otherPhotos,
  ]

  return (
    <Section
      title="Informations"
      icon={IconInfoCircle}
      titleOrder={4}
      isCollapsible={
        getStepIndex(currentStep, "preliminaryRequestStep") >=
        getStepIndex(PreliminaryRequestStep.SUBMITTED, "preliminaryRequestStep")
      }
      isCollapsed
    >
      <SimpleGrid cols={2} spacing="32">
        <Field name="Calepinage" noBorder>
          {project.salesVisitPanelsLayoutPath !== null ? (
            <MultiFilePreview
              filesPaths={[project.salesVisitPanelsLayoutPath]}
              fileName={getProspectFileName(
                "calepinage-visite-commerciale",
                prospect,
              )}
            />
          ) : (
            <NotFound message={MISSING_INFO_MESSAGE} type="file" />
          )}
        </Field>
        <Field name="Photos de la maison" noBorder>
          {housePhotos.length > 0 ? (
            <MultiFilePreview
              filesPaths={housePhotos}
              fileName={getHousePhotoFileName(house.streetAddress, "photo")}
            />
          ) : (
            <NotFound message={MISSING_INFO_MESSAGE} type="file" />
          )}
        </Field>
        <Field name="Rapport K2" noBorder>
          {project.salesVisitK2ReportPath !== null ? (
            <MultiFilePreview
              filesPaths={[project.salesVisitK2ReportPath]}
              fileName={getProspectFileName(
                "rapport-k2-visite-commerciale",
                prospect,
              )}
            />
          ) : (
            <NotFound message={MISSING_INFO_MESSAGE} type="file" />
          )}
        </Field>
        <Field name="Mandat de représentation" noBorder>
          {quote !== null &&
          (quote.mandatePath !== null || quote.quotePath !== null) ? (
            <MultiFilePreview
              filesPaths={
                quote.mandatePath ? [quote.mandatePath] : [quote.quotePath!]
              }
              fileName={getProspectFileName("mandat", prospect)}
            />
          ) : (
            <NotFound type="file" />
          )}
        </Field>
      </SimpleGrid>
    </Section>
  )
}
