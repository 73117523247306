import { SimpleGrid, Stack, Textarea, TitleOrder } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconSolarPanel } from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { Team } from "@ensol/shared/entities/users"
import { getProspectFileName } from "@ensol/shared/utils/files"

import { Section } from "@ensol/entool/components/Section"
import { Field } from "@ensol/entool/components/form/Field"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { MultiFileField } from "@ensol/entool/components/form/File/MultiFileField"
import { UserSelect } from "@ensol/entool/components/form/UserSelect"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"

type Props<Values> = {
  project: ProjectResponses.Project
  form: UseFormReturnType<Values>
  titleOrder?: TitleOrder
}

export const ProjectForm = <
  Values extends {
    salesOwnerId: string | null
    salesVisitPanelsLayoutPath: string | null
    salesVisitSuggestedLocationPhotos: string[]
    technicalVisitClientAvailabilities: string
    technicalVisitSalesComments: string
    electricityBillPath: string | null
    notes: string
  },
>({
  project: { installation },
  form,
  titleOrder = 3,
}: Props<Values>) => {
  const { prospect } = installation.house
  const panelsLayoutFileName = getProspectFileName(
    "calepinage-visite-commerciale",
    prospect,
  )

  return (
    <Section title="Projet" icon={IconSolarPanel} titleOrder={titleOrder}>
      <Stack p="16 0">
        <SimpleGrid cols={{ base: 1, md: 2 }} spacing={{ base: 16, md: 62 }}>
          <Stack gap="16">
            <Field name="Responsable" noBorder withAsterisk>
              <UserSelect
                w={FIELD_WIDTH}
                teams={[Team.ACCOUNT_EXECUTIVE, Team.INSIDE_SALES]}
                withDefault
                {...form.getInputProps("salesOwnerId")}
              />
            </Field>
            <Field name="PDF Calepinage K2" noBorder withAsterisk>
              <FileField
                previewProps={{
                  fileName: panelsLayoutFileName,
                }}
                inputProps={{ accept: ["application/pdf"] }}
                {...form.getInputProps("salesVisitK2ReportPath")}
              />
            </Field>
            <Field name="Image calepinage" noBorder withAsterisk>
              <FileField
                previewProps={{
                  fileName: panelsLayoutFileName,
                }}
                inputProps={{ accept: ["image/jpeg", "image/png"] }}
                {...form.getInputProps("salesVisitPanelsLayoutPath")}
              />
            </Field>
            <Field name="Photos emplacement suggéré des panneaux" noBorder>
              <MultiFileField
                previewProps={{
                  fileName: panelsLayoutFileName,
                }}
                inputProps={{
                  accept: ["image/jpeg", "image/png", "image/heic"],
                }}
                value={form.values.salesVisitSuggestedLocationPhotos}
                {...form.getInputProps("salesVisitSuggestedLocationPhotos")}
              />
            </Field>
          </Stack>
          <Stack gap="16">
            <Field name="Notes générales projet" noBorder>
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("notes")}
              />
            </Field>
            <Field
              name="Disponibilités client pour la visite technique"
              noBorder
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("technicalVisitClientAvailabilities")}
              />
            </Field>
            <Field name="Commentaires pour la visite technique" noBorder>
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("technicalVisitSalesComments")}
              />
            </Field>
            <Field name="Facture d'électricité" noBorder>
              <FileField
                previewProps={{
                  fileName: getProspectFileName(
                    "facture-électricité",
                    prospect,
                  ),
                }}
                inputProps={{
                  accept: [
                    "image/png",
                    "image/jpeg",
                    "image/heic",
                    "application/pdf",
                  ],
                  capture: "environment",
                }}
                {...form.getInputProps("electricityBillPath")}
              />
            </Field>
          </Stack>
        </SimpleGrid>
      </Stack>
    </Section>
  )
}
