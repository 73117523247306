import { Group, TextProps, Stack, Text } from "@mantine/core"
import { ElementType, SVGProps } from "react"

type InstallationResultTitleProps = {
  title: string
  icon: ElementType<SVGProps<SVGSVGElement>>
}

export const SummaryTitle = ({
  title,
  icon: Icon,
}: InstallationResultTitleProps) => (
  <Group gap={6} mb={4}>
    <Icon />
    <Text fw="bold">{title}</Text>
  </Group>
)

type InstallationResultItemProps = {
  title: string
  value: string | number
  size?: TextProps["size"]
  warning?: string
}

export const SummaryItem = ({
  title,
  value,
  warning,
  size = "sm",
}: InstallationResultItemProps) => (
  <Stack gap={6}>
    <Group justify="space-between">
      <Text c="gray.7" size={size}>
        {title}
      </Text>
      <Text fw="bold" size={size}>
        {value}
      </Text>
    </Group>
    {warning && (
      <Text c="orange" size="xs" mb={4}>
        {warning}
      </Text>
    )}
  </Stack>
)
