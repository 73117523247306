import { ConsuelStep } from "@ensol/shared/entities/projects/processes"

import { StepComponentGetter } from "@ensol/entool/components/entities/Project/ProcessView"

import { Pending } from "./1-Pending"
import { Validated } from "./10-Validated"
import { WaitingForSolteo } from "./2-WaitingForSolteo"
import { Submitted } from "./3-Submitted"
import { VisitNeeded } from "./4-VisitNeeded"
import { VisitScheduled } from "./5-VisitScheduled"
import { WorkNeeded } from "./6-WorkNeeded"
import { WorkScheduled } from "./7-WorkScheduled"
import { WorkDone } from "./8-WorkDone"
import { ComplianceCertificateSigned } from "./9-ComplianceCertificateSigned"

export const getConsuelStepComponent: StepComponentGetter = (currentStep) => {
  switch (currentStep) {
    case ConsuelStep.PENDING:
      return { StepComponent: Pending, isCancellable: false }
    case ConsuelStep.WAITING_FOR_SOLTEO:
      return { StepComponent: WaitingForSolteo }
    case ConsuelStep.SUBMITTED:
      return { StepComponent: Submitted }
    case ConsuelStep.VISIT_NEEDED:
      return { StepComponent: VisitNeeded }
    case ConsuelStep.VISIT_SCHEDULED:
      return { StepComponent: VisitScheduled }
    case ConsuelStep.WORK_NEEDED:
      return { StepComponent: WorkNeeded }
    case ConsuelStep.WORK_SCHEDULED:
      return { StepComponent: WorkScheduled }
    case ConsuelStep.WORK_DONE:
      return { StepComponent: WorkDone }
    case ConsuelStep.COMPLIANCE_CERTIFICATE_SIGNED:
      return { StepComponent: ComplianceCertificateSigned }
    case ConsuelStep.VALIDATED:
      return { StepComponent: Validated }
    default:
      return { StepComponent: null }
  }
}
