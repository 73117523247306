import { Burger, Drawer, Divider, Button } from "@mantine/core"
import { useMediaQuery, useDisclosure } from "@mantine/hooks"
import {
  IconCalendarMonth,
  IconUsers,
  IconHomeMove,
  IconHomeCog,
  IconHomeBolt,
} from "@tabler/icons-react"
import { ReactNode } from "react"
import { Link } from "react-router-dom"

import { NAVBAR_HEIGHT_IN_PX } from "@ensol/entool/styles/constants"
import { devices } from "@ensol/entool/styles/theme"

const MENU_ITEMS = [
  { to: "/prospects", name: "Prospects", Icon: <IconUsers /> },
  { to: "/sales", name: "Suivi AE", Icon: <IconHomeMove /> },
  { to: "/projects", name: "Opérations", Icon: <IconHomeCog /> },
  { to: "/follow-ups", name: "Suivi Énergie", Icon: <IconHomeBolt /> },
  { to: "/events", name: "Calendrier", Icon: <IconCalendarMonth /> },
]

export const Menu = () => {
  const isSmallScreen = useMediaQuery(devices.lg)
  const [isMenuOpen, { open: openMenu, close: closeMenu }] =
    useDisclosure(false)

  if (isSmallScreen) {
    return (
      <>
        <Burger mr="20" opened={isMenuOpen} onClick={openMenu} />
        <Drawer
          opened={isMenuOpen}
          onClose={closeMenu}
          size="50%"
          position="right"
          styles={{ body: { padding: 0 } }}
        >
          {MENU_ITEMS.map((item) => (
            <NavLink key={item.to} {...item} onClick={closeMenu} />
          ))}
        </Drawer>
      </>
    )
  }

  return (
    <>
      {MENU_ITEMS.map((item) => (
        <NavLink key={item.to} {...item} />
      ))}
      <Divider orientation="vertical" mr="16" />
    </>
  )
}

type NavLinkProps = {
  to: string
  name: string
  Icon: ReactNode
  onClick?: () => void
}

const NavLink = ({ to, name, Icon, onClick }: NavLinkProps) => {
  const isSmallScreen = useMediaQuery(devices.lg)

  return (
    <Button
      component={Link}
      to={to}
      onClick={onClick}
      h={NAVBAR_HEIGHT_IN_PX}
      px="24"
      color="dark.4"
      leftSection={Icon}
      variant="subtle"
      styles={{
        root: { borderRadius: 0, width: isSmallScreen ? "100%" : "auto" },
        inner: { justifyContent: "flex-start" },
      }}
    >
      {name}
    </Button>
  )
}
