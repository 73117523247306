import { Button, Text } from "@mantine/core"
import { modals } from "@mantine/modals"

import { QuoteStatus, isQuoteOutdated } from "@ensol/shared/entities/quotes"

import { LinkLoader } from "@ensol/entool/components/LinkLoader"
import {
  useCreateQuoteMutation,
  useGetQuoteQuery,
} from "@ensol/entool/queries/installations"

type Props = {
  installationId: string
  installationDate: Date
  isInstallationValid: boolean
}

export const GenerateQuoteButton = ({
  installationId,
  installationDate,
  isInstallationValid,
}: Props) => {
  const { data: quote } = useGetQuoteQuery(installationId)
  const isOutdated = isQuoteOutdated(installationDate, quote)
  const isSigned = quote?.status === QuoteStatus.SIGNED

  const { mutateAsync: sendQuote, isPending: isSendingQuote } =
    useCreateQuoteMutation(installationId)

  let label
  if (quote === undefined) {
    label = "Créer le devis"
  } else if (isSendingQuote) {
    label = "Génération du devis"
  } else if (!isOutdated) {
    label = "Le devis est à jour"
  } else {
    label = "Mettre à jour le devis"
  }

  return (
    <Button
      data-testid="generate-quote"
      variant="light"
      onClick={() => {
        if (isSigned) {
          return modals.openConfirmModal({
            title: "Le devis de cette installation est signé",
            children: (
              <Text size="sm">
                Êtes-vous sûr(e) de vouloir supprimer ce devis et le remplacer
                par un nouveau ? Cette action supprimera l&apos;anciens devis et
                le mandat et nécessitera que le nouveau devis soit signé à la
                place.
              </Text>
            ),
            confirmProps: { color: "red" },
            centered: true,
            labels: {
              confirm: "Remplacer le devis",
              cancel: "Annuler",
            },
            onConfirm: sendQuote,
          })
        }

        sendQuote()
      }}
      leftSection={isSendingQuote && <LinkLoader />}
      disabled={!isInstallationValid || !isOutdated || isSendingQuote}
      color="purple"
    >
      {label}
    </Button>
  )
}
