import { useQuery } from "@tanstack/react-query"

import { UsersResponses } from "@ensol/types/endpoints/users"

import { httpClient } from "@ensol/entool/backend/axios"

export const useListUsers = () => {
  const { data, ...response } = useQuery<UsersResponses.UsersList>({
    queryKey: ["users"],
    queryFn: async () => {
      const response = await httpClient.get("/users")
      return response.data
    },
  })

  return { users: data ?? [], ...response }
}

export const useAuthenticatedUser = () => {
  const { data } = useQuery<UsersResponses.User>({
    queryKey: ["me"],
    queryFn: async () => {
      const response = await httpClient.get("/users/me")
      return response.data
    },
  })

  return data
}
