import { Badge } from "@mantine/core"

import { QuoteStatus, STATUSES } from "@ensol/shared/entities/quotes"

type Props = {
  status?: QuoteStatus | null
}

export const QuoteStatusBadge = ({ status }: Props) => {
  if (!status) {
    return <Badge color="gray">Non créé</Badge>
  }

  const { label, color } = STATUSES[status]

  return <Badge color={color}>{label}</Badge>
}
