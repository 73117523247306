import frLocale from "@fullcalendar/core/locales/fr"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import { Stack } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"

import { ProjectEventType } from "@ensol/shared/entities/projects/events"

import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { EventModal } from "@ensol/entool/pages/ProjectsEvents/Calendar/EventModal"
import { Legend } from "@ensol/entool/pages/ProjectsEvents/Calendar/Legend"
import {
  formatProjectsEvents,
  formatInstallersEvents,
} from "@ensol/entool/pages/ProjectsEvents/Calendar/formatEvents"
import { useCalendar } from "@ensol/entool/pages/ProjectsEvents/Calendar/useCalendar"
import { useGetInstallersEventsQuery } from "@ensol/entool/queries/installers"
import { useGetProjectsEventsQuery } from "@ensol/entool/queries/projects"
import { CONTENT_PADDING_IN_PX } from "@ensol/entool/styles/constants"
import { devices } from "@ensol/entool/styles/theme"

import { useCalendarShortcuts } from "./useCalendarShortcuts"
import { useEventUpdate } from "./useEventUpdate"

type Props = {
  eventTypes: ProjectEventType[]
  installers: string[]
  showInstallerEvents: boolean
}

export const Calendar = ({
  eventTypes,
  installers,
  showInstallerEvents,
}: Props) => {
  const isMobile = useMediaQuery(devices.sm)
  const calendarRef = useCalendarShortcuts()
  const updateEvent = useEventUpdate()
  const {
    currentCalendarView,
    onDatesSet,
    openedEventId,
    openEvent,
    closeEvent,
    period,
  } = useCalendar()
  const projectsEventsQuery = useGetProjectsEventsQuery({
    types: eventTypes,
    installers,
    period,
  })
  const installersEventsQuery = useGetInstallersEventsQuery({
    installers,
    period,
  })

  return (
    <QueryWrapper query={projectsEventsQuery} returnEmptyResults>
      {({ data: projectsEventsResponse }) => (
        <QueryWrapper query={installersEventsQuery} returnEmptyResults>
          {({ data: installersEventsResponse }) => {
            const projectsEvents = formatProjectsEvents(projectsEventsResponse)
            const installersEvents = showInstallerEvents
              ? formatInstallersEvents({
                  installersEventsResponse,
                })
              : []

            return (
              <Stack
                flex="1"
                h="100%"
                justify="center"
                pl={CONTENT_PADDING_IN_PX}
                pr={isMobile ? CONTENT_PADDING_IN_PX : 0}
              >
                <EventModal
                  event={projectsEvents.find(({ id }) => id === openedEventId)}
                  onClose={closeEvent}
                />
                <FullCalendar
                  ref={calendarRef}
                  height="100%"
                  plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
                  locales={[frLocale]}
                  initialView={currentCalendarView}
                  datesSet={onDatesSet}
                  headerToolbar={
                    isMobile
                      ? {
                          start: "title",
                          end: "dayGridMonth,timeGridWeek,prev,next",
                        }
                      : {
                          start: "dayGridMonth,timeGridWeek",
                          center: "title",
                          end: "today,prev,next",
                        }
                  }
                  weekends={false}
                  expandRows={true}
                  slotMinTime="07:00:00"
                  slotMaxTime="19:00:00"
                  allDayText=""
                  events={[...projectsEvents, ...installersEvents]}
                  eventClick={openEvent}
                  eventResizableFromStart={true}
                  eventDrop={updateEvent}
                  eventResize={updateEvent}
                />
                {!isMobile && <Legend />}
              </Stack>
            )
          }}
        </QueryWrapper>
      )}
    </QueryWrapper>
  )
}
