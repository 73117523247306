import { Drawer, Stack, Switch, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"

import { ProjectEventType } from "@ensol/shared/entities/projects/events"

import { CheckboxGroup } from "@ensol/entool/components/form/CheckboxGroup"
import { useInstallersOptions } from "@ensol/entool/queries/installers"
import { CONTENT_PADDING_IN_PX } from "@ensol/entool/styles/constants"
import { devices } from "@ensol/entool/styles/theme"
import { PROJECT_EVENT_TYPES_OPTIONS } from "@ensol/entool/utils/projects/options"

type Props = {
  isOpen: boolean
  onClose: () => void
  eventTypes: ProjectEventType[]
  setEventTypes: (eventTypes: ProjectEventType[]) => void
  installers: string[]
  setInstallers: (installers: string[]) => void
  showInstallerEvents: boolean
  setShowInstallerEvents: (showInstallerEvents: boolean) => void
}

const MENU_WIDTH_IN_PX = 230

export const EventsMenu = (props: Props) => {
  const isMobile = useMediaQuery(devices.sm)

  if (isMobile) {
    return (
      <Drawer
        opened={props.isOpen}
        onClose={props.onClose}
        position="right"
        size="75%"
        title={
          <Text size="lg" fw="bold">
            Paramètres du calendrier
          </Text>
        }
      >
        <EventsMenuContent {...props} />
      </Drawer>
    )
  }

  return (
    <Stack
      h="100%"
      w={MENU_WIDTH_IN_PX}
      pl="16"
      pr={CONTENT_PADDING_IN_PX}
      style={{
        overflow: "auto",
        borderLeft: "1px solid var(--mantine-color-gray-3)",
      }}
    >
      <EventsMenuContent {...props} />
    </Stack>
  )
}

const EventsMenuContent = ({
  eventTypes,
  setEventTypes,
  installers,
  setInstallers,
  showInstallerEvents,
  setShowInstallerEvents,
}: Props) => {
  const installersOptions = useInstallersOptions()

  return (
    <Stack gap="32">
      <Stack>
        <Text fz="sm" fw="bold">
          Types d&apos;évènements
        </Text>
        <CheckboxGroup
          options={PROJECT_EVENT_TYPES_OPTIONS}
          value={eventTypes}
          onChange={(value) => setEventTypes(value as ProjectEventType[])}
        />
      </Stack>
      <Stack>
        <Text fz="sm" fw="bold">
          Calendriers installateurs
        </Text>
        <Switch
          label="Afficher les évènements"
          checked={showInstallerEvents}
          onChange={() => setShowInstallerEvents(!showInstallerEvents)}
        />
      </Stack>
      <Stack>
        <Text fz="sm" fw="bold">
          Installateurs
        </Text>
        <CheckboxGroup
          options={installersOptions}
          value={installers}
          onChange={setInstallers}
          withSelectAll
        />
      </Stack>
    </Stack>
  )
}
