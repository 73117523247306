import { ExtraWorkType } from "@ensol/shared/material/extraWorks"

export enum InstallationUpdateType {
  SALES = "sales",
  TECHNICAL_VISIT = "technicalVisit",
}

export type ExtraWorks = {
  type: ExtraWorkType
  isBilled: boolean
}[]
